import { OrderWait } from "../../store/slices/orderSlice";
import * as Request from "../Request";
interface cartPos {
  food_id: string;
  foodName: string;
  noteFood_id: string;
  noteFood_name: string;
  price: number;
  side_id: string;
  imageFood: string;
  quantity: number;
  statusOrderGo: number;
}
export interface SideDish {
  side_id: string;
  quantity: number;
}
export interface InfoOrder {
  id?: string;
  owner_id?: string;
  custommer_id?: string;
  table_id?: string;
  service_item_id?: string;
  price?: string;
  quantity?: string;
  notefood_id?: string;
  side_id?: string;
  form_order?: string;
  note?: string;
  name?: string;
  imageCourse?: string;
  donvitinh?: string;
  table_name?: string;
  notefood_name?: string;
  side_name?: string;
  slcon?: string;
  insufficient?: boolean;
  description?: string;
}
export interface order_detail {
  custommer_id: string;
  name: string;
  listFood: InfoOrder[];
  order_id: string;
  totalPrice: string;
  staff_name: string;
  tip_amount: string;
  tip_staff_id: string;
  discount: string;
  payment: number;
  voucher_id: string;
  voucher_code: string;
  status: string;
}
export interface Result {
  status: number;
  message?: string;
  data?: [];
  order_id: string;
  order_detail: order_detail;
}
export interface ResultOffLine<T> {
  status: number;
  message?: string;
  data?: T;
}
export interface Menu {
  id: string;
  service_id: string;
  service_item_code: string;
  name: string;
  short_des: string;
  description: string;
  sort_order: string;
  price: string;
  parent_id: string;
  discount: string;
  imageCourse: string;
  donvitinh: string;
  role: string;
  side_id: string;
  price_old: string;
}
export interface InfoHappyHour {
  id: string;
  owner_id: string;
  timestart: string;
  timeend: string;
  status: string;
  discount: string;
  repeat_id: string;
  name_repeat: string;
  proceeding_date: string;
}
export interface ResultHappyHour<T> {
  status: number;
  message?: string;
  data: T;
  listFood: Menu[];
  time_difference: number;
}

export const submitOrderPos = async (
  owner_id: number,
  TotalPay: number,
  tax_price: number,
  staffId: string,
  tip_amount: number,
  cartInfo: cartPos[],
  type: number,
  table_id: string = "",
  order_id: string = "",
  voucher_id: string = "",
  voucher_code: string = "",
  discount: number = 0,
  customer_id: string = "",
  payment_method: number = 0,
  sideDish: SideDish[] = [],
  priceSides: number = 0,
  staffJointId = 0
) => {
  return await Request.fetchAPI(`owner/create-order-pos.php`, "POST", {
    owner_id,
    TotalPay,
    tax_price,
    staffId,
    tip_amount,
    cartInfo: JSON.stringify(cartInfo),
    table_id,
    order_id,
    type,
    voucher_code,
    voucher_id,
    discount,
    customer_id,
    payment_method,
    sideDish: JSON.stringify(sideDish),
    priceSides,
    staffJointId,
  })
    .then((res: Result) => {
      return res;
    })
    .catch((err: any) => {
      return {
        message: "An error has occurred. Please check your internet connection",
        status: 0,
        data: "",
        order_id: "",
        order_detail: undefined,
      };
    });
};

export const submitOrderPos_Offline = async (
  owner_id: number,
  listOrder: OrderWait[]
) => {
  return await Request.fetchAPI(`owner/create-order-pos_offline.php`, "POST", {
    owner_id,
    listOrder: JSON.stringify(listOrder),
  })
    .then((res: ResultOffLine<string[]>) => {
      return res;
    })
    .catch((err: any) => {
      return {
        message: "An error has occurred. Please check your internet connection",
        status: 0,
        data: [],
      };
    });
};

export const gHappyHour = async (owner_id: number) => {
  return await Request.fetchAPI(`owner/gShowHappyHour.php`, "POST", {
    owner_id,
  })
    .then((res: ResultHappyHour<InfoHappyHour>) => {
      return res;
    })
    .catch((err: any) => {
      return {
        message: "An error has occurred. Please check your internet connection",
        status: 0,
        data: undefined,
        listFood: [],
        time_difference: 0,
      };
    });
};
