import React, { useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import {
  KeyboardArrowLeftIcon,
  KeyboardArrowRightIcon,
} from "../../../../../components/Image";
import Topbar from "../../../../../layouts/Topbar";
import "../Styles.css";
import * as userSlice from "../../../../../store/slices/userSlice";
import { useSelector } from "react-redux";
import DrawerOrderDetail from "./Drawer/DrawerOderDetail";
import * as reportApi from "../../../../../api/apiOwner/reportApi";
import ModalLoading from "../../../../../components/ModalLoading";
import { FormatDolla } from "../../../../../utils/format";
import * as custommerRecieptApi from "../../../../../api/apiOwner/custommerRecieptApi";
import { getLanguageUI } from "../../../../../Services/languageUI";
const IncomeDateDetail: React.FC = () => {
  const languageUI = getLanguageUI().reportPage;
  const UserIdStore = useSelector(userSlice.selectorUserId);
  const navigate = useNavigate();
  const location = useLocation();
  const params: any = location.state;
  const dayValue = params?.dayValue;
  const dayName = params?.dayName;
  const selectMonth = params?.selectMonth;
  const countYear = params?.countYear;

  const [showDrawer, setShowDrawer] = useState<boolean>(false);
  const [ShowLoading, setShowLoading] = useState<boolean>(false);
  const [listBill, setListBill] = useState<reportApi.ReportBill[]>([]);
  const [detailOrder, setDetailOrder] =
    useState<custommerRecieptApi.DetailBill>();
  const gAllBillByDate = async () => {
    if (UserIdStore) {
      setShowLoading(true);
      const req = await reportApi.gReportBillbyDay(UserIdStore, dayValue);
      if (Number(req?.status) === 1) {
        setListBill(req?.data ?? []);
        setShowLoading(false);
      } else {
        setShowLoading(false);
      }
    }
  };

  const gDetailOrder = async (idOrder: string) => {
    if (UserIdStore) {
      setShowLoading?.(true);
      const gDetail = await custommerRecieptApi.gDetailOrderCustomer(
        UserIdStore,
        idOrder
      );
      if (Number(gDetail?.status) === 1) {
        setDetailOrder(gDetail?.data ?? undefined);
        setShowLoading?.(false);
        setShowDrawer(true);
      } else {
        setShowLoading?.(false);
      }
    }
  };
  useEffect(() => {
    if (params?.dayValue && UserIdStore) {
      gAllBillByDate();
    } else {
      navigate("/web-owner/report/incomedetail");
    }
  }, []);
  return (
    <>
      <ModalLoading show={ShowLoading} onHide={() => setShowLoading(false)} />
      <DrawerOrderDetail
        openDrawer={showDrawer}
        dismisDrawer={() => setShowDrawer(!showDrawer)}
        detailOrder={detailOrder}
        UserIdStore={UserIdStore}
      />
      <div className="">{/* <Topbar pageName="Report" /> */}</div>
      <div className=" bg-white">
        <div className=" mt-18 ">
          <div className="d-flex justify-between  ml-12 pt-3">
            <div
              className="w-100 flex items-center py-12px"
              onClick={() =>
                navigate("/web-owner/report/incomedetail", {
                  state: { countYear, selectMonth },
                })
              }
            >
              <div className="bg-white border-DCDCE4 radius-6px p-2 flex justify-center mr-18px">
                <KeyboardArrowLeftIcon />
              </div>
              <span className="text-8 fw-600 font-urbansist mr-6 text-212134">
                {languageUI.income} {dayName}{" "}
              </span>
            </div>
          </div>
        </div>
      </div>
      <div className="mt-38px">
        <div className="w-95 bg-white  mx-12 radius-4 ">
          <table className="table">
            <thead>
              <tr>
                <th
                  scope="col"
                  className="w-10 text-18px text-12B064 fw-600 font-urbansist"
                >
                  {languageUI.date}{" "}
                </th>
                <th scope="col" className="w-10">
                  <span className="text-18px text-12B064 fw-600 font-urbansist">
                    {languageUI.time}{" "}
                  </span>
                </th>

                <th
                  scope="col"
                  className="w-25 text-18px text-12B064 fw-600 font-urbansist"
                >
                  {languageUI.order}{" "}
                </th>
                <th
                  scope="col"
                  className="w-10 text-18px text-12B064 fw-600 font-urbansist"
                >
                  {languageUI.table}{" "}
                </th>
                <th scope="col" className="w-10">
                  <span className="text-18px text-12B064 fw-600 font-urbansist">
                    {languageUI.bill}{" "}
                  </span>
                </th>
                <th scope="col" className="w-10">
                  <span className="text-18px text-12B064 fw-600 font-urbansist">
                    Tips
                  </span>
                </th>
                <th
                  scope="col"
                  className="w-25 text-18px text-12B064 fw-600 font-urbansist"
                >
                  {languageUI.staff}
                </th>
              </tr>
            </thead>
            <tbody className="h-body-table">
              {listBill.map((item, i) => (
                <tr key={`item${i}`} className="flex">
                  <td className="border-bottom-none text-17px text-4A4A6A w-10">
                    {item?.dateorder}
                  </td>
                  <td className="border-bottom-none text-17px text-4A4A6A w-10">
                    {item?.timeorder}
                  </td>
                  <td className="border-bottom-none text-17px text-269AFF w-25">
                    #{item?.id}
                  </td>
                  <td className="border-bottom-none text-17px text-269AFF w-10">
                    {item?.table_name}
                  </td>
                  <td className="border-bottom-none text-18px text-4A4A6A w-10">
                    ${FormatDolla(Number(item?.price))}
                  </td>
                  <td className="border-bottom-none text-18px text-4A4A6A w-10">
                    ${FormatDolla(Number(item?.tip_amount))}
                  </td>
                  <td
                    onClick={() => gDetailOrder(item?.id)}
                    className="w-25 border-bottom-none"
                  >
                    <div className="flex items-center justify-between ">
                      <span className="text-17px text-4A4A6A">
                        {Number(item?.tip_staff_id) !== 0
                          ? item?.staff_name
                          : "-"}
                      </span>
                      <KeyboardArrowRightIcon />
                    </div>
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
        </div>
      </div>
    </>
  );
};

export default IncomeDateDetail;
