/* eslint-disable jsx-a11y/anchor-is-valid */
import { useEffect, useState } from "react";
// actions
// import GoogleReviewLink from "./reviewLink";
import "./styles.css";
// store

import { useNavigate, useParams } from "react-router-dom";
// import fetch from "node-fetch";
// components
import PersonIcon from "@mui/icons-material/Person";
import ModalChooseStaffRating from "./webcheckin/components/ModalChooseStaffRating/ModalChooseStaffRating";
import {
  StaffActive,
  addFeedbackFood,
  customer_info,
  gListProductWaitFeedback,
  gListStaffActive,
  listFoodWaiting,
  sendMailLowRating,
} from "../api/apiOwner/StaffApi";
import ReviewFoodItem from "../components/ReviewFoodItem/ReviewFoodItem";
import { Rating } from "@mui/material";
import ModalLoading from "../components/ModalLoading";
import ReviewSuccessGoToGoogle from "./ReviewSuccessGoToGoogle";

interface ReviewRestaurantProps {}

const ReviewRestaurant = ({}: ReviewRestaurantProps) => {
  const [showModalToGoogle, setShowModalToGoogle] = useState<boolean>(false);
  const [showModalShooseStaff, setShowModalShooseStaff] = useState(false);
  const [nameStaffActive, setNameStaffActive] = useState("");
  const [listEmployeeActive, setListEmployeeActive] = useState<StaffActive[]>(
    []
  );
  const [valueRatingStaff, setValueRatingStaff] = useState<number | null>(5);
  const [contentFeedback, setContentFeedback] = useState<string>("");
  const [idStaffActive, setIdStaffActive] = useState<number>(0);
  const [idStaffActiveted, setIdStaffActiveted] = useState<number>(0);
  const [messErr, setMessErr] = useState("");
  const [showModalLoading, setShowModalLoading] = useState(false);
  const [listFoodWaitFeedBack, setListFoodWaitFeedBack] = useState<
    listFoodWaiting[]
  >([]);
  const [infoCustomer, setInfoCustomer] = useState<customer_info>();
  const [totalRateFood, setTotalRateFood] = useState<number>(0);
  const navigate = useNavigate();

  const mobile_link = `https://search.google.com/local/writereview?placeid=${infoCustomer?.googleBusinessID}`;

  const desktop_link = `https://search.google.com/local/writereview?placeid=${infoCustomer?.googleBusinessID}`;
  const isMobile = window.innerWidth <= 600;

  const linkURL = isMobile ? mobile_link : desktop_link;

  const { idSendSMS } = useParams();
  var sms_id = idSendSMS;
  const dismisModalShooseStaff = () => {
    setShowModalShooseStaff(false);
  };

  const handleSubmitStaff = (idStaff: number, nameStaff: string) => {
    setNameStaffActive(nameStaff);
    setIdStaffActive(idStaff);
    setIdStaffActiveted(idStaff);
    setShowModalShooseStaff(false);
  };

  const gListEmployeeActive = async (ownerId: number) => {
    if (ownerId) {
      const req = await gListStaffActive(ownerId, 1);
      if (Number(req?.status) === 1) {
        setListEmployeeActive(req?.data ?? []);
      } else {
        setMessErr(req.message ?? "Please check your internet");
      }
    } else {
      setMessErr("Please check your internet");
    }
  };

  const getListProductWaitFeedback = async (sms_id: string) => {
    setShowModalLoading(true);
    const req = await gListProductWaitFeedback(sms_id);
    if (req?.status === 1) {
      setListFoodWaitFeedBack(req.data?.listFoodWaiting ?? []);
      setInfoCustomer(req.data?.customer_info);
      gListEmployeeActive(req.data?.customer_info.admin_id ?? 0);

      setShowModalLoading(false);
    }
    setShowModalLoading(false);
  };

  const handleChangeReviewFood = (
    id: number,
    valueStar: number,
    contentfeedBack: string
  ) => {
    const listFoodReview = listFoodWaitFeedBack.map((item, index) => {
      if (item.service_item_id === id) {
        return {
          ...item,
          rate: valueStar,
          content: contentfeedBack,
        };
      }
      return item;
    });
    setListFoodWaitFeedBack(listFoodReview);
  };

  //gửi mail khi có món dưới 9 sao
  const sentMailWarning = async (owner_id: number) => {
    const res = await sendMailLowRating(owner_id);
    if (Number(res?.status) === 1) {
      navigate("/review-success");
    } else {
      navigate("/review-success");
    }
  };
  const handleAddFeedbackFood = async () => {
    setShowModalLoading(true);
    if (infoCustomer?.customer_id) {
      const req = await addFeedbackFood(
        infoCustomer?.customer_id,
        idStaffActive,
        valueRatingStaff === 1
          ? 3
          : valueRatingStaff === 2 || valueRatingStaff === 3
          ? 2
          : 1,
        contentFeedback,
        listFoodWaitFeedBack ?? [],
        valueRatingStaff ?? 1
      );
      if (req?.status === 1) {
        setShowModalLoading(false);
        if (totalRateFood < 9) {
          sentMailWarning(infoCustomer?.admin_id);
        } else {
          setShowModalToGoogle(true);
        }
      } else {
        setMessErr(req?.message ?? "Please check your internet!");
        setShowModalLoading(false);
      }
      setShowModalLoading(false);
    } else {
      setShowModalLoading(false);
    }
    setShowModalLoading(false);
  };
  useEffect(() => {
    getListProductWaitFeedback(sms_id ?? "");
  }, [sms_id]);

  useEffect(() => {
    var totalStart = 0;
    listFoodWaitFeedBack.map((item) => {
      totalStart += item.rate;
    });
    setTotalRateFood(totalStart / listFoodWaitFeedBack.length);
  }, [listFoodWaitFeedBack]);

  return (
    <>
      <div
        className="review-restaurant-page"
        style={{ minHeight: `${window.innerHeight}vh"` }}
      >
        <ModalLoading
          show={showModalLoading}
          onHide={() => setShowModalLoading(false)}
        />
        <div className="review-introduce">
          <p className="review-restaurant-name">{infoCustomer?.store_name}</p>
          <div className="review-introduce-customer">
            Hi {infoCustomer?.customer_name}! <br />
            We greatly value your feedback. <br />
            Could you please share how we might improve our food and service?{" "}
            <br />
            We strive to offer the best possible experience.
          </div>
          <div className="box-note-review">
            <p className="title-note-review-restaurant">
              Note from POS provider:
            </p>
            <p className="content-note-review-restaurant">
              Your name will be kept confidential; BriJack POS will hide your
              identity, so no one from the restaurant will know who provided the
              feedback.
            </p>
          </div>
        </div>
        <div className="review-introduce">
          <p className="review-restaurant-name">
            Are the staff friendly and enthusiastic in their service?
          </p>
          <div
            className="w-100 h-50px rounded-6 bg-orange-light d-flex align-items-center px-3 gap-2 my-2"
            onClick={() => setShowModalShooseStaff(true)}
          >
            <PersonIcon className="text-orange flex-shrink-0" />
            <p className="m-0 text-orange font-bold text-xl text-truncate">
              {nameStaffActive === "" ? "Please choose staff" : nameStaffActive}
            </p>
          </div>
          <div className="w-100 d-flex justify-content-center">
            <Rating
              size="large"
              sx={{
                fontSize: "4rem",
              }}
              max={5}
              value={valueRatingStaff}
              onChange={(event, newValue) => {
                setValueRatingStaff(newValue);
              }}
            />
          </div>
          <div className="mt-2">
            <textarea
              className="note-feedback-review focus-visiable-none"
              placeholder="Please tell us your feedback!"
              id="textarea"
              value={contentFeedback}
              onChange={(e) => {
                setContentFeedback(e.target.value);
                setMessErr("");
              }}
            ></textarea>
          </div>
        </div>
        <div className="review-introduce">
          <p className="review-restaurant-name">How was your food?</p>
          {listFoodWaitFeedBack.map((item, inđex) => (
            <ReviewFoodItem
              foodName={item.name}
              handleChangeReviewFood={handleChangeReviewFood}
              idFoodReview={item.service_item_id}
            />
          ))}
        </div>
        <div className="footer-review" onClick={handleAddFeedbackFood}>
          <div className="btn-submit-review">Submit</div>
        </div>
      </div>
      <ReviewSuccessGoToGoogle linkURLGG={linkURL} show={showModalToGoogle} />
      <ModalChooseStaffRating
        listEmployeeActive={listEmployeeActive}
        handleClose={dismisModalShooseStaff}
        show={showModalShooseStaff}
        idStaffActiveted={idStaffActiveted}
        handeSubmit={handleSubmitStaff}
      />
    </>
  );
};

export default ReviewRestaurant;
