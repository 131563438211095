import * as Request from "../Request";
export interface OrderCustommer {
  id: string;
  owner_id: string;
  custommer_id: string;
  table_id: string;
  table_name: string;
  tip_amount: string;
  tip_staff_id: string;
  staff_name: string;
  price: string;
  timeorder: string;
  custommer_name: string;
}
export interface DetailBill {
  id: string;
  owner_id: string;
  custommer_id: string;
  table_id: string;
  table_name: string;
  custommer_name: string;
  total_order: string;
  tip_amount: string;
  staff_name: string;
  vat_amount: string;
  tax_percent: string;
  timestart: string;
  timeend?: string;
  phone: string;
  sub_total: string;
  listFood: FoodDetail[];
  discount: string;
  feePrice?: string;
}

export interface FoodDetail {
  id: string;
  service_item_id: string;
  price: string;
  quantity: string;
  notefood_id: string;
  side_id: string;
  form_order: string;
  note: string;
  name: string;
  imageCourse: string;
  donvitinh: string;
  notefood_name: string;
  side_name: string;
  description: string;
}
export interface Result {
  status: number;
  message?: string;
  data?: OrderCustommer[];
  total?: string;
}
export interface listSide {
  id: string;
  imageCourse: string;
  name: string;
  price: string;
  quantity: string;
  side_id: string;
  table_id: string;
  typeFood: string;
}
export interface ResultDetailBill<T> {
  status: number;
  message?: string;
  data?: T;
  listSide?: listSide[];
  sub_total: number;
  tax_percent: number;
  feePrice: number;
  discount: number;
  tip_amount: number;
  total_order: number;
  vat_amount: number;
  staff_name: string;
}
export interface ProductInfo {
  id: string;
  order_id: string;
  service_item_id: string;
  quantity: string;
  price: string;
  notefood_id: string;
  side_id: string;
  note: string;
  form_order: string;
  status: string;
  is_payment: string;
  name: string;
  notefood_name: string;
  side_name: any;
  description: string;
  typeFood: number;
}
export const gListCustomerReceipts = async (
  owner_id: number,
  page: number = 1,
  daySearch: string = "",
  staff_id: string = "",
  custommer_search: string = ""
) => {
  return await Request.fetchAPI(
    `owner/gListBillHasBeenPaidByDate.php`,
    "POST",
    { owner_id, page, daySearch, custommer_search, staff_id }
  )
    .then((res: Result) => {
      return res;
    })
    .catch((err: any) => {
      return {
        message: "An error has occurred. Please check your internet connection",
        status: 0,
        data: [],
        total: "1",
      };
    });
};

export const gDetailOrderCustomer = async (
  owner_id: number,
  order_id: string
) => {
  return await Request.fetchAPI(`owner/gDetailBill.php`, "POST", {
    owner_id,
    order_id,
  })
    .then((res: ResultDetailBill<DetailBill>) => {
      return res;
    })
    .catch((err: any) => {
      return {
        message: "An error has occurred. Please check your internet connection",
        status: 0,
        data: undefined,
      };
    });
};

export const gListProductOrder = async (order_id: string) => {
  return await Request.fetchAPI(`owner/gListProductOrder.php`, "POST", {
    order_id,
  })
    .then((res: ResultDetailBill<ProductInfo[]>) => {
      return res;
    })
    .catch((err: any) => {
      return {
        message: "An error has occurred. Please check your internet connection",
        status: 0,
        data: undefined,
        listSide: [],
        sub_total: 0,
        tax_percent: 0,
        feePrice: 0,
        discount: 0,
        tip_amount: 0,
        total_order: 0,
        vat_amount: 0,
        staff_name: "",
      };
    });
};
