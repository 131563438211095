import { useState } from "react";
import { checkedCircle } from "../components/Image";
import "./styles.css";
import { Modal } from "react-bootstrap";
import { useNavigate } from "react-router-dom";

interface ReviewSuccessGoToGoogleProps {
  show: boolean;
  linkURLGG: string;
}

const ReviewSuccessGoToGoogle = ({
  show,
  linkURLGG,
}: ReviewSuccessGoToGoogleProps) => {
  const [googleBusinessID, setGoogleBusinessID] = useState<any>("");
  const mobile_link =
    "/https://search.google.com/local/writereview?placeid=" +
    `${googleBusinessID}`;

  const desktop_link =
    "/https://search.google.com/local/writereview?placeid=" +
    `${googleBusinessID}`;
  const isMobile = window.innerWidth <= 600;
  const linkURL = isMobile ? mobile_link : desktop_link;
  const navigate = useNavigate();
  return (
    <Modal show={show} fullscreen={true}>
      <div
        className="review-restaurant-page align-items-center d-flex"
        style={{ minHeight: `${window.innerHeight}vh"` }}
      >
        <div className="review-introduce">
          <div className="d-flex gap-2 align-items-center">
            <p className="text-primary text-successs-rv mb-0 text-center">
              Thank You For Your Feedback
            </p>
          </div>
          <p className="text-center text-notification-payment-success mt-2">
            Could you please contribute a review on Google? Your support would
            greatly help us.
          </p>
          <div className="px-6">
            <div
              className="btn-submit-review"
              onClick={() =>
                window.open(linkURLGG, "_blank", "rel=noopener noreferrer")
              }
            >
              Go To Google Review
            </div>
          </div>
        </div>
      </div>
    </Modal>
  );
};

export default ReviewSuccessGoToGoogle;
