import {
  FormControlLabel,
  Radio,
  RadioGroup,
  TableCell,
  TableRow,
} from "@mui/material";
import { useEffect, useState } from "react";
import "./styles.css";

interface ContentTableFoodSectionProps {
  index: string;
  nameFood: string;
  value: string;
  handleChangeValue: () => void;
}
const ContentTableFoodSection = ({
  nameFood,
  value,
  handleChangeValue,
  index,
}: ContentTableFoodSectionProps) => {
  const [statusSelect, setStatusSelect] = useState("");
  useEffect(() => {
    setStatusSelect(value);
  }, []);
  return (
    <>
      <TableRow hover role="checkbox" tabIndex={-1}>
        <TableCell align="left">{index}</TableCell>
        <TableCell>{nameFood}</TableCell>
        <TableCell align="center">
          <div className="d-flex justify-content-center mt-1 w-100">
            <div className="">
              <Radio
                checked={statusSelect === "1"}
                onChange={() => setStatusSelect("1")}
                value={"1"}
                sx={{
                  color: "#00A15A",
                  "&.Mui-checked": {
                    color: "#00A15A",
                    margin: 0,
                  },
                }}
              />
            </div>
          </div>
        </TableCell>
        <TableCell align="center">
          <div className="d-flex justify-content-center mt-1 w-100">
            <div className="">
              <Radio
                checked={statusSelect === "2"}
                onChange={() => setStatusSelect("2")}
                value={"2"}
                sx={{
                  color: "#00A15A",
                  "&.Mui-checked": {
                    color: "#00A15A",
                    margin: 0,
                  },
                }}
              />
            </div>
          </div>
        </TableCell>{" "}
        <TableCell align="center">
          <div className="d-flex justify-content-center mt-1 w-100">
            <div className="">
              <Radio
                checked={statusSelect === "3"}
                onChange={() => setStatusSelect("3")}
                value={"5"}
                sx={{
                  color: "#00A15A",
                  "&.Mui-checked": {
                    color: "#00A15A",
                    margin: 0,
                  },
                }}
              />
            </div>
          </div>
        </TableCell>{" "}
        <TableCell align="center">
          <div className="d-flex justify-content-center mt-1 w-100">
            <div className="">
              <Radio
                checked={statusSelect === "4"}
                onChange={() => setStatusSelect("4")}
                value={"6"}
                sx={{
                  color: "#00A15A",
                  "&.Mui-checked": {
                    color: "#00A15A",
                    margin: 0,
                  },
                }}
              />
            </div>
          </div>
        </TableCell>{" "}
        <TableCell align="center">
          <div className="d-flex justify-content-center mt-1 w-100">
            <div className="">
              <Radio
                checked={statusSelect === "5"}
                onChange={() => setStatusSelect("5")}
                value={"7"}
                sx={{
                  color: "#00A15A",
                  "&.Mui-checked": {
                    color: "#00A15A",
                    margin: 0,
                  },
                }}
              />
            </div>
          </div>
        </TableCell>{" "}
        <TableCell align="center">
          <div className="d-flex justify-content-center mt-1 w-100">
            <div className="">
              <Radio
                checked={statusSelect === "6"}
                onChange={() => setStatusSelect("6")}
                value={"1"}
                sx={{
                  color: "#00A15A",
                  "&.Mui-checked": {
                    color: "#00A15A",
                    margin: 0,
                  },
                }}
              />
            </div>
          </div>
        </TableCell>
        <TableCell align="center">
          <div className="d-flex justify-content-center mt-1 w-100">
            <div className="">
              <Radio
                checked={statusSelect === "7"}
                onChange={() => setStatusSelect("7")}
                value={"8"}
                sx={{
                  color: "#00A15A",
                  "&.Mui-checked": {
                    color: "#00A15A",
                    margin: 0,
                  },
                }}
              />
            </div>
          </div>
        </TableCell>
      </TableRow>
    </>
  );
};
export default ContentTableFoodSection;
