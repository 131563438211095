import { createSlice, PayloadAction } from "@reduxjs/toolkit";

export interface OrderWait {
  custommer_id: string,
  name: string,
  listFood: InfoOrder[],
  order_id: string,
  totalPrice: string,
  staff_name: string,
  tip_amount: string,
  tip_staff_id: string,
  discount: string,
  taxPrice?: number,
  table_id?: string,
  is_end?: number,
  payment?: number,
  voucher_code?: string,
  voucher_id?: string
}
interface InfoOrder {
  id?: string
  owner_id?: string
  custommer_id?: string,
  table_id?: string,
  service_item_id?: string,
  price?: string,
  quantity?: string,
  notefood_id?: string,
  side_id?: string,
  form_order?: string,
  note?: string,
  name?: string,
  imageCourse?: string,
  donvitinh?: string,
  table_name?: string,
  notefood_name?: string,
  side_name?: string,
  slcon?: string,
  insufficient?: boolean,
  description?: string,
  status?: string
}

const initialOrderState: OrderWait[] = [];

const orderSlice = createSlice({
  name: "order",
  initialState: initialOrderState,
  reducers: {
    setOrder: (state, action: PayloadAction<OrderWait[]>) => action.payload,

    clearOrder: (state) => initialOrderState,
  },
});

export const selectorOrder = (state: { order: OrderWait[] }) =>
  state.order;

export const {
  setOrder,
  clearOrder
} = orderSlice.actions;
export default orderSlice.reducer;
