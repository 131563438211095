import { parse, format } from "date-fns";

export const FormatDolla = (dolla: number = 0) => {
  return Number(dolla)
    .toFixed(2)
    .replace(/\d(?=(\d{3})+\.)/g, "$&,");
};
//parse fomat dola to number
export const parseDolla = (formattedNumberString: string) => {
  const convertedNumber = parseFloat(formattedNumberString.replace(/,/g, ""));
  return convertedNumber;
};
export function formatPhoneNumber(phoneNumberString: any) {
  var cleaned = ("" + phoneNumberString).replace(/\D/g, "");
  var match = cleaned.match(/^(\d{3})(\d{3})(\d{4})$/);

  if (match) {
    return match[1] + "-" + match[2] + "-" + match[3];
  }
  return null;
}
export const formatDate = (originalDateString: string) => {
  const originalDate = new Date(originalDateString);
  const year = originalDate.getFullYear();
  const month = String(originalDate.getMonth() + 1).padStart(2, "0");
  const day = String(originalDate.getDate()).padStart(2, "0");
  const hours = String(originalDate.getHours()).padStart(2, "0");
  const minutes = String(originalDate.getMinutes()).padStart(2, "0");
  const seconds = String(originalDate.getSeconds()).padStart(2, "0");

  const formattedDate = `${year}-${month}-${day} ${hours}:${minutes}:${seconds}`;
  return formattedDate;
};
export const FormatNumber = (number: number) => {
  const formatNumber = (num: number): string => {
    return num.toLocaleString(undefined, {
      minimumFractionDigits: 0,
      maximumFractionDigits: 0,
    });
  };

  return formatNumber(number);
};
export function convertDateString(dateString: string, fomattype: string) {
  const dateObject = parse(dateString, "EEE MMM dd yyyy", new Date());
  return format(dateObject, fomattype);
}
