
import "./Styles.css";
import AddIcon from '@mui/icons-material/Add';
import RemoveIcon from '@mui/icons-material/Remove';
import { FormatDolla } from "../../../../utils/format";
import default_product from "../../../../assets/images/default_product.png";

interface FoodOrderItemsProps {
    foodName: string,
    noteFood_id: string,
    price: number,
    imageFood: string,
    noteFood: string,
    quantity: number,
    food_id: string,
    index: number,
    noteChef: string,
    changeQuantity: (index: number, quantity: number) => void
}

const FoodOrderItems = ({
    foodName = '',
    price = 0, imageFood = '', noteFood = '', quantity = 0, food_id, changeQuantity, noteFood_id, index, noteChef = '',
}: FoodOrderItemsProps) => {


    return (
        <div className="border-items-food-order pb-1">
            <div className="h-55px d-flex justify-content-between align-items-center">
                <div className="d-flex gap-2">
                    <img src={imageFood} alt="" className="h-55px w-55px flex-shink-0 rounded-12px" onError={(e) => {
                        e.currentTarget.src = default_product;
                    }} />
                    <div className="">
                        <p className="name-food-order-items mb-0 text-truncate maxwidth-110px">{foodName}</p>
                        <div className="d-flex">
                            <p className="mb-0 name-price-food-order-items h-fit">{FormatDolla(price)}</p>
                            <p className="name-dollar-food-order-items h-fit">$</p>
                        </div>
                    </div>
                </div>
                <div className="d-flex align-items-center">
                    <button className="h-40px w-44px rounded-42px border-1px-primary bg-white" onClick={() => changeQuantity(index, quantity - 1)}>
                        <RemoveIcon className="text-12B064" />
                    </button>
                    <div className="w-42px d-flex justify-content-center align-items-center">
                        <p className="mb-0 number-food-order">{quantity}</p>
                    </div>
                    <button className="h-40px w-44px rounded-42px border-1px-primary bg-white" onClick={() => changeQuantity(index, quantity + 1)}>
                        <AddIcon className="text-12B064" />
                    </button>
                </div>
            </div>
            <div className="ps-2 mt-1">
                {noteFood && (<p className="m-0 note-food-order">- {noteFood}</p>)}
                {noteChef && (<p className="mb-0 note-food-order m-0 w-full">- {noteChef}</p>)}
            </div>
        </div>
    );
};

export default FoodOrderItems;
