import React, { useState } from "react";
import SwipeableViews from "react-swipeable-views";
import "./styles.css";
import { delNotificationById } from "../../api/apiOwner/notificationApi";
import { getLanguageUI } from "../../Services/languageUI";

interface NotificationItemProps {
  // onDelete: () => void
  type: number;
  table_name: string;
  timestamp: string;
  is_read: number;
  notificationId: string;
  title: string;
  is_end_payment?: number;
  showNotificaton: boolean;
}
const NotificationItem = ({
  is_read,
  table_name,
  timestamp,
  type,
  notificationId,
  title,
  is_end_payment = 0,
  showNotificaton,
}: NotificationItemProps) => {
  const languageUI = getLanguageUI().layout;
  const [deleteNotification, setDeleteNotification] = useState(true);
  const handleSwipe = async (index: number) => {
    if (index === 0) {
      setDeleteNotification(false);
    }
    if (index === 1) {
      setDeleteNotification(false);
    }
    await delNotificationById(notificationId);
  };
  return (
    <SwipeableViews
      enableMouseEvents
      onChangeIndex={handleSwipe}
      className={`${deleteNotification ? "" : "d-none"}`}
    >
      <div
        style={{
          position: "relative",
          width: "100%",
          backgroundColor: `${
            type === 1
              ? is_end_payment === 1
                ? "#fe0000"
                : "#2a812b"
              : type === 2
              ? "#009f75"
              : "#0571d3"
          }`,
        }}
        className={` ${
          showNotificaton ? "d-flex justify-content-center" : ""
        } box-notification-router p-4 `}
      >
        <div className="d-flex gap-3 align-items-center">
          <p className="text-box-notification-router">{table_name}</p>
          <p
            className={`${
              showNotificaton ? "d-none" : ""
            } text-box-notification-router `}
          >
            {type === 1 ? title : type === 2 ? languageUI.help : title}
          </p>
        </div>
        <p
          className={` ${
            showNotificaton ? "d-none" : ""
          } text-box-notification-router`}
        >
          {timestamp}
        </p>
      </div>
      <div style={{ position: "relative", width: "100%" }}></div>
    </SwipeableViews>
  );
};

export default NotificationItem;
