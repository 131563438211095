import React from "react";
import { Accordion, Form, Modal } from "react-bootstrap";
import "./Styles.css";
import OrderListITem from "../../../webcheckin/components/OrderListITem/OrderListITem";
import * as orderConfilmApi from "../../../../api/apiCheckin/orderConfilmApi";
import { useEffect, useState } from "react";
// import * as orderConfilmApi from "../../api/apiCheckin/orderConfilmApi";
import * as paymentApi from "../../../../api/apiCheckin/paymentApi";
import { FormatDolla, parseDolla } from "../../../../utils/format";
import { chevronDown } from "../../../../components/ImgExport";
import * as userSlice from "../../../../store/slices/userSlice";
import ModalToast from "../ModalToast/ModalToastErr";
import { useSelector } from "react-redux";
import { getLanguageUI } from "../../../../Services/languageUI";
import * as orderSlice from "../../../../store/slices/orderSlice";
import * as tableSlice from "../../../../store/slices/tableSlice";
import { useDispatch } from "react-redux";
import OrderListITemPrintPosMenu from "../../../webcheckin/components/OrderListITem/OrderListITemPrintPosMenu";
import ModalLoading from "../../../../components/ModalLoading";
import {
  CancelPaymentIntent,
  CreatePaymentIntent,
  PaymentIntentStripe,
} from "../../../../api/apiOwner/stripeApi";
import ModalPaymentError from "../ModalPaymentError/ModalPaymentError";
import ModalReader from "../ModalReader";
import ModalSplitBill from "../../../webowner/components/ModalSplitBill/ModalSplitBill";
import AddIcon from "@mui/icons-material/Add";
import * as SettingPrinterApi from "../../../../api/apiOwner/SettingPrinterApi";
import { PrintOrderPage } from "../../../../api/apiOwner/printerApi";
import HelpOutlineSharpIcon from "@mui/icons-material/HelpOutlineSharp";
import { ClickAwayListener, Tooltip } from "@mui/material";
import ModalRemoveBillToTable from "../ModalRemoveBillToTable/ModalRemoveBillToTable";
import { TableInfo, swapOrderTable } from "../../../../api/apiOwner/tableApi";
import socket from "../../../../utils/socket";
import ModalSelectStaffTip from "../ModalSelectStaffTip/ModalSelectStaffTip";
import ArrowForwardIosRoundedIcon from "@mui/icons-material/ArrowForwardIosRounded";
import ModalVoidBill from "../ModalVoidBill/ModalVoidBill";
import {
  checkVoucher,
  VoucherCheckPass,
} from "../../../../api/apiOwner/voucherApi";

interface ModalPayBillTablePOSProps {
  show: boolean;
  table_name?: string;
  table_id?: string;
  listStaff?: paymentApi.TipStaff[];
  tipList?: paymentApi.Tip[];
  OrderWait?: orderConfilmApi.OrderWait[];
  handleClose: () => void;
  handleSuccess: (tableOL?: tableSlice.Table[]) => void;
  handlePlitBill?: (type: number) => void;
  showToastPrint?: (mess: string) => void;
  listPrinterName: SettingPrinterApi.ListPrinterByCatalog[];
  listTable: TableInfo[];
  gListFloorByOwner: () => void;
  chooseBillHandle: (order_id?: string) => void;
  handleMoveBillSuccess: (tableId: number, floor_id: string) => void;
  floor_id: string;
}

const ModalPayBillTablePOS = ({
  show,
  table_name = "",
  table_id = "",
  OrderWait = [],
  listStaff = [],
  tipList = [],
  showToastPrint,
  handleSuccess,
  handleClose,
  handlePlitBill,
  listPrinterName,
  listTable,
  gListFloorByOwner,
  chooseBillHandle,
  handleMoveBillSuccess,
  floor_id,
}: ModalPayBillTablePOSProps) => {
  const [showModalSplitBill, setShowModalSplitBill] = useState(false);
  const [showModalVoidBill, setShowModalVoidBill] = useState(false);
  const LanguageUI = getLanguageUI().posTable;
  const UserIdStore = useSelector(userSlice.selectorUserId) ?? 0;
  const taxOffline = useSelector(userSlice.selectorTax) ?? 0;
  const listOrderOffline = useSelector(orderSlice.selectorOrder);
  const TableOffline = useSelector(tableSlice.selectorTable);
  const pos_id = useSelector(userSlice.selectorPosId) ?? 0;
  const dispatch = useDispatch();
  const [tax, setTax] = useState<number>(0);
  const [isOnline, setIsOnline] = useState(navigator.onLine);
  // const [tipList, setTipList] = useState<paymentApi.Tip[]>([])
  const [tipName, setTipName] = useState<string>(""); //tên % tip
  const [valueTipCustom, setValueTipCustom] = useState<number | string>("");
  const [valueTip, setValueTip] = useState<number>(0); //id tip đang chọn
  const [TipPrice, setTipPrice] = useState<number>(0);
  const [staffName, setStaffName] = useState<string>(""); //tên staff dc chọn nhận tip
  const [showModalChooseNameTip, setShowModalChooseNameTip] = useState(false);
  const [chooseDiscountType, setChooseDiscountType] = useState<number>(1);
  const [staffId, setStaffId] = useState<string>(""); //staff dc chọn nhận tip
  const [choosepaymentMethodCash, setChoosePaymentMethodCash] = useState(true);
  const [listOrderPay, setListOrderPay] = useState<string[]>([]);
  const [totalPrice, setTotalPrice] = useState<number>(0);
  const [discountOrder, setDiscountOrder] = useState<number>(0);
  const [receivedAmount, setReceivedAmount] = useState<string>(""); //tiền trả
  const [reMainingAmount, setreMainingAmount] = useState<number | string>(""); // tiền thối
  const [messageError, setMessageError] = useState<string>("");
  const [showModalAlertFail, setshowModalAlertFail] = useState(false);
  const nameStore = useSelector(userSlice.selectorNameStore);
  const addressStore = useSelector(userSlice.selectorAddress);
  const phoneOwnerStore = useSelector(userSlice.selectorPhoneNumber);
  const [ShowLoading, setShowLoading] = useState<boolean>(false);
  const [StripeTerminal, setStriperTiminal] = useState<any>();
  const [showModalPaymentErr, setShowModalPaymentErr] =
    useState<boolean>(false);
  const [messageErrPayment, setMessageErrPayment] = useState("");
  const [showModalReader, setShowModalReader] = useState(false);
  const [paymentIntentId, setpaymentIntentId] = useState("");
  const [orderSplitBill, setOrderSplitBill] =
    useState<orderConfilmApi.OrderWait>();
  const [openToolTip, setOpenToolTip] = useState(false);
  const [feeStripe, setFeeStripe] = useState<boolean>(false);
  const [feePrice, setFeePrice] = useState<number>(0);
  const [showModalRemoveBill, setShowModalRemoveBill] =
    useState<boolean>(false);
  const [voucherCode, setVoucherCode] = useState("");
  const [infoVoucher, setinfoVoucher] = useState<VoucherCheckPass>();
  const [messageErrorVoucher, setMessageErrorVoucher] = useState<string>("");

  const CheckVouchePass = async () => {
    if (UserIdStore) {
      const req = await checkVoucher(voucherCode, UserIdStore);
      if (Number(req?.status) === 1) {
        setinfoVoucher(req?.data ?? {});
        setMessageErrorVoucher("");
      } else {
        setinfoVoucher({});
        setMessageErrorVoucher(req?.message ?? "");
      }
    }
  };
  const dismisModalRemoveBill = () => {
    setShowModalRemoveBill(false);
  };
  const handleTooltipClose = () => {
    setOpenToolTip(false);
  };

  // const [showToastError, setShowToastError] = useState<boolean>(false)
  // const [messageError, setMessageError] = useState<string>('')
  const dismisMoalSplitBill = () => {
    setShowModalSplitBill(false);
  };
  const dismisMoalVoidBill = () => {
    setShowModalVoidBill(false);
  };
  const handleSuccessSplitBil = async (id: string) => {
    setOrderSplitBill(undefined);
    dismisMoalSplitBill();
    handlePlitBill?.(2);
    setListOrderPay([...listOrderPay, id]);
    setShowLoading(false);
  };
  const handleSuccessVoidBil = async (id: string, status: number) => {
    setOrderSplitBill(undefined);
    dismisMoalVoidBill();
    if (status === 2) {
      const NewOrder = listOrderOffline.filter((item) => id == item?.order_id);
      dispatch(orderSlice.setOrder(NewOrder));
      const NewListTable = TableOffline.map((item) => {
        return item.id == table_id
          ? {
              ...item,
              status: "0",
            }
          : item;
      });
      dispatch(tableSlice.setTable(NewListTable));
      socket.emit("PushNotificationPayMent", {
        table_id,
        ownerID: UserIdStore,
        customer_name: "",
        custommerId: 0,
        message: `Table status changed successfully`,
        order_id: 0,
      });
      handleSuccess();
    } else {
      handleSuccess();
    }

    // setListOrderPay([...listOrderPay, id]);
    setShowLoading(false);
  };
  const dismisModalReader = () => {
    setShowModalReader(false);
  };

  const dismisModalPaymentErr = () => {
    setShowModalPaymentErr(false);
  };

  const dismisModalChooseNameTip = () => {
    setShowModalChooseNameTip(false);
  };
  const handleChangeAllOrder = (checked: boolean) => {
    if (checked) {
      setListOrderPay(
        OrderWait.map((item) => {
          return item.order_id;
        })
      );
    } else {
      setListOrderPay([]);
    }
  };
  const HandleChangOrder = (checked: boolean, order_id: string) => {
    if (checked) {
      setListOrderPay([...listOrderPay, order_id]);
    } else {
      setListOrderPay([...listOrderPay.filter((item) => item != order_id)]);
    }
  };

  //tính lại tổng tiền của hóa đơn
  useEffect(() => {
    var total = 0;
    // var discount = 0;
    OrderWait.map((item) => {
      if (listOrderPay.some((ord) => item.order_id == ord)) {
        total += Number(item?.totalPrice);
        // discount += Number(item?.discount);
      }
    });
    setTotalPrice(total);
    // setDiscountOrder(discount);
  }, [listOrderPay]);

  useEffect(() => {
    var feee = 0;
    if (choosepaymentMethodCash === false && feeStripe) {
      setFeePrice(((totalPrice + TipPrice) * 3) / 100);
      feee = ((totalPrice + TipPrice) * 3) / 100;
    } else {
      setFeePrice(0);
    }
    if (infoVoucher?.id) {
      if (Number(infoVoucher?.type) === 1) {
        if (Number(infoVoucher?.is_gifvoucher) === 1) {
          setDiscountOrder(
            parseDolla(
              FormatDolla(
                ((totalPrice +
                  feee +
                  (Number(TipPrice) > 0 ? Number(TipPrice) : 0) +
                  (totalPrice * tax) / 100) *
                  Number(infoVoucher?.discount_value)) /
                  100
              )
            )
          );
        } else {
          setDiscountOrder(
            parseDolla(
              FormatDolla(
                (totalPrice * Number(infoVoucher?.discount_value)) / 100
              )
            )
          );
        }
      } else {
        var amount =
          totalPrice +
          feee +
          (Number(TipPrice) > 0 ? Number(TipPrice) : 0) +
          (totalPrice * tax) / 100;
        if (Number(infoVoucher?.is_gifvoucher) === 1) {
          setDiscountOrder(
            parseDolla(
              FormatDolla(
                Number(infoVoucher?.discount_value) > amount
                  ? amount
                  : Number(infoVoucher?.discount_value)
              )
            )
          );
        } else {
          setDiscountOrder(
            parseDolla(
              FormatDolla(
                Number(infoVoucher?.discount_value) > totalPrice
                  ? totalPrice
                  : Number(infoVoucher?.discount_value)
              )
            )
          );
        }
      }
    } else {
      setDiscountOrder(0);
    }
  }, [totalPrice, choosepaymentMethodCash, infoVoucher]);
  const gConfig = async () => {
    if (UserIdStore) {
      if (!isOnline) {
        setTax(taxOffline);
      } else {
        const config = await paymentApi.getConfig(UserIdStore);
        if (config.status == 1) {
          setTax(Number(config?.data?.tax) ?? 0);
          setFeeStripe(Number(config?.data?.fee_stripe) === 1 ? true : false);
        }
      }
    }
  };
  function handleChangeInput(tip?: string) {
    if (tip) {
      setValueTipCustom(Number(tip));
      setValueTip(0);
      setTipName("");
      setTipPrice(Number(tip));
    } else {
      setValueTipCustom("");
      setValueTip(0);
      setTipName("");
      setTipPrice(0);
    }
  }
  useEffect(() => {
    if (receivedAmount != "") {
      var total =
        totalPrice +
        feePrice +
        TipPrice +
        (totalPrice * tax) / 100 -
        discountOrder;
      if (Number(receivedAmount) > total) {
        setreMainingAmount((Number(receivedAmount) - total).toFixed(2));
      } else {
        setreMainingAmount("");
      }
    } else {
      setreMainingAmount("");
    }
  }, [receivedAmount]);

  const SubmitPayBill = () => {
    var total =
      totalPrice +
      feePrice +
      TipPrice +
      (totalPrice * tax) / 100 -
      discountOrder;
    if (listOrderPay.length == 0) {
      setMessageError("Please select the invoice to pay!");
      setshowModalAlertFail(true);
    } else if (TipPrice > 0 && staffId == "") {
      setMessageError("Please select staff to receive tips!");
      setshowModalAlertFail(true);
    } else if (choosepaymentMethodCash && staffId != "" && TipPrice <= 0) {
      setMessageError("Tipping must be greater than 0!");
      setshowModalAlertFail(true);
    } else {
      if (choosepaymentMethodCash) {
        // if (Number(receivedAmount) <= 0) {
        //     setMessageError('Please enter received amount!')
        //     setshowModalAlertFail(true)
        // } else
        if (receivedAmount && Number(receivedAmount) < total) {
          setMessageError(
            "The amount received must be greater than or equal to the total price."
          );
          setshowModalAlertFail(true);
        } else {
          PaymentBillPost(1);
        }
      } else {
        // PaymentBillPost(2)
        // connectReaderHandler()
        PaymentIntentClient();
      }
    }
  };

  const PaymentBillPost = async (type: number) => {
    setShowLoading(true);
    var TotalPay =
      totalPrice +
      feePrice +
      TipPrice +
      (totalPrice * tax) / 100 -
      discountOrder;
    // var tax_price = tax > 0 ? (totalPrice * tax / 100) : 0;
    var tip_amount = TipPrice > 0 ? TipPrice : 0;
    // console.log({ listOrderPay, table_id, ownerID: UserIdStore, TotalPay, tax, staffId, tip_amount, type });
    if (UserIdStore) {
      if (!isOnline) {
        const NewOrder = listOrderOffline.map((item) =>
          listOrderPay.some((od) => od == item?.order_id)
            ? {
                ...item,
                is_end: 1,
                payment: type,
                totalPrice: TotalPay.toString(),
                taxPrice: (totalPrice * tax) / 100,
                tip_staff_id: item?.order_id == listOrderPay[0] ? staffId : "0",
                tip_amount:
                  item?.order_id == listOrderPay[0]
                    ? tip_amount.toString()
                    : "0",
              }
            : item
        );
        dispatch(orderSlice.setOrder(NewOrder));
        const NewListTable = TableOffline.map((item) => {
          return item.id == table_id
            ? {
                ...item,
                status: listOrderPay.length === OrderWait.length ? "0" : "1",
                is_visa:
                  listOrderPay.length === OrderWait.length ? false : true,
              }
            : item;
        });
        dispatch(tableSlice.setTable(NewListTable));
        handleSuccess(NewListTable);
        setShowLoading(false);
        PrintCard();
      } else {
        const payHandle = await paymentApi.PaymentBillPOS(
          UserIdStore,
          table_id,
          TotalPay,
          tax,
          type,
          listOrderPay,
          staffId,
          tip_amount,
          feePrice,
          discountOrder,
          infoVoucher?.voucher_code ?? "",
          infoVoucher?.voucher_id ?? ""
        );
        if (payHandle.status == 1) {
          socket.emit("PushNotificationPayMent", {
            table_id,
            ownerID: UserIdStore,
            customer_name: "",
            custommerId: 0,
            message: payHandle?.message,
            order_id: 0,
          });
          const NewOrder = listOrderOffline.filter(
            (item) => !listOrderPay.some((od) => od == item?.order_id)
          );
          dispatch(orderSlice.setOrder(NewOrder));
          const NewListTable = TableOffline.map((item) => {
            return item.id == table_id
              ? {
                  ...item,
                  status: listOrderPay.length === OrderWait.length ? "0" : "1",
                }
              : item;
          });
          dispatch(tableSlice.setTable(NewListTable));
          handleSuccess();
          setShowLoading(false);
          PrintCard();
        } else {
          setMessageError(payHandle.message ?? "");
          setshowModalAlertFail(true);
          setShowLoading(false);
        }
      }
    }
  };
  useEffect(() => {
    if (OrderWait.length === 1) {
      setListOrderPay([OrderWait[0]?.order_id]);
    } else {
      setListOrderPay([]);
    }
  }, [OrderWait]);

  useEffect(() => {
    if (show) {
      gConfig();

      // setListOrderPay([...OrderWait.map(item => { return item?.order_id })]);
    }
  }, [show]);
  useEffect(() => {
    // Đăng ký sự kiện kiểm tra trạng thái kết nối mạng
    window.addEventListener("online", handleOnline);
    window.addEventListener("offline", handleOffline);

    // Hủy đăng ký sự kiện khi component bị hủy
    return () => {
      window.removeEventListener("online", handleOnline);
      window.removeEventListener("offline", handleOffline);
    };
  }, []);

  const handleOnline = () => {
    setIsOnline(true);
  };

  const handleOffline = () => {
    setIsOnline(false);
  };
  const isZInListFood = (
    z: number[],
    listFood: orderConfilmApi.InfoOrder[]
  ) => {
    return z.every((zItem) => listFood.some((item) => item.typeFood === zItem));
  };
  const PrintCard = async () => {
    try {
      const printBillList = OrderWait.filter((item) =>
        listOrderPay.some((val) => Number(val) === Number(item?.order_id))
      );

      // console.log(rawhtml);
      // console.log(listCartFood);

      if (listPrinterName.length > 0) {
        listPrinterName.map(async (itempr) => {
          if (itempr?.printType.some((pr) => pr === 4)) {
            const rawhtml =
              `
          <!DOCTYPE html>
          <html lang="en">

          <head>
          <meta charset="UTF-8">
          <meta name="viewport" content="width=device-width, initial-scale=1.0">
          <title>Hóa đơn</title>
          <style type="text/css">
            .name-food {
              font-weight: 600;
            }

            .quanlity-number {
              font-weight: 600;
            }

            p, h6, h4 {
              margin-bottom: 0;
              margin-top: 0;
              line-height: normal;
            }

            .fw-normal{
               !important;
            }
          </style>

          </head>

          <body>

          <div id="bill" style="width: 100%; padding-right: 45px; padding-bottom: 80px">
            <h1 style="width: 100%; margin:  auto; text-align: center">Bill</h1>
            <div style="width: 100%;">
              <h5 class="" style="; margin: 0; text-align: start;">${
                Number(table_id) > 0 ? `Table name: ${table_name}` : ""
              }</h5>
            </div>
            
            
            ${printBillList
              .map((item, i) =>
                // eslint-disable-next-line @typescript-eslint/no-unused-expressions
                itempr?.printType.some((pr) => pr === 4)
                  ? `
            <div style="display: table; width: 100% ; padding-top: 10px ; padding-right: 10px;">
               <div style = "display: table-row; padding-top: 10px; width: 100%" >

                <div style="display: table-cell; width: auto; text-align: left; padding-right: 10px;  padding-top: 10px">
                  <h4>- Bill-${item.order_id}</h4>
                </div>
                <div style="display: table-cell; text-align: right; flex-shrink: 0; width: fit-content;padding-top:4px;">
                </div>
              </div >
              </div >

           ${item.listFood
             .map(
               (cart, i) => `
              <div style="display: table; width: 100%">
           <div style = "display: table-row; padding-top: 10px;  padding-right: 10px; width: 100%" >

                <div style="display: table-cell; width: auto; text-align: left; padding-right: 10px;  padding-top: 10px">
                <h6 class="fw-normal" style=" margin: 0; text-align: start;">${
                  i + 1
                }.${cart?.name}</h6>
                  ${
                    cart?.note
                      ? `<p class="fw-normal" style="font-size: 24px ; "  >-${cart?.note}</p>`
                      : ""
                  }
                    
                  ${
                    cart?.notefood_name
                      ? `<p class="fw-normal"  style="width: 100% ;font-size: 24px ; ; white-space: nowrap">-${cart?.notefood_name}</p>`
                      : ""
                  }
                </div>
                <div style="display: table-cell; text-align: right; flex-shrink: 0; width: fit-content;padding-top:4px;">
                <h6 class="fw-normal quanlity-number; margin-top: 10px !important ">${Number(
                  cart?.quantity
                )}x${Number(cart?.price)}$</h6>
                  ${
                    Number(cart?.form_order) !== 1
                      ? `<h6 style=" flex-shrink: 0;  white-space: nowrap; font-weight: 600;">Take away</h6>`
                      : `<h6 style=" flex-shrink: 0;  white-space: nowrap; font-weight: 600;">Diner</h6>`
                  }
                </div>
              </div >
              </div >

              `
             )
             .join(" ")}
          ${
            item.listSide?.length
              ? item?.listSide.length > 0 &&
                `
            <h6 style=" margin: 0; text-align: start; font-size: 24px " ><li>Free Side</li></h6>
            ${item?.listSide
              .map(
                (item, index) =>
                  `
            <h6 style=" margin: 0; text-align: start; font-size: 24px " >${
              index + 1
            } ${item.name} (x${item.quantity})</h6>
            `
              )
              .join(" ")}
          `
              : ""
          }
          `
                  : ""
              )
              .join(" ")}
           <div style="display: table; width: 100%;  padding-right: 40px;">
        <div style = "display: table-row; padding-top: 0px; width: 100%" >
          <div style="display: table-cell; width: auto; text-align: left; padding-right: 10px;  padding-top: 4px">
            <p class="fw-normal" style="font-size: 24px ; " >Sub Total:</p>
          </div>
          <div style="display: table-cell; text-align: right; flex-shrink: 0; width: fit-content;padding-top:4px;">
          <p class="fw-normal" style="font-size: 24px ; " >$${FormatDolla(
            totalPrice
          )}</p>
            </div>
          </div>
          </div>
           <div style="display: table; width: 100%">

          <div style = "display: table-row; padding-top: 0px; width: 100%" >
            <div style="display: table-cell; width: auto; text-align: left; padding-right: 10px;  padding-top: 4px">
            <p class="fw-normal " style="font-size: 24px ; ">Tip:</p>
            </div>
            <div style="display: table-cell; text-align: right; flex-shrink: 0; width: fit-content;padding-top:4px;">
            <p class="fw-normal " style="font-size: 24px ; ">$${
              TipPrice > 0 ? FormatDolla(TipPrice) : 0
            }</p>
          </div>
        </div>
        </div>
          <div style="display: table; width: 100%">

          <div style = "display: table-row; padding-top: 0px; width: 100%" >
            <div style="display: table-cell; width: auto; text-align: left; padding-right: 10px;  padding-top: 4px">
            <p class="fw-normal" style="font-size: 24px ; ">Tip for:</p>
            </div>
            <div style="display: table-cell; text-align: right; flex-shrink: 0; width: fit-content;padding-top:4px;">
            <p class="fw-normal" style="font-size: 24px ; ">${
              staffName !== "" ? staffName : "---"
            }</p>
          </div>
        </div>
        </div>
           <div style="display: table; width: 100%">

          <div style = "display: table-row; padding-top: 0px; width: 100%" >
            <div style="display: table-cell; width: auto; text-align: left; padding-right: 10px;  padding-top: 4px">
            <p class="fw-normal" style="font-size: 24px ; ">Tax:</p>
            </div>
            <div style="display: table-cell; text-align: right; flex-shrink: 0; width: fit-content;padding-top:4px;">
            <p class="fw-normal" style="font-size: 24px ; ">$${
              tax > 0 ? FormatDolla((totalPrice * tax) / 100) : 0
            }</p>
          </div>
        </div>
        </div>
           <div style="display: table; width: 100%">

          <div style = "display: table-row; padding-top: 0px; width: 100%" >
            <div style="display: table-cell; width: auto; text-align: left; padding-right: 10px;  padding-top: 4px">
            <p style="font-size: 24px ; ">Discount:</p>
            </div>
            <div style="display: table-cell; text-align: right; flex-shrink: 0; width: fit-content;padding-top:4px;">
            <p style="font-size: 24px ; ">$${
              discountOrder > 0 ? FormatDolla(discountOrder) : 0
            }</p>
            </div>
        </div>
        </div>` +
              (!choosepaymentMethodCash && feeStripe
                ? `<div style="display: table; width: 100%">

            <div style="display: table-row; padding-top: 0px; width: 100%" >
              <div style="display: table-cell; width: auto; text-align: left; padding-right: 10px;  padding-top: 4px">
                <p style="font-size: 24px ; ">Convenience fee:</p>
              </div>
              <div style="display: table-cell; text-align: right; flex-shrink: 0; width: fit-content;padding-top:4px;">
                <p style="font-size: 24px ; ">$${
                  feePrice > 0 ? FormatDolla(feePrice) : 0
                }</p>
              </div>
            </div>
          </div>`
                : ``) +
              `
           <div style="display: table; width: 100%">

        <div style = "display: table-row; padding-top: 0px; width: 100%" >
            <div style="display: table-cell; width: auto; text-align: left; padding-right: 10px;  padding-top: 4px">
            <p style="; font-size: 30px ;">Total:</p>
            </div>
            <div style="display: table-cell; text-align: right; flex-shrink: 0; width: fit-content;padding-top:4px;">
            <p style=" ; font-size: 30px">$${FormatDolla(
              totalPrice +
                feePrice -
                discountOrder +
                TipPrice +
                (totalPrice * tax) / 100
            )}</p>
            </div>
        </div>
        </div>
        
            <div style="width: 100%; margin-top: 38px">
    <h6 class="" style="font-weight: 400; margin: 0; text-align: center;white-space: nowrap; overflow: hidden">------------------------------------------------------------</h6>

              <h5 class="" style="; margin: 0; text-align: center;">${nameStore}</h5>
              <h5 class="" style="; margin: 0; text-align: center;">${addressStore}</h5>
              <h5 class="" style="; margin: 0; text-align: center;">${phoneOwnerStore}</h5>
            </div>
            <h2 style="width: 100%; margin: 0 auto; vertical-align: middle; text-align: center;">Thank you!</h2>
          </div >
          </body >
          </html >
  `;
            const req = await PrintOrderPage(
              UserIdStore ?? 0,
              itempr.printer_name,
              rawhtml.toString()
            );
            if (!req?.status) {
              // setMessageError(req?.message ?? ("Error printing on " + item.printer_name))
              // setShowToastError(true)
              // showToastPrint?.(req?.message ?? ("Error printing on " + item.printer_name))
            }
          }
        });
      } else {
        setMessageError("Please configure the printer in settings!");
        setshowModalAlertFail(true);
      }
      // console.log(text);
      // console.log(rawhtml);

      // await printJS({

      //   printable: rawhtml,
      //   targetStyles: ["*"],
      //   targetStyle: ["*"],
      //   frameId: "cardprint",
      //   type: "raw-html",
      //   showModal: false,
      //   css: ["./Styles.css"],
      //   style: ".abc {font-size: 30px; color: red;}",
      //   header: rawhtml,
      //   onError: async (error: any) => {
      //     console.error("Error Printing: ", error);
      //   },
      //   // silent: false,3
      //   onPrintDialogClose: async () => {
      //     console.info("Print Window Closed");

      //   }
      // });
      // setTimeout(() => {
      //   const arr = listCartFood ?? [];
      //   printCardInfo(arr, arr.length - 1, arr[arr.length - 1]?.food_id, arr[arr.length - 2]?.food_id ?? '', arr[arr.length - 1], Number(table_id) > 0 ? NameTable : '', orderid ?? '', '')
      // }, 1000);
    } catch (error) {
      console.error("Error:", error);
    }
  };

  ////////////////

  //tạo hóa đơn thanh toán
  async function PaymentIntentClient() {
    setShowModalReader(true);
    var TotalPay =
      totalPrice +
      feePrice -
      discountOrder +
      TipPrice +
      (totalPrice * tax) / 100;
    var tip_amount = TipPrice > 0 ? TipPrice : 0;
    const req = await CreatePaymentIntent(
      UserIdStore ?? 0,
      parseDolla(FormatDolla(TotalPay)) * 100,
      pos_id
    );
    if (Number(req?.status) === 1) {
      // return req?.secret
      // setShowModalReader(false)
      setpaymentIntentId(req?.paymentIntents);
      PaymentWithReader(req?.paymentIntents ?? "");
    } else {
      setShowModalReader(false);
      setMessageErrPayment(req.message ?? "");
      setShowModalPaymentErr(true);
      return "";
    }
  }

  //tiến hành chờ wuetj thẻ
  const PaymentWithReader = async (paymentIntents: string) => {
    var TotalPay =
      totalPrice +
      feePrice -
      discountOrder +
      TipPrice +
      (totalPrice * tax) / 100;
    // var tip_amount = TipPrice > 0 ? (TipPrice) : 0;

    const req = await PaymentIntentStripe(
      UserIdStore ?? 0,
      paymentIntents,
      pos_id
    );
    if (Number(req?.status) === 1) {
      var tip_amount = req?.tipAmount ?? 0;
      const payHandle = await paymentApi.PaymentBillPOS(
        UserIdStore,
        table_id,
        TotalPay,
        tax,
        2,
        listOrderPay,
        staffId,
        tip_amount,
        feePrice,
        discountOrder,
        infoVoucher?.voucher_code ?? "",
        infoVoucher?.voucher_id ?? ""
      );
      if (payHandle.status == 1) {
        socket.emit("PushNotificationPayMent", {
          table_id,
          ownerID: UserIdStore,
          customer_name: "",
          custommerId: 0,
          message: payHandle?.message,
          order_id: 0,
        });
        const NewOrder = listOrderOffline.filter(
          (item) => !listOrderPay.some((od) => od == item?.order_id)
        );
        dispatch(orderSlice.setOrder(NewOrder));
        const NewListTable = TableOffline.map((item) => {
          return item.id == table_id
            ? {
                ...item,
                status: listOrderPay.length === OrderWait.length ? "0" : "1",
              }
            : item;
        });
        dispatch(tableSlice.setTable(NewListTable));
        handleSuccess();
        dismisModalReader();
        PrintCard();
      } else {
        dismisModalReader();
        setMessageErrPayment(payHandle.message ?? "");
        setShowModalPaymentErr(true);
      }
    } else {
      setShowModalReader(false);
      if (!req?.message?.includes("canceled")) {
        setMessageErrPayment(req.message ?? "");
        setShowModalPaymentErr(true);
      }
      return "";
    }
  };

  const CancelPayment = async () => {
    if (paymentIntentId !== "") {
      const req = await CancelPaymentIntent(
        UserIdStore ?? 0,
        paymentIntentId,
        pos_id
      );
      if (Number(req?.status) === 1) {
        setpaymentIntentId("");
        dismisModalReader();
      } else {
        dismisModalReader();
        setMessageErrPayment(req.message ?? "");
        // setShowModalPaymentErr(true);
        return "";
      }
    }
  };

  const handleSwapOrderTable = async (tableRemove: number) => {
    const data: { order_id: number }[] = listOrderPay.map((item) => ({
      order_id: Number(item),
    }));
    const req = await swapOrderTable(
      UserIdStore,
      Number(table_id),
      tableRemove,
      data
    );
    if (req?.status === 1) {
      handlePlitBill?.(2);
      dismisModalRemoveBill();
      handleClose();
      handleMoveBillSuccess(tableRemove, floor_id);
    } else {
      setMessageError(req?.message ?? "Please check your internet!");
    }
  };
  return (
    <>
      {/* <ModalToast show={showToastError} handleClose={() => setShowToastError(false)} content={messageError} /> */}
      <ModalLoading show={ShowLoading} onHide={() => setShowLoading(false)} />
      <ModalReader
        show={showModalReader}
        handleClose={CancelPayment}
        amount={parseDolla(
          FormatDolla(
            totalPrice +
              feePrice -
              discountOrder +
              TipPrice +
              (totalPrice * tax) / 100
          )
        )}
      />
      <Modal show={show} onHide={handleClose} className="right">
        <Modal.Dialog className="bg-gray-light">
          <Modal.Header className="position-relative">
            <div className="d-flex align-items-center pt-3 gap-2">
              {/* <Form.Check
                                inline
                                name="checkBoxTable"
                                type={"checkbox"}
                                id={"1"}
                            /> */}
              <p className="text-table-pay-bill-2 mb-0 ">{table_name}</p>

              <p className="text-table-pay-bill-2 mb-0 ">
                {listOrderPay.length}/{OrderWait.length}
              </p>
            </div>
            {/* <div className="btn-close-modal">
                            <CloseSharpIcon />
                        </div> */}
          </Modal.Header>
          <Modal.Body>
            <div className="flex height-modal-content-table-pos">
              <div className="pl-5 d-flex flex-column justify-content-between ">
                <div className="d-flex  w-100 align-items-center gap-3 pr-5">
                  <Form.Check
                    inline
                    name="checkBoxTable"
                    type={"checkbox"}
                    id={"1"}
                    className="check-box-payment d-flex items-center w-fit checkbox-choose-all "
                    size={100}
                    label={LanguageUI.chooseAll}
                    checked={listOrderPay.length === OrderWait.length}
                    onClick={(e: any) => handleChangeAllOrder(e.target.checked)}
                  />
                  <div className="d-flex align-items-center gap-2">
                    <button
                      className="btn-add-order"
                      onClick={() => {
                        chooseBillHandle();
                      }}
                    >
                      +
                    </button>
                    <p className="text-split-bill mb-0">
                      + {LanguageUI.newbill}
                    </p>
                  </div>
                </div>
                <div className="bg-FEFEFE overflow-y-scroll w-460px pe-2 pb-3 border-right h-100vh-324px">
                  {OrderWait.length > 0 &&
                    OrderWait.map((item, i) => (
                      <div className="d-flex align-items-start w-100" key={i}>
                        <Form.Check
                          inline
                          name="checkBoxTable"
                          type={"checkbox"}
                          id={"1"}
                          checked={listOrderPay.some(
                            (orp) => orp == item?.order_id
                          )}
                          onClick={(e: any) =>
                            HandleChangOrder(e.target.checked, item?.order_id)
                          }
                          className="mt-20px check-box-payment"
                          size={30}
                        />
                        <Accordion
                          defaultActiveKey="0"
                          key={i}
                          className="w-100"
                        >
                          <Accordion.Item eventKey="0">
                            <Accordion.Header>
                              <div className="d-flex align-items-center justify-content-between w-100 pr-5 mt-1">
                                <div className="text-bill-pay-bill mb-0 d-flex gap-1 align-iems-center">
                                  <p className="mb-0 text-212134">
                                    {LanguageUI.bill} -
                                  </p>
                                  <p className="mb-0 text-FAA300">
                                    #{item?.order_id}
                                  </p>
                                  <p className="mb-0 text-212134">
                                    {Number(item?.custommer_id) !== 0
                                      ? `(${item?.name})`
                                      : ""}
                                  </p>
                                </div>
                                <button
                                  className="btn-add-order"
                                  onClick={() => {
                                    chooseBillHandle(item.order_id);
                                  }}
                                >
                                  +
                                </button>
                              </div>
                            </Accordion.Header>
                            <Accordion.Body>
                              <div className="d-flex flex-column gap-2">
                                <OrderListITem
                                  orderInfo={item?.listFood}
                                  status={true}
                                  order_id={item?.order_id}
                                />
                                {item?.listSide && item.listSide.length > 0 && (
                                  <div>
                                    <p className="title-pay-bill-customer mb-0">
                                      <li>Side Dish</li>
                                    </p>
                                    {item?.listSide?.map((item, index) => (
                                      <div className="ps-3">
                                        <p className="text-side-dish mb-0 text-414151f">
                                          {index + 1}. {item.name} (x
                                          {item.quantity})
                                        </p>
                                      </div>
                                    ))}
                                  </div>
                                )}
                                {item?.listFood &&
                                  item?.listFood.length > 1 &&
                                  Number(item?.tip_amount) === 0 &&
                                  Number(item?.discount) === 0 && (
                                    <div
                                      className="w-100 h-50px flex justify-content-center align-items-center border-primary rounded-5"
                                      onClick={() => {
                                        setOrderSplitBill(item);
                                        setShowModalSplitBill(true);
                                      }}
                                    >
                                      <AddIcon className="icon-split-bill" />
                                      <p className="text-split-bill mb-0">
                                        {LanguageUI.splitbill}
                                      </p>
                                    </div>
                                  )}
                                <div
                                  className="w-100 h-50px flex justify-content-center align-items-center border-primary rounded-5"
                                  onClick={() => {
                                    setOrderSplitBill(item);
                                    setShowModalVoidBill(true);
                                  }}
                                >
                                  <AddIcon className="icon-split-bill" />
                                  <p className="text-split-bill mb-0">
                                    {LanguageUI.voidBill}
                                  </p>
                                </div>
                              </div>
                            </Accordion.Body>
                          </Accordion.Item>
                        </Accordion>
                      </div>
                    ))}
                  <ModalSplitBill
                    handleClose={dismisMoalSplitBill}
                    show={showModalSplitBill}
                    orderInfo={orderSplitBill}
                    handleSuccess={handleSuccessSplitBil}
                    setShowLoading={setShowLoading}
                  />
                  <ModalVoidBill
                    handleClose={dismisMoalVoidBill}
                    show={showModalVoidBill}
                    orderInfo={orderSplitBill}
                    handleSuccess={handleSuccessVoidBil}
                    setShowLoading={setShowLoading}
                    listPrinterName={listPrinterName}
                    tableName={table_name}
                  />
                  {/* <Accordion defaultActiveKey="0">
                                        <Accordion.Item eventKey="0">
                                            <Accordion.Header> <div className="d-flex align-items-center">
                                                <Form.Check
                                                    inline
                                                    name="checkBoxTable"
                                                    type={"checkbox"}
                                                    id={"1"}
                                                />
                                                <div className="text-bill-pay-bill mb-0 d-flex gap-1 align-iems-center"><p className="mb-0 text-212134">Bill -</p><p className="mb-0 text-FAA300">#4587491</p></div>
                                            </div></Accordion.Header>
                                            <Accordion.Body>
                                                <div className="d-flex flex-column gap-2">
                                                    <OrderListITem orderInfo={dataFake} status={true} textStatus="ádasdasdasdas" />
                                                    <OrderListITem orderInfo={dataFake} status={false} textStatus="ádasdasdasdas" />
                                                </div>
                                            </Accordion.Body>
                                        </Accordion.Item>
                                    </Accordion>
                                    <Accordion defaultActiveKey="0">
                                        <Accordion.Item eventKey="0">
                                            <Accordion.Header> <div className="d-flex align-items-center">
                                                <Form.Check
                                                    inline
                                                    name="checkBoxTable"
                                                    type={"checkbox"}
                                                    id={"1"}
                                                />
                                                <div className="text-bill-pay-bill mb-0 d-flex gap-1 align-iems-center"><p className="mb-0 text-212134">Bill -</p><p className="mb-0 text-FAA300">#4587491</p></div>
                                            </div></Accordion.Header>
                                            <Accordion.Body>
                                                <div className="d-flex flex-column gap-2">
                                                    <OrderListITem orderInfo={dataFake} status={true} textStatus="ádasdasdasdas" />
                                                    <OrderListITem orderInfo={dataFake} status={false} textStatus="ádasdasdasdas" />
                                                </div>
                                            </Accordion.Body>
                                        </Accordion.Item>
                                    </Accordion> */}
                </div>

                <div className="w-460px pe-3 h-224px">
                  {/* <p className="text-table-pay-bill-2 mb-1">Discount</p>
                                    <div className="custom-typing-discount px-2 mt-2">
                                        <input
                                            type="text"
                                            className="input-voucher focus-visible-none w-100"
                                            placeholder="Discount"
                                        />
                                        <div className={`d - flex gap - 2 align - items - center`}>
                                            <div className={`custom - icon - typing - discount ${ chooseDiscountType == 1 ? "text-FF7B2C border-FF7B2C" : "" } `}>
                                                <AttachMoneySharpIcon />
                                            </div>
                                            <div className={`custom - icon - typing - discount ${ chooseDiscountType == 2 ? "text-FF7B2C border-FF7B2C" : "" } `}>
                                                <PercentSharpIcon />
                                            </div>
                                        </div>
                                    </div> */}
                  <p className="text-table-pay-bill-2 mb-1 mt-2">
                    {LanguageUI.paymentMethod}
                  </p>
                  <div className="d-flex align-items-center justify-content-between mt-2">
                    <div
                      className={`d-flex justify-content-center align-items-center w-30 ${
                        choosepaymentMethodCash == true
                          ? "custom-choose-payment-method-2"
                          : "custom-choose-payment-method"
                      } `}
                      onClick={() => {
                        setChoosePaymentMethodCash(true);
                        setStaffName("");
                        setStaffId("");
                      }}
                    >
                      <p
                        className={`custom-text-choose-categorie text-choose-tip mb-0 ${
                          choosepaymentMethodCash == true
                            ? "text-FF7B2C"
                            : "text-8E8EA9 "
                        } `}
                      >
                        {LanguageUI.payWidthCash}
                      </p>
                    </div>
                    <div
                      className={`d-flex justify-content-center align-items-center w-30 ${
                        choosepaymentMethodCash == false
                          ? "custom-choose-payment-method-2"
                          : "custom-choose-payment-method"
                      } `}
                      onClick={() => {
                        setChoosePaymentMethodCash(false);
                        setValueTip(0);
                        setTipName("");
                        setTipPrice(0);
                        setValueTipCustom("");
                        setShowModalChooseNameTip(true);
                      }}
                    >
                      <p
                        className={`custom-text-choose-categorie text-choose-tip mb-0 ${
                          choosepaymentMethodCash == false
                            ? "text-FF7B2C"
                            : "text-8E8EA9 "
                        } `}
                      >
                        {LanguageUI.payWidthCard}
                      </p>
                    </div>
                    <div
                      className={`d-flex justify-content-center align-items-center w-30 custom-choose-payment-method `}
                      onClick={() => {
                        listOrderPay.length > 0 && setShowModalRemoveBill(true);
                      }}
                    >
                      <ArrowForwardIosRoundedIcon style={{ color: "8E8EA9" }} />
                      <p
                        className={`custom-text-choose-categorie text-choose-tip mb-0  text-8E8EA9`}
                      >
                        {/* {LanguageUI.payWidthCard} */}
                        {LanguageUI.movebill}
                      </p>
                    </div>
                  </div>
                  <div
                    className={`mt - 2 ${
                      choosepaymentMethodCash == false ? "d-none" : ""
                    } `}
                  >
                    <div className="d-flex justify-content-between border-1px-gray rounded-16px mt-2">
                      <input
                        type="number"
                        className="input-voucher focus-visible-none w-100"
                        placeholder={LanguageUI.rececievedAmount}
                        value={receivedAmount}
                        onChange={(e: any) => {
                          setReceivedAmount(e.target.value);
                        }}
                      />
                      <p className="mb-0 text-other mr-6px">$</p>
                    </div>
                    <div className="d-flex justify-content-between border-1px-gray rounded-16px mt-2">
                      <input
                        type="number"
                        disabled
                        className="input-voucher focus-visible-none w-100 bg-transparent"
                        placeholder={LanguageUI.remainingAmount}
                        value={reMainingAmount}
                      />
                      <p className="mb-0 text-other mr-6px">$</p>
                    </div>
                  </div>
                </div>
              </div>
              <div className="flex flex-column justify-between">
                <div className="w-460px px-2">
                  {/* {choosepaymentMethodCash && (
                    <>
                      <div className="mt-2">
                        <p className="text-table-pay-bill-2">
                          {LanguageUI.addTip}
                        </p>
                        <div className="d-flex justify-content-between">
                          {tipList.length > 0 &&
                            tipList.map((items, i) => (
                              <div
                                key={i}
                                className={`d-flex justify-content-center align-items-center w-30  ${
                                  valueTip === Number(items.id)
                                    ? "custom-choose-tip-2"
                                    : "custom-choose-tip"
                                } `}
                                onClick={() => {
                                  setValueTip(Number(items.id));
                                  setTipName(items?.percent ?? "");
                                  setTipPrice(
                                    (totalPrice * Number(items?.percent)) / 100
                                  );
                                  setValueTipCustom("");
                                }}
                              >
                                <p
                                  className={`custom-text-choose-categorie text-choose-tip mb-0 ${
                                    valueTip == Number(items.id)
                                      ? "text-ffffff"
                                      : "text-8E8EA9 "
                                  } `}
                                >
                                  {items.percent} % ($
                                  {FormatDolla(
                                    (totalPrice * Number(items?.percent)) / 100
                                  )}
                                  )
                                </p>
                              </div>
                            ))}
                        </div>
                      </div>
                      <div className="d-flex justify-content-between border-1px-gray rounded-16px mt-2">
                        <input
                          type="number"
                          className="input-voucher focus-visible-none w-100"
                          placeholder={LanguageUI.customer}
                          value={valueTipCustom}
                          onChange={(e) => handleChangeInput(e.target.value)}
                        />
                        <p className="mb-0 text-other mr-6px">$</p>
                      </div>
                    </>
                  )} */}
                  {choosepaymentMethodCash === false && (
                    <div
                      className={`d-flex justify-content-between align-items-center border-1px-gray rounded-16px mt-2 ${
                        listStaff.length === 0 ? "d-none" : ""
                      }`}
                    >
                      <div
                        className="input-voucher focus-visible-none w-100"
                        onClick={() => setShowModalChooseNameTip(true)}
                      >{`${
                        staffName != ""
                          ? staffName
                          : LanguageUI.employeeWantToTip
                      } `}</div>
                      <img src={chevronDown} alt="" />
                    </div>
                  )}

                  {/* <p className="text-table-pay-bill-2 mb-1 mt-1">Discount</p>
                  <div className="custom-typing-discount px-2 ">
                    <input
                      type="text"
                      className="input-voucher focus-visible-none w-100"
                      placeholder="Discount"
                    />
                    <div className={`d-flex gap-2 align-items-center`}>
                      <div
                        className={`custom-icon-typing-discount ${
                          chooseDiscountType == 1
                            ? "text-FF7B2C border-FF7B2C"
                            : ""
                        } `}
                      >
                        <AttachMoneySharpIcon />
                      </div>
                      <div
                        className={`custom-icon-typing-discount ${
                          chooseDiscountType == 2
                            ? "text-FF7B2C border-FF7B2C"
                            : ""
                        } `}
                      >
                        <PercentSharpIcon />
                      </div>
                    </div>
                  </div> */}
                  <div>
                    <p className="text-table-pay-bill-2 mb-0">
                      {LanguageUI.Discount}
                    </p>
                    <div className="custom-typing-discount px-2">
                      <input
                        type="text"
                        className="input-discount-pos-menu focus-visible-none w-100"
                        placeholder={LanguageUI.Discount}
                        value={voucherCode}
                        onChange={(e) => setVoucherCode(e.target.value)}
                        onKeyPress={(event) => {
                          if (event.key === "Enter") {
                            CheckVouchePass();
                          }
                        }}
                      />
                      <div
                        className="btn-apply-discount-posmenu flex-shrink-0"
                        onClick={() => CheckVouchePass()}
                      >
                        {LanguageUI.apply}
                      </div>
                    </div>
                    {messageErrorVoucher && (
                      <div className="text-danger text-italic text-center">
                        {messageErrorVoucher}
                      </div>
                    )}
                  </div>
                </div>

                <div className="w-460px ">
                  <div className=" w-100 h-100 bill-payment-confirm px-6">
                    <div className="h-100 pb-20px">
                      <p className="text-table-pay-bill-2 text-center pt-3 mb-60px">
                        {LanguageUI.summary}
                      </p>
                      <div className="px-6">
                        <div className="d-flex justify-content-between  mb-1">
                          <p className="name-payment-bill">
                            {LanguageUI.itemsTotal}
                          </p>
                          <div className="d-flex">
                            <p className="mb-0 price-payment-bill">
                              {FormatDolla(totalPrice)}
                            </p>
                            <p className="mb-0 dollar-payment-bill">$</p>
                          </div>
                        </div>
                        <div className="d-flex justify-content-between  mb-1">
                          <p className="name-payment-bill">
                            Tip{" "}
                            {`${tipName != "" ? "(" + tipName + "%)" : ""} `}
                          </p>
                          <div className="d-flex">
                            <p className="mb-0 price-payment-bill">
                              {FormatDolla(TipPrice)}
                            </p>
                            <p className="mb-0 dollar-payment-bill">$</p>
                          </div>
                        </div>
                        <div className="d-flex justify-content-between  mb-1">
                          <p className="name-payment-bill">{LanguageUI.tax}</p>
                          <div className="d-flex">
                            <p className="mb-0 price-payment-bill">
                              {tax > 0
                                ? FormatDolla((totalPrice * tax) / 100)
                                : 0}
                            </p>
                            <p className="mb-0 dollar-payment-bill">$</p>
                          </div>
                        </div>
                        <div className="d-flex justify-content-between  mb-1">
                          <p className="name-payment-bill">
                            {LanguageUI.Discount}
                          </p>
                          <div className="d-flex">
                            <p className="mb-0 price-payment-bill">
                              {FormatDolla(discountOrder)}
                            </p>
                            <p className="mb-0 dollar-payment-bill">$</p>
                          </div>
                        </div>
                        {!choosepaymentMethodCash && feeStripe && (
                          <div className="d-flex justify-content-between  mb-1">
                            <ClickAwayListener onClickAway={handleTooltipClose}>
                              <div className="tooltip-payment-web-checkin">
                                <Tooltip
                                  className=""
                                  PopperProps={{
                                    disablePortal: true,
                                  }}
                                  onClose={handleTooltipClose}
                                  open={openToolTip}
                                  disableFocusListener
                                  disableHoverListener
                                  disableTouchListener
                                  placement="right"
                                  title="The convenience fee is charged per transaction by a third-party vendor. We do not retain any portion of this fee."
                                >
                                  <div
                                    className="d-flex gap-1 align-items-end w-fit"
                                    onClick={() => setOpenToolTip(true)}
                                  >
                                    <div className="name-payment-bill">
                                      Convenience fee
                                    </div>
                                    <HelpOutlineSharpIcon className="" />
                                  </div>
                                </Tooltip>
                              </div>
                            </ClickAwayListener>
                            <div className="d-flex gap-2px">
                              <div className="mb-0 price-payment-bill">
                                {FormatDolla(feePrice)}
                              </div>
                              <div className="mb-0 dollar-payment-bill">$</div>
                            </div>
                          </div>
                        )}
                      </div>
                      <div className="px-6">
                        <div className="notification-price-payment d-flex overflow-hidden">
                          <div className="d-flex align-items-center ps-3 w-50">
                            <div>
                              <p className="name-payment-bill mb-1">
                                {LanguageUI.Total}
                              </p>
                              <div className="d-flex align-items-center gap-2px">
                                <p className="mb-0 price-payment-confirm">
                                  {FormatDolla(
                                    totalPrice +
                                      feePrice -
                                      discountOrder +
                                      TipPrice +
                                      (totalPrice * tax) / 100
                                  )}
                                </p>
                                <p className="mb-2 name-payment-bill">$</p>
                              </div>
                            </div>
                          </div>
                          <div
                            className="w-50 bg-FE841B d-flex justify-content-center align-items-center"
                            onClick={SubmitPayBill}
                          >
                            <p className="mb-0 text-submit-payment-bill">
                              {LanguageUI.payBill}
                            </p>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div></div>
                  </div>
                </div>
              </div>
            </div>
            <div className="d-none">
              <div
                className="d-flex flex-column w-100"
                id="print-bill-table-pos-card"
              >
                <div className="border-bottom-black position-relative line-height-normal">
                  <div>
                    <p className="mb-0 id-order-customer text-black line-height-normal">
                      {nameStore}
                    </p>
                    <div className="d-flex gap-1 align-items-end">
                      <p className="mb-0 id-order-customer text-black line-height-normal">
                        Add:
                      </p>
                      <p className="mb-0 text-items-order-print text-black">
                        {addressStore}
                      </p>
                    </div>
                    {/* <div className="d-flex gap-1 align-items-end">
                      <p className="mb-0 id-order-customer  text-black line-height-normal">Phone:</p>
                      <p className="mb-0 text-items-order-print text-black">{phoneOwnerStore}</p>
                    </div> */}
                  </div>
                  <div className="d-flex align-items-center gap-2 text-black">
                    {/* {Number(table_id) > 0 && (
                      <div className="title-infor-bill-customer-print line-height-normal">
                        {NameTable}
                      </div>
                    )} */}
                    <div
                      className={` border - bottom - black h - 1 w - 100`}
                    ></div>
                    {/* <div className="d-flex align-items-center ">
                <p className="mb-0 order-customer text-black line-height-normal">Order</p>
                <p className="mb-0 id-order-customer text-black line-height-normal">#{order_id}</p>
              </div> */}
                  </div>
                  <div className={`d - flex flex - column`}>
                    {OrderWait.map(
                      (order, j) =>
                        listOrderPay.some((item) => order.order_id == item) && (
                          <div
                            className="title-pay-bill-customer "
                            key={"Order" + j}
                          >
                            Bill-{order.order_id}
                            {order.listFood.map((cart, i) => (
                              <OrderListITemPrintPosMenu
                                key={"cart" + i}
                                name={cart?.name ?? ""}
                                notefood_name={cart?.notefood_name ?? ""}
                                form_order={`${
                                  Number(cart?.form_order) != 1 ? "2" : "1"
                                } `}
                                price={Number(cart?.price)}
                                quantity={Number(cart?.quantity)}
                              />
                            ))}
                          </div>
                        )
                    )}

                    {/* <OrderListITemPrint orderInfo={listFood} status={true} /> */}
                  </div>
                </div>
                <div className="">
                  <div className={` border - bottom - black`}>
                    <div
                      className={`d - flex align - items - end justify - content - between`}
                    >
                      <p className="title-pay-bill-customer line-height-normal mb-0 text-black">
                        Sub total
                      </p>
                      <p className="price-pay-bill-customer  line-height-normal mb-0 text-black">
                        ${FormatDolla(totalPrice)}
                      </p>
                    </div>
                    {Number(TipPrice) > 0 && (
                      <div className="mb-0">
                        <p className="title-pay-bill-customer mb-0 text-black line-height-normal flex-shink-0">
                          Tip for:{" "}
                        </p>
                        <div className="d-flex gap-1 align-items-start justify-between line-height-normal">
                          <p className="title-pay-bill-customer mb-0 text-black line-height-normal">
                            {staffName}
                          </p>
                          <p className="price-pay-bill-customer mb-0 h-fit text-black line-height-normal flex-shink-0">
                            {FormatDolla(Number(TipPrice))}$
                          </p>
                        </div>
                      </div>
                    )}
                    <div className="d-flex align-items-end justify-content-between">
                      <p className="title-pay-bill-customer line-height-normal mb-0 text-black">
                        Tax
                      </p>
                      <p className="price-pay-bill-customer  line-height-normal mb-0 text-black">
                        {tax > 0 ? FormatDolla((totalPrice * tax) / 100) : 0}$
                      </p>
                    </div>
                  </div>
                  <div className="">
                    <div className="d-flex align-items-end justify-content-between">
                      <p className="title-pay-bill-customer line-height-normal align-items-end mb-0 text-black">
                        Discount
                      </p>
                      <div className="d-flex gap-2px align-items-end">
                        <p className="price-pay-bill-customer d-flex mb-0 h-fit text-black line-height-normal">
                          {discountOrder}$
                        </p>
                      </div>
                    </div>
                    <div className="d-flex align-items-end justify-content-between m-0">
                      <p className="title-pay-bill-customer line-height-normal mb-0 d-flex align-items-end text-black m-0">
                        Total
                      </p>
                      <div className="d-flex align-items-end m-0">
                        <p className="price-pay-bill-customer-total m-0 h-fit text-black line-height-normal">
                          {FormatDolla(
                            totalPrice +
                              feePrice +
                              TipPrice +
                              (totalPrice * tax) / 100 -
                              discountOrder
                          )}
                          $
                        </p>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </Modal.Body>
        </Modal.Dialog>
        <ModalPaymentError
          show={showModalPaymentErr}
          handleClose={dismisModalPaymentErr}
          content={messageErrPayment}
        />
        <ModalSelectStaffTip
          heightModal={600}
          listStaff={listStaff}
          staffId={staffId}
          setStaffId={(id: string) => setStaffId(id)}
          setStaffName={(name: string) => setStaffName(name)}
          handleClose={dismisModalChooseNameTip}
          show={listStaff.length > 0 ? showModalChooseNameTip : false}
        />
        <ModalToast
          handleClose={() => setshowModalAlertFail(false)}
          show={showModalAlertFail}
          content={messageError}
        ></ModalToast>
        <ModalRemoveBillToTable
          handleClose={dismisModalRemoveBill}
          show={showModalRemoveBill}
          listTable={listTable}
          tableID={table_id}
          handleSwapOrderTable={handleSwapOrderTable}
          messErrAPI={messageError}
        />
      </Modal>
    </>
  );
};

export default ModalPayBillTablePOS;
