const getLanguageUser = () => {
  var lg = 2;
  return lg;
};

const setLanguageUser = (languageUser: number) => {
  localStorage.setItem("languageUser", languageUser.toString());
};

const removelanguageUser = () => {
  localStorage.removeItem("languageUser");
};

export { getLanguageUser, setLanguageUser, removelanguageUser };
