import { Modal } from "react-bootstrap";
import "./Styles.css";
import { leaf1, leaf2 } from "../../../../components/ImgExport";
import OtpInput from "react18-input-otp";
import { useEffect, useState } from "react";
import * as PasscodeApi from "../../../../api/apiOwner/PasscodeApi";
import $ from "jquery";
import { getLanguageUI } from "../../../../Services/languageUI";

interface ModalOTPSubscriptionCancelProps {
  show: boolean;
  handleClose: () => void;
  UserIdStore?: number | null;
  HandleSubmit: () => void;
}

const ModalOTPSubscriptionCancel = ({
  show,
  handleClose,
  HandleSubmit,
  UserIdStore,
}: ModalOTPSubscriptionCancelProps) => {
  const languageUI = getLanguageUI().subscriptionPage;
  const [passCode, setPassCode] = useState("");
  const [messageError, setMessageError] = useState("");
  const handleChange = (enteredOtp: string) => {
    setPassCode(enteredOtp);
  };
  const setClockIn = async () => {
    if (passCode.length !== 4) {
      setMessageError(languageUI.pleaseEnterPasscode);
    } else if (UserIdStore) {
      const resStatus = await PasscodeApi.checkPasscode(UserIdStore, passCode);
      if (Number(resStatus.status) === 1) {
        HandleSubmit();
      } else {
        setMessageError(resStatus?.message ?? "");
      }
    }
  };
  useEffect(() => {
    const lengthInput = $(".otp input");

    if (lengthInput != null) {
      lengthInput.trigger("focus");
    }
    if (show) {
      setMessageError("");
      setPassCode("");
    }
  }, [show]);
  return (
    <>
      <Modal
        show={show}
        onHide={handleClose}
        className="modal-delete-customer border-modal-16px modal-clock-in-out"
      >
        <Modal.Dialog className="w-100">
          <Modal.Body className="p-0">
            <div className="position-relative px-60px d-flex align-items-center flex-column z-10">
              <div className="text-center">
                <p className="text-delete-modal-customer mb-1 text-center">
                  {languageUI.confirm}
                </p>
                <p className="text-pass-code-clock-cancel text-4a4a6a-owwner">
                  {languageUI.enterYouPass}
                </p>
                <p className="text-center text-danger text-italic mb-1">
                  {messageError}
                </p>
              </div>
              <div
                className={`otp-input ${
                  messageError != "" ? "border-red" : ""
                } mb-2 mt-2 d-flex justify-content-between w-100`}
              >
                <OtpInput
                  value={passCode}
                  onChange={handleChange}
                  numInputs={4}
                  isInputNum={true}
                  className="otp"
                />
              </div>
              <div className="d-flex justify-content-between w-100 mt-6">
                <div
                  className="btn-import-inventory-footer "
                  onClick={handleClose}
                >
                  {languageUI.close}
                </div>
                <div
                  className="btn-import-inventory-footer  text-white bg-F24343"
                  onClick={setClockIn}
                >
                  {languageUI.cancel}
                </div>
              </div>
              <img src={leaf1} alt="" className="img-leaf1-otp-owner" />
              <img src={leaf2} alt="" className="img-leaf2-otp-owner" />
            </div>
          </Modal.Body>
        </Modal.Dialog>
      </Modal>
    </>
  );
};

export default ModalOTPSubscriptionCancel;
