import * as Request from "../Request";

export interface FoodGroup {
  id: string;
  owner_id: string;
  name: string;
  parent_id: string;
  img: string;
  status: string;
  timestamp: string;
  color: string;
  list_food_group: FoodChild[];
}
export interface FoodGroupDetailEdit {
  id: string;
  owner_id: string;
  service_code: string;
  name: string;
  parent_id: string;
  role: string;
  img: string;
  status: string;
  delf: string;
  color: string;
}
export interface FoodGroupInfo {
  id: string;
  owner_id: string;
  service_code: string;
  name: string;
  role: string;
  date_begin: string;
  date_end: string;
  img: string;
  discount: string;
  color: string;
  value: string;
  label: string;
}
export interface FoodChild {
  parent_id: string;
  food_id: string;
  food_name: string;
  description: string;
  price: string;
  imageCourse: string;
  status_food: string;
  typeFood: string;
  NoteFood: NoteFood[];
  listNoteOptionFood: ListNoteOptionFood[];
}
export interface ListNoteOptionFood {
  food_id: number;
  id: number;
  option_name: string;
  price: number;
}
export interface NoteFood {
  notefood_id: number;
  notefood_name: string;
}
export interface MenuInfo {
  id: string;
  service_id: string;
  service_item_code: string;
  name: string;
  description: string;
  price: string;
  imageCourse: string;
  service_code: string;
  owner_id: string;
  name_foodgroup: string;
}

export interface ListIngredient {
  ingredient_id: string;
  ingredient_name: string;
  unit_id: string;
  unit_name: string;
  quantity: number;
}
export interface ListIngredientEdit {
  id: string;
  ingredient_id: string;
  unit_id: string;
  quantity: string;
  ingredient_name: string;
  unit_name: string;
}
export interface ListSideEdit {
  id: string;
  side_dishes_id: string;
  name: string;
}
export interface ListNoteFoodEdit {
  id: string;
  notefood_id: string;
  name: string;
}
export interface ListNoteOptionFoodEdit {
  id: string;
  food_id: string;
  option_name: string;
  price: string | number;
}
export interface InfoFoodMenuEdit {
  id: string;
  service_id: string;
  service_item_code: string;
  name: string;
  price: string;
  imageCourse: string;
  description: string;
  status: string;
  delf: string;
  nameFoodGroup: string;
  parent_id: string;
  listIngredient: ListIngredientEdit[];
  listSide: ListSideEdit[];
  listNoteFood: ListNoteFoodEdit[];
  max_side: string;
  typeFood: string;
  listNoteOptionFood: ListNoteOptionFoodEdit[];
}

export interface Result {
  status: number;
  message?: string;
  data: FoodGroup[];
}
export interface ResultMenu {
  status: number;
  message?: string;
  data: MenuInfo[];
}
export interface ResultFoodGroup {
  status: number;
  message?: string;
  data: FoodGroupInfo[];
}
export interface ResultDetailGroup {
  status: number;
  message?: string;
  data?: FoodGroupDetailEdit;
}
export interface ListSides {
  id: string;
  service_id: string;
  service_item_code: string;
  name: string;
  short_des: string;
  description: string;
  sort_order: string;
  price: string;
  parent_id: string;
  discount: string;
  imageCourse: string;
  donvitinh: string;
  role: string;
  side_id: string;
  status: string;
}
export interface ResultSuccess<T> {
  status: number;
  message?: string;
  data?: T;
}
export const gListAllFood = async (UserIdStore: number) => {
  return await Request.fetchAPI(
    "owner/gAllFoodGroup.php?owner_id=" + UserIdStore,
    "GET",
    {}
  )
    .then((res: Result) => {
      return res;
    })
    .catch((err: any) => {
      return {
        message: "An error has occurred. Please check your internet connection",
        status: 0,
        data: [],
      };
    });
};

export const gListMenuByOwner = async (UserIdStore: number) => {
  return await Request.fetchAPI(
    "owner/gListMenu.php?owner_id=" + UserIdStore,
    "GET",
    {}
  )
    .then((res: ResultMenu) => {
      return res;
    })
    .catch((err: any) => {
      return {
        message: "An error has occurred. Please check your internet connection",
        status: 0,
        data: [],
      };
    });
};
export const gListFoodGroup = async (UserIdStore: number) => {
  return await Request.fetchAPI(
    "owner/gListFoodGroup.php?owner_id=" + UserIdStore,
    "GET",
    {}
  )
    .then((res: ResultFoodGroup) => {
      return res;
    })
    .catch((err: any) => {
      return {
        message: "An error has occurred. Please check your internet connection",
        status: 0,
        data: [],
      };
    });
};

export const gInfoFoodGroupById = async (idFoodGroup: string) => {
  return await Request.fetchAPI(
    "owner/gInfoFoodGroup.php?id=" + idFoodGroup,
    "get",
    {}
  )
    .then((res: ResultDetailGroup) => {
      return res;
    })
    .catch((err: any) => {
      return {
        message: "An error has occurred. Please check your internet connection",
        status: 0,
        data: undefined,
      };
    });
};

export const updateSortFoodGroup = async (
  foodGroupPosition: { id: number; parent_id: number }[]
) => {
  return await Request.fetchAPI(`owner/resortFoodGroup.php`, "POST", {
    foodGroupPosition: JSON.stringify(foodGroupPosition),
  })
    .then((res: ResultFoodGroup) => {
      return res;
    })
    .catch((err: any) => {
      return {
        message: "An error has occurred. Please check your internet connection",
        status: 0,
      };
    });
};

export const updateSortFoodItem = async (
  foodPosition: { food_id: number; parent_id: number }[]
) => {
  return await Request.fetchAPI(`owner/resortFoodItem.php`, "POST", {
    foodPosition: JSON.stringify(foodPosition),
  })
    .then((res: ResultFoodGroup) => {
      return res;
    })
    .catch((err: any) => {
      return {
        message: "An error has occurred. Please check your internet connection",
        status: 0,
      };
    });
};

export const addFoodMenu = async (
  owner_id: number,
  service_id: number,
  name: string,
  price: number,
  parent_id: number,
  listIngredient: ListIngredient[],
  typeFood: number = 1,
  listSide: string[] = [],
  maxSide: number,
  notefood_id: string[] = [],
  image: any = "",
  side_id: string = "",
  description: string = "",
  optionNote: { optionText: string; price: number | number }[] = []
) => {
  return await Request.fetchAPI("owner/addMenu_v3.php", "post", {
    owner_id,
    name,
    price,
    parent_id,
    service_id,
    description,
    image: image,
    listIngredient: JSON.stringify(listIngredient),
    listSide: JSON.stringify(listSide),
    notefood_id: JSON.stringify(notefood_id),
    optionNote: JSON.stringify(optionNote),
    side_id,
    maxSide,
    typeFood,
  })
    .then((res: ResultFoodGroup) => {
      return res;
    })
    .catch((err: any) => {
      return {
        message: "An error has occurred. Please check your internet connection",
        status: 0,
      };
    });
};
export const addNewSideDish = async (
  owner_id: number,
  service_id: number,
  name: string,
  price: number,
  parent_id: number,
  listIngredient: ListIngredient[],
  typeFood: number = 1,
  listSide: string[] = [],
  maxSide: number,
  notefood_id: string[] = [],
  image: any = "",
  side_id: string = "",
  description: string = ""
) => {
  return await Request.fetchAPI("owner/addSideDish.php", "post", {
    owner_id,
    name,
    price,
    parent_id,
    service_id,
    description,
    image: image,
    listIngredient: JSON.stringify(listIngredient),
    listSide: JSON.stringify(listSide),
    notefood_id: JSON.stringify(notefood_id),
    side_id,
    maxSide,
    typeFood,
  })
    .then((res: ResultFoodGroup) => {
      return res;
    })
    .catch((err: any) => {
      return {
        message: "An error has occurred. Please check your internet connection",
        status: 0,
      };
    });
};
export const gInfoFoodById = async (idFood: number) => {
  return await Request.fetchAPI(`owner/gInfoFood.php`, "POST", { idFood })
    .then((res: ResultSuccess<InfoFoodMenuEdit>) => {
      return res;
    })
    .catch((err: any) => {
      return {
        message: "An error has occurred. Please check your internet connection",
        status: 0,
        data: undefined,
      };
    });
};
export const gInfoSideById = async (idFood: string) => {
  return await Request.fetchAPI(`owner/gInfoSideDish.php`, "POST", { idFood })
    .then((res: ResultSuccess<InfoFoodMenuEdit>) => {
      return res;
    })
    .catch((err: any) => {
      return {
        message: "An error has occurred. Please check your internet connection",
        status: 0,
        data: undefined,
      };
    });
};
export const editFoodMenu = async (
  owner_id: number,
  id_food: number,
  id_foodGroup: string,
  name: string,
  price: number,
  parent_id: number,
  typeFood: number,
  listIngredient: ListIngredient[],
  listSide: string[] = [],
  max_side: number,
  notefood_id: string[] = [],
  image: any = "",
  side_id: string = "",
  description: string = "",
  optionNote: { optionText: string; price: number | number }[] = []
) => {
  return await Request.fetchAPI("owner/editFood_v3.php", "post", {
    owner_id,
    id_food,
    id_foodGroup,
    name,
    price,
    parent_id,
    description,
    image: image,
    listIngredient: JSON.stringify(listIngredient),
    listSide: JSON.stringify(listSide),
    notefood_id: JSON.stringify(notefood_id),
    side_id,
    max_side,
    typeFood,
    optionNote: JSON.stringify(optionNote),
  })
    .then((res: ResultFoodGroup) => {
      return res;
    })
    .catch((err: any) => {
      return {
        message: "An error has occurred. Please check your internet connection",
        status: 0,
      };
    });
};
export const editSideMenu = async (
  owner_id: number,
  id_food: string,
  id_foodGroup: string,
  name: string,
  price: number,
  parent_id: number,
  typeFood: number,
  listIngredient: ListIngredient[],
  listSide: string[] = [],
  max_side: number,
  notefood_id: string[] = [],
  image: any = "",
  side_id: string = "",
  description: string = ""
) => {
  return await Request.fetchAPI("owner/editSideDish.php", "post", {
    owner_id,
    id_food,
    id_foodGroup,
    name,
    price,
    parent_id,
    description,
    image: image,
    listIngredient: JSON.stringify(listIngredient),
    listSide: JSON.stringify(listSide),
    notefood_id: JSON.stringify(notefood_id),
    side_id,
    max_side,
    typeFood,
  })
    .then((res: ResultFoodGroup) => {
      return res;
    })
    .catch((err: any) => {
      return {
        message: "An error has occurred. Please check your internet connection",
        status: 0,
      };
    });
};
export const addFoodToGroup = async (
  service_id: string,
  listFoodItem: string[]
) => {
  return await Request.fetchAPI(`owner/add_food_to_group.php`, "POST", {
    service_id,
    listFoodItem: JSON.stringify(listFoodItem),
  })
    .then((res: ResultSuccess<string>) => {
      return res;
    })
    .catch((err: any) => {
      return {
        message: "An error has occurred. Please check your internet connection",
        status: 0,
        data: "",
      };
    });
};
export const gListFreeSide = async (owner_id: number) => {
  return await Request.fetchAPI(`checkin/gListFreeSide.php`, "POST", {
    owner_id,
  })
    .then((res: ResultSuccess<ListSides[]>) => {
      return res;
    })
    .catch((err: any) => {
      return {
        message: "An error has occurred. Please check your internet connection",
        status: 0,
        data: [],
      };
    });
};
//lấy cấu hình
export const updateShowRankConfig = async (
  owner_id: number,
  show_rank: number
) => {
  return await Request.fetchAPI(`checkin/updateShowRank.php`, "POST", {
    owner_id,
    show_rank,
  })
    .then((res: ResultSuccess<string>) => {
      return res;
    })
    .catch((err: any) => {
      return {
        message: "An error has occurred. Please check your internet connection",
        status: 0,
        data: "",
      };
    });
};
