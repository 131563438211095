import { PropsWithChildren, useEffect, useMemo, useState } from "react";
import { Row, Button, Nav, Tab } from "react-bootstrap";

import React from "react";
// components
import MenuGroupItem from "./components/MenuGroupItem";
import MenuItem from "./components/MenuItem";

import * as userSlice from "../../store/slices/userSlice";
import { useSelector } from "react-redux";
import * as Request from "../../api/Request";
import { targetupload } from "../../api/urlImage";
import { withSwal } from "react-sweetalert2";
import ModalLoading from "../../components/ModalLoading";
import "./Styles.css";
import { DragDropContext, Droppable, Draggable } from "react-beautiful-dnd";
import {
  FoodGroup,
  ListSides,
  MenuInfo,
  gInfoFoodGroupById,
  gListAllFood,
  gListFreeSide,
  gListMenuByOwner,
  updateShowRankConfig,
  updateSortFoodGroup,
  updateSortFoodItem,
} from "../../api/apiOwner/templateMenuApi";
import ModalCreateCategory from "./components/ModalCreateCategory/ModalCreateCategory";
import ModalEditCategory from "./components/ModalCreateCategory/ModalCreateCategory";
import ModalAddNewFood from "./components/ModalAddNewFood/ModalAddNewFood";
import ModalEditFood from "./components/ModalAddNewFood/ModalEditFood";
import ModalEditSideDish from "./components/ModalAddNewFood/ModalEditSideDish";
import * as comboApi from "../../api/apiOwner/comboApi";
import default_product from "../../assets/images/default_product.png";
import SideDishGroupItem from "./components/SideDishGroupItem";
import ModalAddNewSideDish from "./components/ModalAddNewFood/ModalAddNewSideDish";
import {
  FormControlLabel,
  Icon,
  Rating,
  Switch,
  SwitchProps,
  TableContainer,
  styled,
} from "@mui/material";
import { getConfig } from "../../api/apiCheckin/paymentApi";
import { useNavigate } from "react-router-dom";
import { getLanguageUI } from "../../Services/languageUI";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";
import CloseRoundedIcon from "@mui/icons-material/CloseRounded";
import ArrowBackRoundedIcon from "@mui/icons-material/ArrowBackRounded";
import { FormatDolla } from "../../utils/format";
import html2canvas from "html2canvas";
import { bgMenuPhysical, cartEmpty } from "../../components/Image";
import {
  Active,
  DndContext,
  DragEndEvent,
  DragOverlay,
  DropAnimation,
  KeyboardSensor,
  PointerSensor,
  defaultDropAnimationSideEffects,
  useSensor,
  useSensors,
} from "@dnd-kit/core";
import {
  SortableContext,
  arrayMove,
  sortableKeyboardCoordinates,
  verticalListSortingStrategy,
} from "@dnd-kit/sortable";
import MenuGroupItem2 from "./components/MenuGroupItem2";
import { restrictToVerticalAxis } from "@dnd-kit/modifiers";
import { Nu } from "react-flags-select";
import ArrowForwardIosIcon from "@mui/icons-material/ArrowForwardIos";
import StaffReview from "./components/Staff/Component/Content/StaffReview";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import ContentTableFoodSection from "./components/ContentTableFoodSection/ContentTableFoodSection";

interface NavTabItemProps {
  eventKey: string;
  navTitle: string;
}
interface FoodMenu {
  nameFood: string;
  decription: string;
  price: number;
  type: number;
}

const NavTabItem = ({ eventKey, navTitle }: NavTabItemProps) => {
  return (
    <>
      <Nav.Item as="li">
        <Nav.Link className="cursor-pointer ms-0" eventKey={eventKey}>
          {navTitle}
        </Nav.Link>
      </Nav.Item>
    </>
  );
};

const dropAnimationConfig: DropAnimation = {
  sideEffects: defaultDropAnimationSideEffects({
    styles: {
      active: {
        opacity: "0.4",
      },
    },
  }),
};

interface Props {}

interface Column {
  id: string;
  label: string;
  minWidth?: number;
  align?: "left" | "right" | "center" | "inherit" | "justify" | undefined;
  format?: (value: number) => string;
}

export function SortableOverlay({ children }: PropsWithChildren<Props>) {
  return (
    <DragOverlay dropAnimation={dropAnimationConfig}>{children}</DragOverlay>
  );
}

const TemplateMenu2 = withSwal((props: any) => {
  const navigate = useNavigate();
  const languageUI = getLanguageUI().menuTemplate;
  const [showrate, setShowRate] = useState(false);

  const [ChoooseFoodGroupAdd, setChoooseFoodGroupAdd] = useState<number>(0);
  const [FoodGroupNameAdd, setFoodGroupNameAdd] = useState<any>("");

  const [ListFood, setListFood] = useState<MenuInfo[]>([]);
  const [ListFreeSides, setListFreeSides] = useState<ListSides[]>([]);
  const [ListFoodGroup, setListFoodGroup] = useState<FoodGroup[]>([]);
  const [Message, setMessage] = useState<any>("");
  const [ShowLoading, setShowLoading] = useState<boolean>(false);

  const [activeTab, setActiveTab] = useState("foodGroup");
  const [activeTab2, setActiveTab2] = useState("1");
  const [activeTab3, setActiveTab3] = useState("1");
  const [nameFoodGroup, setNameFoodGroup] = useState<string>("");
  const [idFoodGroup, setIdFoodGroup] = useState<number>(0);

  const [idFoodEdit, setIdFoodEdit] = useState<number>();
  const [idSideEdit, setIdSideEdit] = useState<string>("");
  const [menuFoodInGroup, setMenuFoodInGroup] = useState<any>("");

  const [FoodNumericalOrderMax, setFoodNumericalOrderMax] = useState<number>(0);
  const [showRank, setShowRank] = useState<number>(0);

  const [FoodGroupNumerical, setFoodGroupNumerical] = useState<number>(1);
  const [FoodGroupNumericalMax, setFoodGroupNumericalMax] = useState<any>("");
  const [FoodGroupNumericalEdit, setFoodGroupNumericalEdit] = useState<any>("");
  const [FoodGroupColorEdit, setFoodGroupColorEdit] = useState<string>("");
  const [showModalCreateCategory, setShowModalCreateCategory] = useState(false);
  const [showModalEditCategory, setShowModalEditCategory] = useState(false);
  const [showModalAddNewFood, setShowModalAddNewFood] = useState(false);
  const [showModalAddNewSideDish, setShowModalAddNewSideDish] = useState(false);
  const nameStore = useSelector(userSlice.selectorNameStore);
  const [showModalEditFood, setShowModalEditFood] = useState(false);
  const [showModalEditSideDish, setShowModalEditSideDish] = useState(false);
  const [comboIngredient, setComboIngredient] = useState<
    comboApi.ComboIngredient[]
  >([]);
  const [comboSideDish, setComboSideDish] = useState<comboApi.ComboSideDish[]>(
    []
  );
  const [comboUnit, setComboUnit] = useState<comboApi.ComboUnit[]>([]);
  const [dataListFoodRank, setDataListFoodRank] = useState<
    comboApi.IListFoodRank[]
  >([]);
  const [dataListFeedbackFoodByOwner, setDataListFeedbackFoodByOwner] =
    useState<comboApi.IListFeedbackFoodByOwner[]>([]);
  const [dataAllFoodFeedbackByOwner, setDataAllFoodFeedbackByOwner] = useState<
    comboApi.IAllFoodFeedbackByOwner[]
  >([]);
  const [dataAllFeedbackByFood, setDataAllFeedbackByFood] = useState<
    comboApi.IAllFeedbackByFood[]
  >([]);
  const UserIdStore = useSelector(userSlice.selectorUserId);

  const [arrayFoodMenu, setArrayFoodMenu] = useState<FoodMenu[]>([]);
  const [showFoodSection, setShowFoodSection] = useState<boolean>(false);

  const dismisModalCreateCategory = () => {
    setShowModalCreateCategory(false);
  };

  const dismisModalEditCategory = () => {
    setShowModalEditCategory(false);
  };

  const dismisModalAddNewFood = () => {
    setShowModalAddNewFood(false);
  };
  const dismisModalAddNewSideDish = () => {
    setShowModalAddNewSideDish(false);
  };
  const dismisModalEditFood = () => {
    setShowModalEditFood(false);
  };
  const dismisModalEditSide = () => {
    setShowModalEditSideDish(false);
  };

  // const foodGroupIdEditRef = useRef<any>([]);
  const { swal } = props;
  const handleDeleteFood = (idFood: number, idgroup: number) => {
    swal
      .fire({
        title: languageUI.doYouWantDeleteFood,
        text: "",
        icon: "question",
        showCancelButton: true,
        confirmButtonColor: "#28bb4b",
        cancelButtonColor: "#f34e4e",
        confirmButtonText: languageUI.yes,
        cancelButtonText: languageUI.no,
      })
      .then(function (result: { value: any; dismiss: any }) {
        if (result.value) {
          delFood(idFood, idgroup);
        } else if (
          // Read more about handling dismissals
          result.dismiss === swal.DismissReason.cancel
        ) {
          swal.fire({
            title: languageUI.cancelled,
            text: languageUI.yourFoodIsSafe,
            icon: "error",
            confirmButtonColor: "#4a4fea",
          });
        }
      });
  };

  const handleDeleteSideDish = (idFood: any) => {
    swal
      .fire({
        title: languageUI.doYouWantDeleteFood,
        text: "",
        icon: "question",
        showCancelButton: true,
        confirmButtonColor: "#28bb4b",
        cancelButtonColor: "#f34e4e",
        confirmButtonText: languageUI.yes,
        cancelButtonText: languageUI.no,
      })
      .then(function (result: { value: any; dismiss: any }) {
        if (result.value) {
          delSideDish(idFood);
        } else if (
          // Read more about handling dismissals
          result.dismiss === swal.DismissReason.cancel
        ) {
          swal.fire({
            title: languageUI.cancelled,
            text: languageUI.yourFoodIsSafe,
            icon: "error",
            confirmButtonColor: "#4a4fea",
          });
        }
      });
  };

  const handleDeleteFoodGroup = (idFoodGroup: any) => {
    swal
      .fire({
        title: languageUI.doYouWantDelete,
        text: "",
        icon: "question",
        showCancelButton: true,
        confirmButtonColor: "#28bb4b",
        cancelButtonColor: "#f34e4e",
        confirmButtonText: languageUI.yes,
        cancelButtonText: languageUI.no,
      })
      .then(function (result: { value: any; dismiss: any }) {
        if (result.value) {
          delFoodGroup(idFoodGroup);
        } else if (
          // Read more about handling dismissals
          result.dismiss === swal.DismissReason.cancel
        ) {
          swal.fire({
            title: "Cancelled",
            text: "Your food is safe :)",
            icon: "error",
            confirmButtonColor: "#4a4fea",
          });
        }
      });
  };

  const handleEditFoodGroup = async (idFoodGroup: string) => {
    const reqInfo = await gInfoFoodGroupById(idFoodGroup);
    if (Number(reqInfo?.status) === 1) {
      setNameFoodGroup(reqInfo?.data?.name ?? "");
      setIdFoodGroup(Number(idFoodGroup) ?? 0);
      setFoodGroupNumericalEdit(Number(reqInfo?.data?.parent_id) ?? "");
      setFoodGroupColorEdit(reqInfo?.data?.color ?? "");
      setMessage("");
      setShowModalEditCategory(true);
      setShowLoading(false);
    } else {
      setMessage(
        reqInfo?.message
          ? reqInfo?.message
          : "Something went wrong. Please try again later!"
      );
      setShowLoading(false);
    }
  };

  const handleEditFood = async (idFood: number) => {
    setShowModalEditFood(true);
    setIdFoodEdit(idFood);
  };

  const delFood = async (idFood: number, id_group: number) => {
    if (UserIdStore) {
      setShowLoading(true);
      await Request.fetchAPI("owner/deleteMenu_v3.php", "post", {
        id_owner: UserIdStore,
        id_menu: idFood,
        id_group,
      })
        .then((result: any) => {
          if (Number(result?.status) === 1) {
            setMessage("");
            swal.fire({
              title: languageUI.deleted,
              text: languageUI.yourFoodDeleted,
              icon: "success",
              confirmButtonColor: "#4a4fea",
            });
            setShowLoading(false);
            GetListFoodGroup();
            GetListFood();
            // console.log("Xóa food thành công");
          } else {
            setMessage(
              result?.message
                ? result?.message
                : "Something went wrong. Please try again later!"
            );
          }
        })
        .catch((err: any) => {
          setMessage(
            "An error has occurred. Please check your internet connection"
          );
        });
      setShowLoading(false);
    } else {
      console.log("Chưa đăng nhập");
      navigate("/auth/login");
    }
  };

  const delSideDish = async (idFood: number) => {
    if (UserIdStore) {
      setShowLoading(true);
      await Request.fetchAPI("owner/deleteSideDish.php", "post", {
        id_owner: UserIdStore,
        id_menu: idFood,
      })
        .then((result: any) => {
          if (Number(result?.status) === 1) {
            setMessage("");
            swal.fire({
              title: languageUI.deleted,
              text: languageUI.yourFoodDeleted,
              icon: "success",
              confirmButtonColor: "#4a4fea",
            });
            setShowLoading(false);
            GetListFreeSide();
            // console.log("Xóa food thành công");
          } else {
            setMessage(
              result?.message
                ? result?.message
                : "Something went wrong. Please try again later!"
            );
          }
        })
        .catch((err: any) => {
          setMessage(
            "An error has occurred. Please check your internet connection"
          );
        });
      setShowLoading(false);
    } else {
      console.log("Chưa đăng nhập");
      navigate("/auth/login");
    }
  };

  const delFoodGroup = async (idFoodGroup: number) => {
    if (UserIdStore) {
      setShowLoading(true);
      await Request.fetchAPI("owner/deleteFoodGroup.php", "post", {
        id_owner: UserIdStore,
        id: idFoodGroup,
      })
        .then((result: any) => {
          if (Number(result?.status) === 1) {
            setMessage("");
            setShowLoading(false);
            swal.fire({
              title: languageUI.deleted,
              text: languageUI.yourGroupFoodDeleted,
              icon: "success",
              confirmButtonColor: "#4a4fea",
            });
            GetListFoodGroup();
          } else {
            swal.fire({
              title: languageUI.deleted,
              text: result?.message
                ? result?.message
                : "Something went wrong. Please try again later!",
              icon: "error",
              confirmButtonColor: "#4a4fea",
            });
          }
        })
        .catch((err: any) => {
          setMessage(
            "An error has occurred. Please check your internet connection"
          );
        });
      setShowLoading(false);
    } else {
      navigate("/auth/login");
    }
  };

  const GetListFood = async () => {
    if (UserIdStore) {
      setShowLoading(true);
      const reqMenu = await gListMenuByOwner(UserIdStore);
      if (Number(reqMenu?.status) === 1) {
        setListFood(reqMenu?.data);
      } else if (Number(reqMenu?.status) === 2) {
        setListFood([]);
      } else {
        setListFood([]);
      }

      setShowLoading(false);
    } else {
      navigate("/auth/login");
    }
  };
  //lấy list side
  const GetListFreeSide = async () => {
    if (UserIdStore) {
      setShowLoading(true);
      const reqMenu = await gListFreeSide(UserIdStore);
      if (Number(reqMenu?.status) === 1) {
        setListFreeSides(reqMenu?.data ?? []);
      } else {
        setListFreeSides([]);
      }

      setShowLoading(false);
    } else {
      navigate("/auth/login");
    }
  };

  const GetListFoodGroup = async () => {
    if (UserIdStore) {
      setShowLoading(true);
      const reqFood = await gListAllFood(UserIdStore);

      if (Number(reqFood?.status) === 1) {
        setListFoodGroup(reqFood?.data);
        setFoodGroupNumericalMax(Number(reqFood?.data.length) + 1);
        setFoodGroupNumerical(Number(reqFood?.data.length) + 1);

        const data1: FoodMenu[] = [];
        reqFood?.data.map((item) => {
          if (item.list_food_group.length > 0) {
            data1.push({
              decription: "",
              nameFood: item.name,
              price: 0,
              type: 1,
            });
            item.list_food_group.map((item2) => {
              data1.push({
                decription: item2.description,
                nameFood: item2.food_name,
                price: Number(item2.price),
                type: 2,
              });
            });
          }
        });
        setArrayFoodMenu(data1);
      } else if (Number(reqFood?.status) === 2) {
        setListFoodGroup([]);
        setFoodGroupNumericalMax(1);
        setFoodGroupNumerical(1);
        setArrayFoodMenu([]);
      } else {
        setListFoodGroup([]);
        setArrayFoodMenu([]);
      }
      setShowLoading(false);
    } else {
      navigate("/auth/login");
    }
  };

  // const GetFoodGroup = async () => {
  //   if (UserIdStore) {
  //     setShowLoading(true);
  //     const reqGroup = await gListFoodGroup(UserIdStore);
  //     if (reqGroup?.status == 1) {
  //     } else if (reqGroup?.status == 2) {
  //     } else {
  //     }
  //     setShowLoading(false);
  //   } else {
  //     console.log("Chưa đăng nhập");
  //     navigate("/auth/login");
  //   }
  // };

  const saveNewFoodGroup = async (color: string) => {
    setShowModalCreateCategory(false);
    if (FoodGroupNumerical < 1) {
      setFoodGroupNumerical(FoodGroupNumericalMax);
      return;
    }

    if (FoodGroupNumericalMax < FoodGroupNumerical) {
      setFoodGroupNumerical(FoodGroupNumericalMax);
      return;
    }
    if (FoodGroupNameAdd !== "" || FoodGroupNameAdd.trim() !== "") {
      if (UserIdStore) {
        setShowLoading(true);
        await Request.fetchAPI("owner/addFoodGroup.php", "post", {
          id_owner: UserIdStore,
          name: FoodGroupNameAdd,
          parent_id: FoodGroupNumerical,
          color,
        })
          .then((result: any) => {
            if (Number(result?.status) === 1) {
              setShowLoading(false);
              GetListFoodGroup();
            } else {
            }
          })
          .catch((err: any) => {});
        setShowLoading(false);
      } else {
        navigate("/auth/login");
      }
    }
  };

  const saveEditFoodGroup = async (color: string) => {
    if (FoodGroupNumericalEdit < 1) {
      setFoodGroupNumericalEdit(FoodGroupNumericalMax - 1);
      return;
    }

    if (Number(FoodGroupNumericalMax - 1) < FoodGroupNumericalEdit) {
      setFoodGroupNumericalEdit(FoodGroupNumericalMax - 1);
      return;
    }

    if (nameFoodGroup.trim() !== "") {
      if (UserIdStore) {
        setShowLoading(true);
        await Request.fetchAPI("owner/editFoodGroup.php", "post", {
          name: nameFoodGroup,
          id_foodGroup: idFoodGroup,
          parent_id: FoodGroupNumericalEdit,
          color,
        })
          .then((result: any) => {
            if (Number(result?.status) === 1) {
              // swal.fire({
              //   position: "center",
              //   icon: "success",
              //   title: "Update successful!",
              //   showConfirmButton: false,
              //   timer: 1500,
              // });
              setShowLoading(false);
              GetListFoodGroup();
              GetListFood();
              dismisModalEditCategory();
              setNameFoodGroup("");
              setIdFoodGroup(0);
            } else {
            }
          })
          .catch((err: any) => {});
        setShowLoading(false);
      } else {
        navigate("/auth/login");
      }
    } else {
    }
  };
  const HandleEditFoodSuccess = () => {
    swal.fire({
      position: "center",
      icon: "success",
      title: languageUI.updateSuccessful,
      showConfirmButton: false,
      timer: 1500,
    });
    GetListFoodGroup();
  };

  const HandleEditSideDishSuccess = () => {
    swal.fire({
      position: "center",
      icon: "success",
      title: languageUI.updateSuccessful,
      showConfirmButton: false,
      timer: 1500,
    });
    GetListFreeSide();
  };

  const saveActiveFoodGroup = async (id: any) => {
    if (UserIdStore) {
      setShowLoading(true);
      await Request.fetchAPI("owner/activeFoodGroup.php", "post", {
        id_foodGroup: id,
      })
        .then((result: any) => {
          if (Number(result?.status) === 1) {
            setShowLoading(false);
            GetListFoodGroup();
          } else {
          }
        })
        .catch((err: any) => {});
      setShowLoading(false);
    } else {
      console.log("Chưa đăng nhập");
      navigate("/auth/login");
    }
  };

  const saveActiveFood = async (id: number) => {
    if (UserIdStore) {
      setShowLoading(true);
      await Request.fetchAPI("owner/activeFood.php", "post", {
        id_food: id,
      })
        .then((result: any) => {
          if (Number(result?.status) === 1) {
            setShowLoading(false);
            GetListFoodGroup();
          } else {
          }
        })
        .catch((err: any) => {});
      setShowLoading(false);
    } else {
      console.log("Chưa đăng nhập");
      navigate("/auth/login");
    }
  };

  const handleEditSide = async (idFood: string) => {
    setShowModalEditSideDish(true);
    setIdSideEdit(idFood);
  };

  const ReloadForm = () => {
    setFoodGroupNameAdd("");
    setListFood([]);
    setListFoodGroup([]);
    // GetFoodGroup();
    setMessage("");
    GetListFoodGroup();
    GetListFood();
    GetListFreeSide();
    setShowLoading(false);
  };

  const handleShowRate = (food_id: number) => {
    setShowRate(true);
    if (UserIdStore) {
      gAllFeedbackByFood(UserIdStore, food_id);
    }
  };
  // const handleDragAndDropItem = async (results: any, index: number) => {
  //   const { source, destination, type } = results;

  //   if (!destination) return;

  //   if (
  //     source.droppableId === destination.droppableId &&
  //     source.index === destination.index
  //   )
  //     return;

  //   if (type === "items") {
  //     const reorderedStores2 = [...ListFoodGroup[index].list_food_group];

  //     const storeSourceIndex = source.index;
  //     const storeDestinatonIndex = destination.index;
  //     if (
  //       storeSourceIndex !== null &&
  //       storeDestinatonIndex !== null &&
  //       storeSourceIndex !== storeDestinatonIndex
  //     ) {
  //       var sort1 = reorderedStores2[storeSourceIndex].parent_id;
  //       var sort2 = reorderedStores2[storeDestinatonIndex].parent_id;
  //       reorderedStores2[storeSourceIndex].parent_id = sort2;
  //       reorderedStores2[storeDestinatonIndex].parent_id = sort1;

  //       const listTableUpdate = [
  //         {
  //           food_id: reorderedStores2[storeSourceIndex]?.food_id,
  //           parent_id: reorderedStores2[storeSourceIndex]?.parent_id,
  //         },
  //         {
  //           food_id: reorderedStores2[storeDestinatonIndex]?.food_id,
  //           parent_id: reorderedStores2[storeDestinatonIndex]?.parent_id,
  //         },
  //       ];
  //       setShowLoading(true);
  //       const reqSwap = await updateSortFoodItem(listTableUpdate);
  //       if (Number(reqSwap?.status) === 1) {
  //         setShowLoading(false);
  //       } else {
  //         setShowLoading(false);
  //       }

  //       reorderedStores2[storeSourceIndex] = reorderedStores2.splice(
  //         storeDestinatonIndex,
  //         1,
  //         reorderedStores2[storeSourceIndex]
  //       )[0];
  //       const NewArr = ListFoodGroup.map((item, i) =>
  //         i === index ? { ...item, list_food_group: reorderedStores2 } : item
  //       );
  //       setListFoodGroup([...NewArr]);
  //     }
  //     return;
  //   }
  // };

  const handleDragAndDrop = async (results: DragEndEvent) => {
    console.log("vao 111", results);
    const { active, over } = results;

    if (over && active.id !== over.id) {
      setListFoodGroup((items) => {
        const oldIndex = items.findIndex((item) => item.id === active.id);
        const newIndex = items.findIndex((item) => item.id === over.id);

        return arrayMove(items, oldIndex, newIndex);
      });
      // var parent_id_old = 0;
      // var parent_id_new = 0;

      // ListFoodGroup.map((item) => {
      //   if (item.id === over.id) {
      //     return (parent_id_new = Number(item.parent_id));
      //   }
      //   if (item.id === active.id) {
      //     return (parent_id_old = Number(item.parent_id));
      //   }
      // });

      const parent_id_old = ListFoodGroup.find((item) => item.id === active.id);
      const parent_id_new = ListFoodGroup.find((item) => item.id === over.id);
      const arrUpdate: { id: number; parent_id: number }[] = [
        {
          id: Number(active.id),
          parent_id: Number(parent_id_new?.parent_id) ?? 0,
        },
        {
          id: Number(over.id),
          parent_id: Number(parent_id_old?.parent_id) ?? 0,
        },
      ];
      const reqSwap = await updateSortFoodGroup(arrUpdate);
      if (Number(reqSwap?.status) === 1) {
        setShowLoading(false);
        GetListFoodGroup();
      } else {
        setShowLoading(false);
      }
    }
    console.log(ListFoodGroup);

    // const { source, destination, type } = results;

    // if (!destination) return;

    // if (
    //   source.droppableId === destination.droppableId &&
    //   source.index === destination.index
    // )
    //   return;

    // if (type === "group") {
    //   const reorderedStores = [...ListFoodGroup];

    //   const storeSourceIndex = source.index;
    //   const storeDestinatonIndex = destination.index;
    //   if (
    //     storeSourceIndex !== null &&
    //     storeDestinatonIndex !== null &&
    //     storeSourceIndex !== storeDestinatonIndex
    //   ) {
    //     var sort1 = reorderedStores[storeSourceIndex].parent_id;
    //     var sort2 = reorderedStores[storeDestinatonIndex].parent_id;
    //     reorderedStores[storeSourceIndex].parent_id = sort2;
    //     reorderedStores[storeDestinatonIndex].parent_id = sort1;

    //     reorderedStores[storeSourceIndex] = reorderedStores.splice(
    //       storeDestinatonIndex,
    //       1,
    //       reorderedStores[storeSourceIndex]
    //     )[0];
    //     const listTableUpdate = [
    //       {
    //         id: reorderedStores[storeSourceIndex]?.id,
    //         parent_id: reorderedStores[storeSourceIndex]?.parent_id,
    //       },
    //       {
    //         id: reorderedStores[storeDestinatonIndex]?.id,
    //         parent_id: reorderedStores[storeDestinatonIndex]?.parent_id,
    //       },
    //     ];
    //     setShowLoading(true);
    //     const reqSwap = await updateSortFoodGroup(listTableUpdate);
    //     if (Number(reqSwap?.status) === 1) {
    //       setShowLoading(false);
    //     } else {
    //       setShowLoading(false);
    //     }
    //     setListFoodGroup([...reorderedStores]);
    //   }
    //   return;
    // }
  };

  const gComboIngredient = async () => {
    if (UserIdStore) {
      const req = await comboApi.gComboIngredientbyOwner(UserIdStore);
      if (Number(req?.status) === 1) {
        setComboIngredient(req?.data ?? []);
      }
    }
  };
  const gComboSideDish = async () => {
    if (UserIdStore) {
      const req = await comboApi.gComboSideDishbyOwner(UserIdStore);
      if (Number(req?.status) === 1) {
        setComboSideDish(req?.data ?? []);
      }
    }
  };
  const gComboUnit = async () => {
    if (UserIdStore) {
      const req = await comboApi.gComboUnitbyOwner();
      if (Number(req?.status) === 1) {
        setComboUnit(req?.data ?? []);
      }
    }
  };
  const gListFoodRank = async (owner_id: number, type_food: number) => {
    const result = await comboApi.gListFoodRank(owner_id, type_food);
    if (result.data) {
      setDataListFoodRank(result.data);
    }
  };
  const gListFeedbackFoodByOwner = async (owner_id: number, type: number) => {
    const result = await comboApi.gListFeedbackFoodByOwner(owner_id, type);
    if (result.data) {
      setDataListFeedbackFoodByOwner(result.data);
      console.log(result.data);
    }
  };
  const gAllFoodFeedbackByOwner = async (owner_id: number) => {
    const result = await comboApi.gAllFoodFeedbackByOwner(owner_id);
    if (result.data) {
      setDataAllFoodFeedbackByOwner(result.data);
    }
  };

  const gAllFeedbackByFood = async (owner_id: number, food_id: number) => {
    const result = await comboApi.gAllFeedbackByFood(owner_id, food_id);
    if (result.data) {
      setDataAllFeedbackByFood(result.data);
    }
  };
  const HandleAddFoodSuccess = () => {
    GetListFoodGroup();
    setMenuFoodInGroup(ChoooseFoodGroupAdd);
  };
  const HandleAddSideSuccess = () => {
    GetListFreeSide();
  };
  useEffect(() => {
    setFoodGroupNameAdd("");
  }, [showModalCreateCategory]);
  const IOSSwitch = styled((props: SwitchProps) => (
    <Switch
      focusVisibleClassName=".Mui-focusVisible"
      disableRipple
      {...props}
    />
  ))(({ theme }) => ({
    width: 42,
    height: 26,
    padding: 0,
    "& .MuiSwitch-switchBase": {
      padding: 0,
      margin: 2,
      transitionDuration: "300ms",
      "&.Mui-checked": {
        transform: "translateX(16px)",
        color: "#fff",
        "& + .MuiSwitch-track": {
          backgroundColor:
            theme.palette.mode === "dark" ? "#2ECA45" : "#65C466",
          opacity: 1,
          border: 0,
        },
        "&.Mui-disabled + .MuiSwitch-track": {
          opacity: 0.5,
        },
      },
      "&.Mui-focusVisible .MuiSwitch-thumb": {
        color: "#33cf4d",
        border: "6px solid #fff",
      },
      "&.Mui-disabled .MuiSwitch-thumb": {
        color:
          theme.palette.mode === "light"
            ? theme.palette.grey[100]
            : theme.palette.grey[600],
      },
      "&.Mui-disabled + .MuiSwitch-track": {
        opacity: theme.palette.mode === "light" ? 0.7 : 0.3,
      },
    },
    "& .MuiSwitch-thumb": {
      boxSizing: "border-box",
      width: 22,
      height: 22,
    },
    "& .MuiSwitch-track": {
      borderRadius: 26 / 2,
      backgroundColor: theme.palette.mode === "light" ? "#E9E9EA" : "#39393D",
      opacity: 1,
      transition: theme.transitions.create(["background-color"], {
        duration: 500,
      }),
    },
  }));

  const gConfig = async () => {
    if (UserIdStore) {
      const config = await getConfig(UserIdStore);
      if (Number(config.status) === 1) {
        setShowRank(Number(config?.data?.show_rank) ?? 0);
      }
    }
  };

  const ChangShowRank = async (checked: boolean) => {
    const show_rank = checked ? 1 : 0;
    setShowRank(show_rank);
    if (UserIdStore) {
      await updateShowRankConfig(UserIdStore, show_rank);
    }
  };

  function downloadURI(uri: string, name: string) {
    var link = document.createElement("a");

    link.download = name;
    link.href = uri;
    document.body.appendChild(link);
    link.click();
    // clearDynamicLink(link);
  }
  function DownloadAsImage(element: any, name: string) {
    html2canvas(element).then(function (canvas) {
      var myImage = canvas.toDataURL();
      downloadURI(myImage, name);
    });
  }
  const downOne = () => {
    const el: any = document.querySelector(".print-menu-physical");
    DownloadAsImage(el, "menu.png");
  };

  function convertAndDownloadAll() {
    const contents = document.querySelectorAll(".print-menu-physical");
    console.log("Đang tải....");

    const contentArray = Array.from(contents);

    function convertAndDownloadNext() {
      if (contentArray.length > 0) {
        const content: any = contentArray.shift();

        const filename = "menu" + ".png";

        html2canvas(content).then(function (canvas) {
          const imgURL = canvas.toDataURL("image/png");
          const downloadLink = document.createElement("a");
          downloadLink.href = imgURL;
          downloadLink.download = filename;

          // Simulate a click event to trigger the download
          downloadLink.click();

          // Chuyển đổi và tải về tiếp theo
          convertAndDownloadNext();
        });
      }
    }

    // Bắt đầu chuyển đổi và tải về từng phần tử một
    convertAndDownloadNext();
  }

  useEffect(() => {
    ReloadForm();
    gConfig();
    gComboIngredient();
    gComboSideDish();
    gComboUnit();
    // gListFoodRank()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (UserIdStore) {
      gListFoodRank(UserIdStore, Number(activeTab2));
    }
  }, [activeTab2]);

  useEffect(() => {
    setShowRate(false);
    if (UserIdStore) {
      if (activeTab3 !== "4") {
        gListFeedbackFoodByOwner(UserIdStore, Number(activeTab3));
      } else {
        gAllFoodFeedbackByOwner(UserIdStore);
      }
    }
  }, [activeTab3]);

  // useEffect(() => {
  //   if (UserIdStore) {
  //     gAllFeedbackByFood(UserIdStore, Number(activeTab3))

  //   }
  // }, [showrate])

  const sensors = useSensors(
    useSensor(PointerSensor),
    useSensor(KeyboardSensor, {
      coordinateGetter: sortableKeyboardCoordinates,
    })
  );
  const [active, setActive] = useState<Active | null>(null);
  const activeItem = useMemo(
    () => ListFoodGroup.find((item) => item.id === active?.id),
    [active, ListFoodGroup]
  );
  const maxLenghtMenu = 15;

  const data: JSX.Element[] = [];
  const MenuPrint = () => {
    // console.log(parseInt(arrayFoodMenu.length / 20));
    // console.log("222", arrayFoodMenu.slice(0, 10));
    // console.log("333", arrayFoodMenu.slice(10, 10));

    for (
      let index = 0;
      index <= Math.floor(arrayFoodMenu.length / maxLenghtMenu);
      index++
    ) {
      data.push(
        <div className="box-gift-card-printer">
          <div
            className="position-relative print-menu-physical"
            style={{ width: "595px", height: "842px" }}
          >
            <img src={bgMenuPhysical} alt="" className="w-100 h-100" />
            <div className="position-absolute w-100 h-100 top-0 p-6">
              <p className="name-store-physical-menu mt-2">{nameStore}</p>
              <div
                className={`px-6 box-food-menu-physical ${
                  arrayFoodMenu.slice(
                    index * maxLenghtMenu,
                    (index + 1) * maxLenghtMenu
                  ).length >
                  maxLenghtMenu / 2
                    ? "justify-content-between"
                    : ""
                }`}
              >
                {arrayFoodMenu
                  .slice(index * maxLenghtMenu, (index + 1) * maxLenghtMenu)
                  .map((item, index1) => (
                    <div key={index1}>
                      {item.type === 1 ? (
                        <div className={`group-physical-menu text-truncate`}>
                          {item.nameFood}
                        </div>
                      ) : (
                        <div className="px-6 mb-1">
                          <div className="d-flex">
                            <div className="w-100 mt-1">
                              <div className="d-flex w-100 justify-content-between align-items-end">
                                <div className="flex-shrink-0 w-fit flex-column max-w-80">
                                  <p className="name-food-physical text-truncate">
                                    {item.nameFood}
                                  </p>
                                  {item.decription && (
                                    <p className="m-0 decreption-menu text-two-line-name">
                                      {item.decription}
                                    </p>
                                  )}
                                </div>
                                <div className="doc-physical-menu mx-1">
                                  .............................................................................................................................
                                </div>
                                <p className="m-0  text-orange-i price-food-name-menu-physical ">
                                  {FormatDolla(Number(item.price))}$
                                </p>
                              </div>
                            </div>
                          </div>
                        </div>
                      )}
                    </div>
                  ))}
              </div>
              <p className="thanks-physical-menu">Thank you!</p>
            </div>
          </div>
        </div>
      );
    }
    return data;
  };

  const columns: Column[] = [
    {
      id: "sst",
      label: "STT",
      // minWidth: 100
    },
    {
      id: "name",
      label: "Name",
      // minWidth: 100
    },
    {
      id: "beverage",
      label: "Beverage",
      // minWidth: 100,
      align: "center",
      format: (value: number) => value.toLocaleString("en-US"),
    },
    {
      id: "appertizer",
      label: "Appertizer",
      // minWidth: 100,
      align: "center",
      format: (value: number) => value.toLocaleString("en-US"),
    },
    {
      id: "entree",
      label: "Entree",
      // minWidth: 100,
      align: "center",
      format: (value: number) => value.toFixed(2),
    },
    {
      id: "Soup&Stews",
      label: "Soup&Stews",
      // minWidth: 100,
      align: "center",
      format: (value: number) => value.toFixed(2),
    },
    {
      id: "Desserts",
      label: "Desserts",
      // minWidth: 100,
      align: "center",
      format: (value: number) => value.toFixed(2),
    },
    {
      id: "Salad",
      label: "Salad",
      // minWidth: 100,
      align: "center",
      format: (value: number) => value.toFixed(2),
    },
    {
      id: "Grill",
      label: "Grill",
      // minWidth: 100,
      align: "center",
      format: (value: number) => value.toFixed(2),
    },
  ];

  interface Data {
    name: string;
    code: string;
    population: number;
    size: number;
    density: number;
  }

  function createData(
    stt: string,
    name: string,
    code: string,
    population: number,
    size: number
  ): Data {
    const density = population / size;
    return { name, code, population, size, density };
  }

  useEffect(() => {
    MenuPrint();
  }, [arrayFoodMenu]);

  return (
    <>
      <ModalLoading show={ShowLoading} onHide={() => setShowLoading(false)} />
      <div className="px-3 pt-header ">
        <div className="py-2">
          <div className=" px-2 rounded-18px  bg-white shadow  pb-4">
            <div className={showFoodSection ? "d-none" : ""}>
              <Tab.Container
                defaultActiveKey={activeTab}
                onSelect={(tab: any) => setActiveTab(tab)}
              >
                <div className="text-danger text-center pt-3">{Message}</div>
                <Row className="pb-3">
                  <div className="w-100 d-flex justify-content-between align-items-center border-bottom pb-3">
                    <Nav
                      as="ul"
                      variant="pills"
                      className="navtab-bg tab-menu-management"
                    >
                      <NavTabItem
                        eventKey="foodGroup"
                        navTitle={languageUI.menuManagement}
                      />
                      <NavTabItem
                        eventKey="sideDish"
                        navTitle={languageUI.sideDish}
                      />
                      <NavTabItem
                        eventKey="footRank"
                        navTitle={languageUI.footRank}
                      />
                      <NavTabItem
                        eventKey="footRating"
                        navTitle={languageUI.footRating}
                      />
                      <NavTabItem
                        eventKey="menuPhysical"
                        navTitle={"Menu Physical"}
                      />
                    </Nav>
                    <Button
                      variant=""
                      className="btn-add-categories"
                      onClick={() => {
                        setShowModalCreateCategory(true);
                      }}
                    >
                      <i className="fe-plus me-1 text-white"></i>{" "}
                      {languageUI.addCategories}
                    </Button>
                  </div>
                </Row>
                <div className="d-flex gap-3 align-items-center">
                  <div className=" d-flex justify-content-between">
                    <FormControlLabel
                      value="start"
                      control={
                        <IOSSwitch
                          sx={{ m: 1 }}
                          checked={showRank === 1}
                          onChange={(e) => ChangShowRank(e.target.checked)}
                        />
                      }
                      label={languageUI.showingFoodRanking}
                      labelPlacement="start"
                    />
                    {activeTab === "menuPhysical" && (
                      <Button
                        variant=""
                        className="btn-add-categories"
                        onClick={() => {
                          convertAndDownloadAll();
                        }}
                      >
                        Print
                      </Button>
                    )}
                  </div>
                  <div>
                    <button
                      className="btn-food-section"
                      onClick={() => setShowFoodSection(!showFoodSection)}
                    >
                      Food Section
                    </button>
                  </div>
                </div>
                <Tab.Content className="pt-0 box-menu-template">
                  <Tab.Pane eventKey="foodGroup">
                    <DndContext
                      sensors={sensors}
                      modifiers={[restrictToVerticalAxis]}
                      onDragEnd={handleDragAndDrop}
                    >
                      <SortableContext
                        items={ListFoodGroup}
                        strategy={verticalListSortingStrategy}
                      >
                        {ListFoodGroup.length > 0 &&
                          ListFoodGroup.map((item, index) => (
                            <MenuGroupItem2
                              key={item.id}
                              id={(item?.id).toString()}
                              status={Number(item?.status) === 0 ? true : false}
                              foodGroupName={item?.name}
                              foodGroupLength={item?.list_food_group?.length}
                              foodGroupSTT={Number(item?.parent_id)}
                              changeIcon={
                                item?.id === menuFoodInGroup ? false : true
                              }
                              handleEditFoodGroup={() => {
                                handleEditFoodGroup(item?.id);
                              }}
                              bgColor={item?.color}
                              handleDelFood={() => {
                                handleDeleteFoodGroup(item?.id);
                              }}
                              handleFoodMenu={() => {
                                if (item?.id === menuFoodInGroup) {
                                  setMenuFoodInGroup(0);
                                } else {
                                  setMenuFoodInGroup(item?.id);
                                }
                              }}
                              addFoodMenu={() => {
                                // setModalAddFood(true);
                                setFoodNumericalOrderMax(
                                  Number(item?.list_food_group?.length) + 1
                                );

                                setChoooseFoodGroupAdd(Number(item?.id));
                                setShowModalAddNewFood(true);
                              }}
                              activeFoodGroupMenu={saveActiveFoodGroup}
                              listFoodItem={item.list_food_group.map((foo) => {
                                return foo?.food_id;
                              })}
                              ListFood={ListFood}
                              setShowLoading={setShowLoading}
                              GetListFoodGroup={GetListFoodGroup}
                              FoodChild={item.list_food_group}
                              handleDeleteFood={handleDeleteFood}
                              saveActiveFood={saveActiveFood}
                              handleEditFood={handleEditFood}
                            />
                          ))}
                      </SortableContext>
                    </DndContext>
                  </Tab.Pane>
                  <Tab.Pane eventKey="sideDish">
                    <Button
                      variant=""
                      className="btn-add-categories"
                      onClick={() => {
                        setFoodNumericalOrderMax(
                          Number(ListFreeSides?.length) + 1
                        );
                        setShowModalAddNewSideDish(true);
                      }}
                    >
                      <i className="fe-plus me-1 text-white"></i>{" "}
                      {languageUI.addSideDish}
                    </Button>
                    <div className="w-100 mt-2">
                      {ListFreeSides.map((item, i) => (
                        <SideDishGroupItem
                          key={"sides" + i}
                          foodSTT={i + 1}
                          id={item?.id}
                          status={Number(item?.status) === 0 ? true : false}
                          img={
                            item?.imageCourse
                              ? targetupload + item?.imageCourse
                              : default_product
                          }
                          foodName={item?.name}
                          foodGroupName={""}
                          handleEditFood={() => {
                            handleEditSide(item?.id);
                          }}
                          handleDelFood={() => {
                            handleDeleteSideDish(item?.id);
                          }}
                          activeFoodMenu={saveActiveFood}
                          priceFood={Number(item?.price)}
                        />
                      ))}
                    </div>
                  </Tab.Pane>
                  <Tab.Pane eventKey="footRank">
                    <div className="box-menu-template-table">
                      <Tab.Container
                        defaultActiveKey={activeTab2}
                        onSelect={(tab: any) => setActiveTab2(tab)}
                      >
                        <div className="text-danger text-center pt-3">
                          {Message}
                        </div>
                        <Row className="mb-3">
                          <div className="w-100 d-flex justify-content-between align-items-center pb-3">
                            <Nav
                              as="ul"
                              variant="pills"
                              className="navtab-bg tab-menu-management"
                            >
                              <NavTabItem
                                eventKey="3"
                                navTitle={languageUI.entree}
                              />
                              <NavTabItem
                                eventKey="2"
                                navTitle={languageUI.bevarage}
                              />
                              <NavTabItem
                                eventKey="1"
                                navTitle={languageUI.appetizers}
                              />
                            </Nav>
                          </div>
                        </Row>
                        <Tab.Content className="pt-0 px-4">
                          <Tab.Pane eventKey="3">
                            <Paper
                              sx={{ width: "100%", overflow: "hidden" }}
                              className="table-food-rank"
                            >
                              <TableContainer
                                sx={{
                                  height: "calc(100vh - 350px)",
                                  width: "100%",
                                  border: "1px solid #0fa54a",
                                  borderRadius: "12px",
                                }}
                              >
                                <Table
                                  stickyHeader
                                  aria-label="sticky table"
                                  sx={{
                                    maxHeight: "100%",
                                    width: "100%",
                                  }}
                                >
                                  <TableHead>
                                    <TableRow
                                      sx={{
                                        border: "none",
                                        backgroundColor: "#0fa54a",
                                      }}
                                    >
                                      <TableCell
                                        sx={{
                                          border: "none",
                                          width: 100,
                                          fontWeight: 700,
                                          backgroundColor: "#0fa54a",
                                          fontFamily: "Roboto",
                                          color: "white",
                                        }}
                                      >
                                        STT
                                      </TableCell>
                                      <TableCell
                                        sx={{
                                          border: "none",
                                          width: 100,
                                          fontWeight: 700,
                                          backgroundColor: "#0fa54a",
                                          fontFamily: "Roboto",
                                          color: "white",
                                        }}
                                        align="left"
                                      >
                                        #order
                                      </TableCell>
                                      <TableCell
                                        sx={{
                                          border: "none",
                                          fontWeight: 700,
                                          backgroundColor: "#0fa54a",
                                          fontFamily: "Roboto",
                                          color: "white",
                                        }}
                                        align="left"
                                      >
                                        Name
                                      </TableCell>
                                    </TableRow>
                                  </TableHead>
                                  <TableBody>
                                    {dataListFoodRank.map((row, index) => (
                                      <TableRow
                                        sx={{
                                          border: "none",
                                        }}
                                        key={row.id}
                                      >
                                        <TableCell
                                          sx={{
                                            border: "none",
                                            width: 100,
                                            padding: "8px",
                                            fontWeight: 700,
                                            color: "#4a4a6a",
                                          }}
                                          component="th"
                                          scope="row"
                                        >
                                          {index}
                                        </TableCell>
                                        <TableCell
                                          sx={{
                                            border: "none",
                                            width: 100,
                                            padding: "8px",
                                            fontWeight: 700,
                                            color: "#4a4a6a",
                                          }}
                                          align="left"
                                        >
                                          {row.order_num}
                                        </TableCell>
                                        <TableCell
                                          sx={{
                                            border: "none",
                                            padding: "8px",
                                          }}
                                          align="left"
                                        >
                                          {row.name}
                                        </TableCell>
                                      </TableRow>
                                    ))}
                                  </TableBody>
                                </Table>
                              </TableContainer>
                            </Paper>
                            {/* <TableContainer
                            component={Paper}
                            className="footRank"
                            sx={{
                              border: "none",
                              boxShadow: "none",
                              maxHeight: 300,
                              maxWidth: "100%",
                            }}
                          >
                            <Table
                              sx={{
                                minWidth: 650,
                                border: "none",
                                backgroundColor: "#EDEDED",
                                borderRadius: 8,
                              }}
                              stickyHeader
                              aria-label="sticky table"
                            >
                              <TableHead sx={{ border: "none" }}>
                                <TableRow sx={{ border: "none" }}>
                                  <TableCell
                                    sx={{
                                      border: "none",
                                      width: 100,
                                      fontWeight: 600,
                                    }}
                                  >
                                    STT
                                  </TableCell>
                                  <TableCell
                                    sx={{
                                      border: "none",
                                      width: 100,
                                      fontWeight: 600,
                                    }}
                                    align="left"
                                  >
                                    #order
                                  </TableCell>
                                  <TableCell
                                    sx={{ border: "none", fontWeight: 600 }}
                                    align="left"
                                  >
                                    Name
                                  </TableCell>
                                </TableRow>
                              </TableHead>
                              <TableBody>
                                {dataListFoodRank.map((row, index) => (
                                  <TableRow key={row.id}>
                                    <TableCell
                                      sx={{ border: "none", width: 100 }}
                                      component="th"
                                      scope="row"
                                    >
                                      {index}
                                    </TableCell>
                                    <TableCell
                                      sx={{ border: "none", width: 100 }}
                                      align="left"
                                    >
                                      {row.order_num}
                                    </TableCell>
                                    <TableCell
                                      sx={{ border: "none" }}
                                      align="left"
                                    >
                                      {row.name}
                                    </TableCell>
                                  </TableRow>
                                ))}
                              </TableBody>
                            </Table>
                          </TableContainer> */}
                          </Tab.Pane>
                          <Tab.Pane eventKey="2">
                            <Paper
                              sx={{ width: "100%", overflow: "hidden" }}
                              className="table-food-rank"
                            >
                              <TableContainer
                                sx={{
                                  height: "calc(100vh - 350px)",
                                  width: "100%",
                                  border: "1px solid #0fa54a",
                                  borderRadius: "12px",
                                }}
                              >
                                <Table
                                  stickyHeader
                                  aria-label="sticky table"
                                  sx={{
                                    maxHeight: "100%",
                                    width: "100%",
                                  }}
                                >
                                  <TableHead>
                                    <TableRow
                                      sx={{
                                        border: "none",
                                        backgroundColor: "#0fa54a",
                                      }}
                                    >
                                      <TableCell
                                        sx={{
                                          border: "none",
                                          width: 100,
                                          fontWeight: 700,
                                          backgroundColor: "#0fa54a",
                                          fontFamily: "Roboto",
                                          color: "white",
                                        }}
                                      >
                                        STT
                                      </TableCell>
                                      <TableCell
                                        sx={{
                                          border: "none",
                                          width: 100,
                                          fontWeight: 700,
                                          backgroundColor: "#0fa54a",
                                          fontFamily: "Roboto",
                                          color: "white",
                                        }}
                                        align="left"
                                      >
                                        #order
                                      </TableCell>
                                      <TableCell
                                        sx={{
                                          border: "none",
                                          fontWeight: 700,
                                          backgroundColor: "#0fa54a",
                                          fontFamily: "Roboto",
                                          color: "white",
                                        }}
                                        align="left"
                                      >
                                        Name
                                      </TableCell>
                                    </TableRow>
                                  </TableHead>
                                  <TableBody>
                                    {dataListFoodRank.map((row, index) => (
                                      <TableRow
                                        sx={{
                                          border: "none",
                                        }}
                                        key={row.id}
                                      >
                                        <TableCell
                                          sx={{
                                            border: "none",
                                            width: 100,
                                            padding: "8px",
                                            fontWeight: 700,
                                            color: "#4a4a6a",
                                          }}
                                          component="th"
                                          scope="row"
                                        >
                                          {index}
                                        </TableCell>
                                        <TableCell
                                          sx={{
                                            border: "none",
                                            width: 100,
                                            padding: "8px",
                                            fontWeight: 700,
                                            color: "#4a4a6a",
                                          }}
                                          align="left"
                                        >
                                          {row.order_num}
                                        </TableCell>
                                        <TableCell
                                          sx={{
                                            border: "none",
                                            padding: "8px",
                                          }}
                                          align="left"
                                        >
                                          {row.name}
                                        </TableCell>
                                      </TableRow>
                                    ))}
                                  </TableBody>
                                </Table>
                              </TableContainer>
                            </Paper>
                          </Tab.Pane>
                          <Tab.Pane eventKey="1">
                            <Paper
                              sx={{ width: "100%", overflow: "hidden" }}
                              className="table-food-rank"
                            >
                              <TableContainer
                                sx={{
                                  height: "calc(100vh - 350px)",
                                  width: "100%",
                                  border: "1px solid #0fa54a",
                                  borderRadius: "12px",
                                }}
                              >
                                <Table
                                  stickyHeader
                                  aria-label="sticky table"
                                  sx={{
                                    maxHeight: "100%",
                                    width: "100%",
                                  }}
                                >
                                  <TableHead>
                                    <TableRow
                                      sx={{
                                        border: "none",
                                        backgroundColor: "#0fa54a",
                                      }}
                                    >
                                      <TableCell
                                        sx={{
                                          border: "none",
                                          width: 100,
                                          fontWeight: 700,
                                          backgroundColor: "#0fa54a",
                                          fontFamily: "Roboto",
                                          color: "white",
                                        }}
                                      >
                                        STT
                                      </TableCell>
                                      <TableCell
                                        sx={{
                                          border: "none",
                                          width: 100,
                                          fontWeight: 700,
                                          backgroundColor: "#0fa54a",
                                          fontFamily: "Roboto",
                                          color: "white",
                                        }}
                                        align="left"
                                      >
                                        #order
                                      </TableCell>
                                      <TableCell
                                        sx={{
                                          border: "none",
                                          fontWeight: 700,
                                          backgroundColor: "#0fa54a",
                                          fontFamily: "Roboto",
                                          color: "white",
                                        }}
                                        align="left"
                                      >
                                        Name
                                      </TableCell>
                                    </TableRow>
                                  </TableHead>
                                  <TableBody>
                                    {dataListFoodRank.map((row, index) => (
                                      <TableRow
                                        sx={{
                                          border: "none",
                                        }}
                                        key={row.id}
                                      >
                                        <TableCell
                                          sx={{
                                            border: "none",
                                            width: 100,
                                            padding: "8px",
                                            fontWeight: 700,
                                            color: "#4a4a6a",
                                          }}
                                          component="th"
                                          scope="row"
                                        >
                                          {index}
                                        </TableCell>
                                        <TableCell
                                          sx={{
                                            border: "none",
                                            width: 100,
                                            padding: "8px",
                                            fontWeight: 700,
                                            color: "#4a4a6a",
                                          }}
                                          align="left"
                                        >
                                          {row.order_num}
                                        </TableCell>
                                        <TableCell
                                          sx={{
                                            border: "none",
                                            padding: "8px",
                                          }}
                                          align="left"
                                        >
                                          {row.name}
                                        </TableCell>
                                      </TableRow>
                                    ))}
                                  </TableBody>
                                </Table>
                              </TableContainer>
                            </Paper>
                          </Tab.Pane>
                        </Tab.Content>
                      </Tab.Container>
                    </div>
                  </Tab.Pane>
                  <Tab.Pane eventKey="footRating">
                    <div className="box-menu-template">
                      <Tab.Container
                        defaultActiveKey={activeTab2}
                        onSelect={(tab: any) => setActiveTab2(tab)}
                      >
                        <div className="d-flex flex-column ">
                          <span className="des-ranking-food pb-0">
                            Customers who leave food feedback will be shown here
                          </span>
                          <Tab.Content className="pt-0 box-menu-tempalte">
                            <Tab.Container
                              defaultActiveKey={activeTab3}
                              onSelect={(tab: any) => {
                                setActiveTab3(tab);
                                console.log(dataListFeedbackFoodByOwner);
                              }}
                            >
                              <div className="text-danger text-center pt-3">
                                {Message}
                              </div>
                              <Row className="">
                                <div className="w-100 d-flex justify-content-between align-items-center pb-3">
                                  <Nav
                                    as="ul"
                                    variant="pills"
                                    className="navtab-bg tab-menu-management"
                                  >
                                    <NavTabItem
                                      eventKey="1"
                                      navTitle={languageUI.today}
                                    />
                                    <NavTabItem
                                      eventKey="2"
                                      navTitle={languageUI.yesterday}
                                    />
                                    <NavTabItem
                                      eventKey="3"
                                      navTitle={"All Time"}
                                    />
                                    <NavTabItem
                                      eventKey="4"
                                      navTitle={languageUI.allFood}
                                    />
                                    <NavTabItem
                                      eventKey="5"
                                      navTitle="Staff Feedback"
                                    />
                                  </Nav>
                                </div>
                              </Row>
                              <Tab.Content className="pt-0 ">
                                <Tab.Pane eventKey="1">
                                  <div className="container-today d-flex flex-column gap-2">
                                    {dataListFeedbackFoodByOwner.map((item) => (
                                      <div
                                        key={item.review_id}
                                        className="d-flex gap-2 box-food-feedback"
                                      >
                                        <div
                                          className="font-bold font-roboto text-4a4a6a rounded-3 p-2"
                                          style={{ width: "100px" }}
                                        >
                                          <span>{item.date_feedback}</span>
                                        </div>
                                        <div className="w-100 d-flex flex-column gap-1 p-2 bg-white rounded-4">
                                          <span className="title-show-ranking">
                                            {item.food_name}
                                          </span>
                                          <div className="d-flex flex-column gap-1">
                                            <div className="d-flex gap-1">
                                              <Rating
                                                size="large"
                                                sx={{
                                                  fontSize: "2rem",
                                                }}
                                                max={10}
                                                value={item.rate}
                                                // disabled={true}
                                                readOnly={true}
                                              />
                                              {/* <StarOutlineIcon sx={{ color: 'yellow' }} /> */}
                                            </div>
                                          </div>
                                          <span>{item.content}</span>
                                        </div>
                                      </div>
                                    ))}
                                  </div>
                                </Tab.Pane>
                                <Tab.Pane eventKey="2">
                                  <div className="container-today d-flex flex-column gap-2">
                                    {dataListFeedbackFoodByOwner.map((item) => (
                                      <div
                                        key={item.review_id}
                                        className="d-flex gap-2"
                                      >
                                        <div
                                          className="font-bold font-roboto text-4a4a6a rounded-3 p-2"
                                          style={{ width: "100px" }}
                                        >
                                          <span>{item.date_feedback}</span>
                                        </div>
                                        <div className="w-100 d-flex flex-column gap-1  p-2 bg-white rounded-4">
                                          <span className="title-show-ranking">
                                            {item.food_name}
                                          </span>
                                          <div className="d-flex flex-column gap-1">
                                            <div className="d-flex gap-1">
                                              <Rating
                                                size="large"
                                                sx={{
                                                  fontSize: "2rem",
                                                }}
                                                max={10}
                                                value={item.rate}
                                                // disabled={true}
                                                readOnly={true}
                                              />
                                              {/* <StarOutlineIcon sx={{ color: 'yellow' }} /> */}
                                            </div>
                                          </div>
                                          <span>{item.content}</span>
                                        </div>
                                      </div>
                                    ))}
                                  </div>
                                </Tab.Pane>
                                <Tab.Pane eventKey="3">
                                  <div className="container-today d-flex flex-column gap-2">
                                    {dataListFeedbackFoodByOwner.map((item) => (
                                      <div
                                        key={item.review_id}
                                        className="d-flex gap-2"
                                      >
                                        <div
                                          className="font-bold font-roboto text-4a4a6a rounded-3 p-2"
                                          style={{ width: "100px" }}
                                        >
                                          <span>{item.date_feedback}</span>
                                        </div>
                                        <div className="w-100 d-flex flex-column gap-1  p-2 bg-white rounded-4">
                                          <span className="title-show-ranking">
                                            {item.food_name}
                                          </span>
                                          <div className="d-flex flex-column gap-1">
                                            <div className="d-flex gap-1">
                                              <Rating
                                                size="large"
                                                sx={{
                                                  fontSize: "2rem",
                                                }}
                                                max={10}
                                                value={item.rate}
                                                // disabled={true}
                                                readOnly={true}
                                              />
                                              {/* <StarOutlineIcon sx={{ color: 'yellow' }} /> */}
                                            </div>
                                          </div>
                                          <span>{item.content}</span>
                                        </div>
                                      </div>
                                    ))}
                                  </div>
                                </Tab.Pane>
                                <Tab.Pane eventKey="4">
                                  {!showrate ? (
                                    <div className="container-today d-flex flex-column gap-2">
                                      {dataAllFoodFeedbackByOwner.map(
                                        (item) => (
                                          <div
                                            key={item.food_id}
                                            className="title-all-food"
                                            onClick={() =>
                                              handleShowRate(item.food_id)
                                            }
                                          >
                                            {item.food_name} (
                                            {item.total_feedback})
                                            <ArrowForwardIosIcon className="icon-all-food" />
                                          </div>
                                        )
                                      )}
                                    </div>
                                  ) : (
                                    <div className="container-today d-flex flex-column gap-2">
                                      <div
                                        className="btn-back-feedback"
                                        onClick={() => setShowRate(false)}
                                      >
                                        <ArrowBackRoundedIcon />
                                      </div>
                                      {dataAllFeedbackByFood.map((item) => (
                                        <div
                                          key={item.review_id}
                                          className="d-flex gap-2"
                                        >
                                          <div
                                            className="d-flex flex-column font-bold font-roboto text-4a4a6a rounded-3 p-2"
                                            style={{ width: "100px" }}
                                          >
                                            <span>{item.date_feedback}</span>
                                            <span>{item.hour_feedback}</span>
                                          </div>
                                          <div className="w-100 d-flex flex-column gap-1  p-2 bg-white rounded-4">
                                            <span className="title-show-ranking">
                                              {item.food_name}
                                            </span>
                                            <div className="d-flex flex-column gap-1">
                                              <div className="d-flex gap-1">
                                                <Rating
                                                  size="large"
                                                  sx={{
                                                    fontSize: "2rem",
                                                  }}
                                                  max={10}
                                                  value={item.rate}
                                                  // disabled={true}
                                                  readOnly={true}
                                                />
                                              </div>
                                            </div>
                                            <span>{item.content}</span>
                                          </div>
                                        </div>
                                      ))}
                                    </div>
                                  )}
                                </Tab.Pane>
                                <Tab.Pane eventKey="5">
                                  <div className="container-today d-flex flex-column gap-2 p-0">
                                    <StaffReview />
                                  </div>
                                </Tab.Pane>
                              </Tab.Content>
                            </Tab.Container>
                          </Tab.Content>
                        </div>
                      </Tab.Container>
                    </div>
                  </Tab.Pane>
                  <Tab.Pane eventKey="menuPhysical" className="p-4">
                    <div>
                      {ListFoodGroup.length > 0 &&
                        ListFoodGroup.map((item, index) => (
                          <div
                            key={index + "a"}
                            className={`${
                              item.list_food_group.length === 0 ? "d-none" : ""
                            }`}
                          >
                            <p className={`group-name-physical`}>{item.name}</p>
                            <div className="p-6">
                              {item.list_food_group.map(
                                (item_food, indexItems) => (
                                  <div
                                    key={`key-${indexItems}`}
                                    className="d-flex mb-2 h-140px menu-physical-item"
                                  >
                                    <div className="box-img-food-name">
                                      <div className="w-100 h-100 ">
                                        <img
                                          src={
                                            item_food?.imageCourse
                                              ? targetupload +
                                                item_food?.imageCourse
                                              : default_product
                                          }
                                          alt=""
                                          className="h-100 w-100 object-fit-cover"
                                          onError={(e) => {
                                            e.currentTarget.src =
                                              default_product;
                                          }}
                                        />
                                      </div>
                                    </div>
                                    <div className="w-100 p-4">
                                      <div className="food-name-menu-items mb-1 text-truncate-container w-100 max-w-100">
                                        <p className="m-0 text-truncate">
                                          {item_food.food_name}
                                        </p>
                                        {item_food.description && (
                                          <p className="mb-0 quanlity-product-menu">
                                            {item_food.description}
                                          </p>
                                        )}
                                        <p className="mb-0 d-flex gap-2px mb-1 mt-1 text-orange-i price-food-name-menu">
                                          {FormatDolla(Number(item_food.price))}{" "}
                                          <p className="mb-1 text-dollar-food-menu-items">
                                            $
                                          </p>
                                        </p>
                                      </div>
                                    </div>
                                  </div>
                                )
                              )}
                            </div>
                          </div>
                        ))}
                      <div className="abc">{MenuPrint()}</div>
                    </div>
                  </Tab.Pane>
                </Tab.Content>
              </Tab.Container>
            </div>
            <div
              className={` ${showFoodSection ? "" : "d-none"} w-full h-full `}
            >
              <div className="p-4 d-flex justify-content-between align-items-center">
                <button
                  className="btn-back-food-section"
                  onClick={() => setShowFoodSection(false)}
                >
                  <ArrowBackIcon />
                  Back
                </button>
                <h1 className="text-4a4a6a">Setting Food Section</h1>
                <button className="btn-back-food-section opacity-0">
                  <ArrowBackIcon />
                  Back
                </button>
              </div>
              <Paper sx={{ width: "100%" }}>
                <TableContainer sx={{ maxHeight: `calc(100vh - 200px)` }}>
                  <Table stickyHeader aria-label="sticky table">
                    <TableHead>
                      <TableRow>
                        {columns.map((column) => (
                          <TableCell
                            key={column.id}
                            align={column.align}
                            style={{ minWidth: column.minWidth }}
                          >
                            {column.label}
                          </TableCell>
                        ))}
                      </TableRow>
                    </TableHead>
                    <TableBody>
                      <ContentTableFoodSection
                        handleChangeValue={() => {}}
                        nameFood="Cá Ngừ"
                        value="4"
                        index="1"
                      />
                    </TableBody>
                  </Table>
                </TableContainer>
              </Paper>
            </div>
          </div>
        </div>
      </div>
      <ModalCreateCategory
        titleModel={languageUI.createNewFoodCategory}
        FoodGroupNameAdd={FoodGroupNameAdd}
        FoodGroupNumerical={FoodGroupNumerical}
        FoodGroupNumericalMax={FoodGroupNumericalMax}
        handleClose={dismisModalCreateCategory}
        setFoodGroupNameAdd={setFoodGroupNameAdd}
        setFoodGroupNumerical={setFoodGroupNumerical}
        show={showModalCreateCategory}
        saveNewFoodGroup={(color: string) => saveNewFoodGroup(color)}
      />
      <ModalEditCategory
        colorHex={FoodGroupColorEdit}
        titleModel={languageUI.editFoodGroup}
        FoodGroupNameAdd={nameFoodGroup}
        FoodGroupNumerical={FoodGroupNumericalEdit}
        FoodGroupNumericalMax={FoodGroupNumericalMax - 1}
        handleClose={dismisModalEditCategory}
        setFoodGroupNameAdd={setNameFoodGroup}
        setFoodGroupNumerical={setFoodGroupNumericalEdit}
        show={showModalEditCategory}
        saveNewFoodGroup={(color: string) => saveEditFoodGroup(color)}
      />
      <ModalAddNewFood
        handleClose={dismisModalAddNewFood}
        show={showModalAddNewFood}
        FoodNumericalOrderMax={FoodNumericalOrderMax}
        ChoooseFoodGroupAdd={ChoooseFoodGroupAdd}
        comboIngredient={comboIngredient}
        comboSideDish={comboSideDish}
        comboUnit={comboUnit}
        HandleAddFoodSuccess={HandleAddFoodSuccess}
      />

      <ModalEditFood
        handleClose={dismisModalEditFood}
        show={showModalEditFood}
        FoodNumericalOrderMax={FoodNumericalOrderMax}
        idFood={idFoodEdit ?? 0}
        comboIngredient={comboIngredient}
        comboSideDish={comboSideDish}
        comboUnit={comboUnit}
        HandleAddFoodSuccess={HandleEditFoodSuccess}
      />
      {/* //side */}
      <ModalAddNewSideDish
        handleClose={dismisModalAddNewSideDish}
        show={showModalAddNewSideDish}
        FoodNumericalOrderMax={FoodNumericalOrderMax}
        ChoooseFoodGroupAdd={ChoooseFoodGroupAdd}
        comboIngredient={comboIngredient}
        comboSideDish={comboSideDish}
        comboUnit={comboUnit}
        HandleAddFoodSuccess={HandleAddSideSuccess}
      />
      <ModalEditSideDish
        handleClose={dismisModalEditSide}
        show={showModalEditSideDish}
        FoodNumericalOrderMax={FoodNumericalOrderMax}
        idFood={idSideEdit}
        comboIngredient={comboIngredient}
        comboSideDish={comboSideDish}
        comboUnit={comboUnit}
        HandleAddFoodSuccess={HandleEditSideDishSuccess}
      />
    </>
  );
});

export default TemplateMenu2;
