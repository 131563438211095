import { Form, InputGroup } from "react-bootstrap";
import "./Styles.css";
import { useCallback, useEffect, useState } from "react";
import * as userSlice from "../../store/slices/userSlice";
import { useSelector } from "react-redux";
import {
  doordash,
  grubhub,
  iconSearchGreen,
  postmates,
  trashRed,
  uberEats,
  upScroll,
} from "../../components/ImgExport";
import FoodItems from "./components/FoodItems/FoodItems";
import FoodOrderItems from "./components/FoodOrderItems/FoodOrderItems";
import SeeYourOrder from "./components/SeeYourOrder/SeeYourOrder";
import ModalSelectEmployeeTip from "../webcheckin/components/ModalSelectEmployeeTip/ModalSelectEmployeeTip";
import * as paymentApi from "../../api/apiCheckin/paymentApi";
import * as chooseMenuApi from "../../api/apiCheckin/chooseMenuApi";
import Select from "react-select";
import { targetupload } from "../../api/urlImage";
import { FormatDolla, parseDolla } from "../../utils/format";
import { useLocation, useNavigate } from "react-router-dom";
import * as menuPosApi from "../../api/apiOwner/menuPosApi";
import ModalToast from "./components/ModalToast/ModalToastErr";
import {
  VoucherCheckPass,
  VoucherUsed,
  checkOrderHaveVoucher,
  checkVoucher,
} from "../../api/apiOwner/voucherApi";
import * as staffTipSlice from "../../store/slices/ListStaffTipSlice";
import * as listFoodGroupSlice from "../../store/slices/listFoodGroupSlice";
import * as menuFoodSlice from "../../store/slices/menuFoodSlice";
import * as orderSlice from "../../store/slices/orderSlice";
import { useDispatch } from "react-redux";
import { getLanguageUI } from "../../Services/languageUI";
import * as tableSlice from "../../store/slices/tableSlice";
import OrderListITemPrintPosMenu from "../webcheckin/components/OrderListITem/OrderListITemPrintPosMenu";
import React from "react";
import ModalLoading from "../../components/ModalLoading";
import {
  CancelPaymentIntent,
  CreatePaymentIntent,
  PaymentIntentStripe,
} from "../../api/apiOwner/stripeApi";
import ModalReader from "./components/ModalReader";
import ModalPaymentError from "./components/ModalPaymentError/ModalPaymentError";
import ModalOrderFunction from "./components/ModalOrderFunction/ModalOrderFunction";
import * as SettingPrinterApi from "../../api/apiOwner/SettingPrinterApi";
import { PrintOrderPage } from "../../api/apiOwner/printerApi";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { ClickAwayListener, Tooltip } from "@mui/material";
import HelpOutlineSharpIcon from "@mui/icons-material/HelpOutlineSharp";
import LocalShippingIcon from "@mui/icons-material/LocalShipping";
import ModalAddSideDish, {
  listSideActive,
} from "./components/ModalAddSideDish/ModalAddSideDish";
import socket from "../../utils/socket";
import MonetizationOnRoundedIcon from "@mui/icons-material/MonetizationOnRounded";
import AddCardRoundedIcon from "@mui/icons-material/AddCardRounded";
import axios from "axios";
import ModalViewMoreGroupFood from "./components/ModalViewMoreGroupFood/ModalViewMoreGroupFood";
import * as staffJointSlice from "../../store/slices/staffJointSlice";

interface POSMenuProps {
  listStaff?: paymentApi.TipStaff[];
}
interface cartPos {
  food_id: string;
  foodName: string;
  noteFood_id: string;
  noteFood_name: string;
  price: number;
  side_id: string;
  imageFood: string;
  quantity: number;
  notechef: string;
  statusOrderGo: number;
  maxSide: number;
  priceSide: number;
  typeFood: number;
}
const POSMenu: React.FC<POSMenuProps> = () => {
  const navigate = useNavigate();
  const UserIdStore = useSelector(userSlice.selectorUserId);
  const nameStore = useSelector(userSlice.selectorNameStore);
  const addressStore = useSelector(userSlice.selectorAddress);
  const phoneOwnerStore = useSelector(userSlice.selectorPhoneNumber);
  const pos_id = useSelector(userSlice.selectorPosId) ?? 0;
  const taxOffline = useSelector(userSlice.selectorTax);
  const ListStaffTipOffline = useSelector(staffTipSlice.selectorListStaffTip);
  const ListFoodGroupOffline = useSelector(
    listFoodGroupSlice.selectorListFoodCategory
  );
  const ListMenuFoodOffline = useSelector(menuFoodSlice.selectorListMenuFood);
  const listOrderOffline = useSelector(orderSlice.selectorOrder);
  const TableOffline = useSelector(tableSlice.selectorTable);
  const staffJointId = useSelector(staffJointSlice.selectorStaffId) ?? 0;
  const dispatch = useDispatch();
  const location = useLocation();
  const params: any = location.state;
  const table_id = params?.table_id;
  const floorId = params?.floor_id;
  const NameTable = params?.NameTable;
  const guestNumber = params?.guest;
  // var order_id = params?.order_id;
  const [isOnline, setIsOnline] = useState(navigator.onLine);
  const [statusOrderGo, setStatusOrderGo] = useState<number>(1);
  const [showMoreListOrder, setShowMoreListOrder] = useState(false);
  const [showSeeYourOrder, setShowSeeYourOrder] = useState(false);
  const [showModalChooseNameTip, setShowModalChooseNameTip] = useState(false);
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [staffName, setStaffName] = useState<string>(""); //tên staff dc chọn nhận tip
  const [staffId, setStaffId] = useState<string>(""); //staff dc chọn nhận tip
  const [listCategory, setListCateGory] = useState<
    chooseMenuApi.FoodCategory[]
  >([]);
  const [searchFoodGroup, setSearchFoodGroup] = useState(""); //giá trị category khi seacrh
  const [searchFood, setSearchFood] = useState(""); //tên sản phẩm search

  const [ListMenuItems, setListMenuItems] = useState<
    chooseMenuApi.MenuCategory[]
  >([]);
  const [listCartFood, setListCartFood] = useState<cartPos[]>([]);
  const [totalPrice, setTotalPrice] = useState<number>(0); //tổng giá đơn hàng
  const [tax, setTax] = useState<number>(0); //%tax
  const [listStaff, setListStaff] = useState<paymentApi.TipStaff[]>([]); //list staff đc nhận tip

  const [tipPrice, setTipPrice] = useState<number>();
  const [messageError, setMessageError] = useState<string>("");
  const [messageErrorVoucher, setMessageErrorVoucher] = useState<string>("");
  const [showToastError, setShowToastError] = useState<boolean>(false);
  const [voucherCode, setVoucherCode] = useState("");
  const [infoVoucher, setinfoVoucher] = useState<VoucherCheckPass>();
  const [discount, setDiscount] = useState<number>(0);
  const [isShowVoucher, setIsShowVoucher] = useState(true);
  const [paymentMethod, setPaymentMethod] = useState(0); //1 cash, 2 visa
  const [ShowLoading, setShowLoading] = useState<boolean>(false);
  const [order_id, setOrderId] = useState<string>(params?.order_id);
  const [showModalPaymentErr, setShowModalPaymentErr] =
    useState<boolean>(false);
  const [showDelevery, setShowDelevery] = useState<boolean>(false);
  const [messageErrPayment, setMessageErrPayment] = useState("");
  const [showModalReader, setShowModalReader] = useState(false);
  const [paymentIntentId, setpaymentIntentId] = useState("");

  const languageUI = getLanguageUI().posMenu;
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [selectOptionMenu, setSelectOptionMenu] = useState("all");
  const [listPrinterName, setListPrinterName] = useState<
    SettingPrinterApi.ListPrinterByCatalog[]
  >([]);
  const [openToolTip, setOpenToolTip] = useState(false);
  const [feeStripe, setFeeStripe] = useState<boolean>(false);
  const [feePrice, setFeePrice] = useState<number>(0);
  const [showModalAddSideDish, setShowModalAddSideDish] = useState(false);
  const [maxSideFoodOrder, setMaxSideFoodOrder] = useState(0);
  const [ListFreeSidesActive, setListFreeSidesActive] = useState<
    listSideActive[]
  >([]);
  const [priceSide, setPriceSide] = useState<number>(0);
  const [showHappyHour, setShowHappyHour] = useState<boolean>(false);
  const [timeExpiration, setTimeExpiration] = useState<number>();
  const [infoHappyhour, setInfoHappyhour] =
    useState<menuPosApi.InfoHappyHour>();
  const [listFoodSell, setListFoodSell] = useState<menuPosApi.Menu[]>([]);
  const [showImgFood, setShowImgFood] = useState<boolean>(false);
  const [showModalViewMoreGroup, setShowModalViewMoreGroup] =
    useState<boolean>(false);

  const dismisModalViewMoreGroup = () => {
    setShowModalViewMoreGroup(false);
  };

  const dismisModalAddSideDish = () => {
    setShowModalAddSideDish(false);
  };

  const listDelevery = [
    {
      id: 5,
      name: "postmates",
      img: postmates,
    },
    {
      id: 6,
      name: "uberEats",
      img: uberEats,
    },
    {
      id: 7,
      name: "grubhub",
      img: grubhub,
    },
    {
      id: 8,
      name: "doordash",
      img: doordash,
    },
  ];
  const handleTooltipClose = () => {
    setOpenToolTip(false);
  };

  const dismisModalReader = () => {
    setShowModalReader(false);
  };

  const dismisModalPaymentErr = () => {
    setShowModalPaymentErr(false);
  };
  const dismisModalSeeYourOrder = () => {
    setShowSeeYourOrder(false);
    setStaffName("");
    setStaffId("");
    setListCartFood([]);
    setTotalPrice(0);
    setTipPrice(0);
    setPriceSide(0);
    setListFreeSidesActive([]);
    setVoucherCode("");
  };

  const HandleSeeOrder = () => {
    setShowSeeYourOrder(false);

    navigate("/web-owner/tablepos", {
      state: { orderShow: order_id, tableShow: table_id },
    });
  };
  const dismisModalChooseNameTip = () => {
    setShowModalChooseNameTip(false);
  };

  const gListPrinter = async () => {
    if (UserIdStore) {
      const req = await SettingPrinterApi.gNamePrinterByCatalog(
        UserIdStore,
        3,
        pos_id
      );
      if (Number(req?.status) === 1) {
        setListPrinterName(req?.data ?? []);
      }
    }
  };

  const arrayNote: {
    id: number;
    title: string;
  }[] = [
      {
        id: 1,
        title: languageUI.eatHere,
      },
      {
        id: 2,
        title: languageUI.orderToGo,
      },
    ];
  const gConfig = async () => {
    if (!isOnline && taxOffline) {
      setTax(taxOffline);
    } else {
      if (UserIdStore) {
        const config = await paymentApi.getConfig(UserIdStore);
        if (config.status === 1) {
          setTax(Number(config?.data?.tax) ?? 0);
          setFeeStripe(Number(config?.data?.fee_stripe) === 1 ? true : false);
          dispatch(userSlice.setTax(Number(config?.data?.tax) ?? 0));
        }
      }
    }
  };
  const gListStaffTip = async () => {
    if (!isOnline && ListStaffTipOffline) {
      setListStaff(ListStaffTipOffline);
    } else {
      if (UserIdStore) {
        const StaffApi = await paymentApi.getListTipStaff(UserIdStore);
        if (StaffApi.status === 1) {
          setListStaff(StaffApi.data ?? []);
          dispatch(staffTipSlice.setListStaffTip(StaffApi?.data ?? []));
        }
      }
    }
  };
  const handleOnchageFoodGroup = (e: any) => {
    if (e?.value === undefined) {
      // getListMenuFood(ownerID);
      handleSearch(true);
      setSearchFoodGroup("");
      return;
    }
    setSearchFoodGroup(e?.value);
  };
  //lấy combo category
  const gListCategory = async (owner_id: string) => {
    if (!isOnline && ListFoodGroupOffline) {
      setListCateGory(ListFoodGroupOffline);
    } else {
      const CategoryStatus = await chooseMenuApi.gFoodCateGory(owner_id);
      if (Number(CategoryStatus.status) === 1) {
        setListCateGory(CategoryStatus?.data ?? []);
        dispatch(
          listFoodGroupSlice.setListFoodCategory(CategoryStatus?.data ?? [])
        );
      }
    }
  };

  const getListMenuFood = async (ownerID: number) => {
    if (!isOnline && ListMenuFoodOffline) {
      setListMenuItems(ListMenuFoodOffline);
    } else {
      setShowLoading(true);
      const resMenu = await chooseMenuApi.gListMenu(ownerID);
      if (Number(resMenu.status) === 1 && resMenu?.data) {
        setListMenuItems(resMenu?.data ?? []);
        setShowLoading(false);
        dispatch(menuFoodSlice.setListMenuFood(resMenu?.data ?? []));
      } else {
        setShowLoading(false);
      }
    }
  };

  // search
  const handleSearch = async (clear: boolean = false) => {
    if (!isOnline && ListMenuFoodOffline) {
      if (clear) {
        setListMenuItems(ListMenuFoodOffline);
      } else {
        setListMenuItems(
          ListMenuFoodOffline.filter(
            (item) => item?.foodgroup_id === searchFoodGroup
          )
        );
      }
    } else {
      if (UserIdStore) {
        const resMenu = await chooseMenuApi.gListMenu(
          UserIdStore,
          searchFoodGroup,
          searchFood,
          clear
        );
        if (Number(resMenu.status) === 1 && resMenu?.data) {
          setListMenuItems(resMenu?.data ?? []);
        }
      }
    }
  };
  // search khi bấm clear
  const handleClearMenu = async () => {
    if (!isOnline && ListMenuFoodOffline) {
      setListMenuItems(ListMenuFoodOffline);
    } else {
      if (UserIdStore) {
        navigate("/web-owner/tablepos", {
          state: { floorId },
        });
        // const resMenu = await chooseMenuApi.gListMenu(
        //   UserIdStore,
        //   searchFoodGroup,
        //   "",
        //   false
        // );
        // if (Number(resMenu.status) === 1 && resMenu?.data) {
        //   setListMenuItems(resMenu?.data ?? []);
        // }
      }
    }
  };
  //thêm món vào danh sách
  const addToCart = (
    food_id: string,
    foodName: string,
    price: number,
    side_id: string,
    maxSide: number,
    typeFood: number,
    priceSide: number = 0,
    noteFood_id: string = "",
    noteFood_name: string = "",
    notechef: string = "",
    imageFood: string = "",
    statusOrder: number = 1
  ) => {
    if (
      listCartFood.length > 0 &&
      listCartFood.some(
        (item) =>
          item?.food_id === food_id &&
          item?.noteFood_id === noteFood_id &&
          item?.notechef === notechef &&
          item?.statusOrderGo === statusOrder
      )
    ) {
      setListCartFood(
        listCartFood.map((item) => {
          return item?.food_id === food_id &&
            item?.noteFood_id === noteFood_id &&
            item?.notechef === notechef
            ? { ...item, quantity: item?.quantity + 1 }
            : item;
        })
      );
    } else {
      setListCartFood([
        ...listCartFood,
        {
          food_id,
          foodName,
          noteFood_id,
          price,
          side_id,
          imageFood,
          quantity: 1,
          noteFood_name: noteFood_name,
          notechef,
          statusOrderGo: statusOrder,
          maxSide,
          priceSide,
          typeFood,
        },
      ]);
    }
  };
  //thay đổi số lượng cart
  const changeQuantity = (index: number, quantity: number) => {
    if (quantity <= 0) {
      setListCartFood([...listCartFood.filter((item, i) => i !== index)]);
    } else {
      setListCartFood([
        ...listCartFood.map((item, i) => {
          return i === index ? { ...item, quantity: quantity } : item;
        }),
      ]);
    }
  };

  //clearCart
  const clearCart = () => {
    setListCartFood([]);
    setListFreeSidesActive([]);
    navigate("/web-owner/tablepos");
  };

  //gHappyHour
  const gShowHappyHour = async () => {
    if (UserIdStore) {
      const req = await menuPosApi.gHappyHour(UserIdStore);
      if (Number(req?.status) === 1) {
        setInfoHappyhour(req?.data);
        setTimeExpiration(Number(req?.time_difference));
        setListFoodSell(req?.listFood ?? []);
        setShowHappyHour(true);
      } else {
        setInfoHappyhour(undefined);
        setTimeExpiration(0);
        setListFoodSell([]);
        setShowHappyHour(false);
      }
    }
  };
  useEffect(() => {
    if (timeExpiration)
      setTimeout(() => {
        if (timeExpiration > 0) {
          setTimeExpiration(timeExpiration - 1);
        } else {
          setInfoHappyhour(undefined);
          setTimeExpiration(0);
          setListFoodSell([]);
          setShowHappyHour(false);
        }
      }, 1000);
  }, [timeExpiration]);
  //submit order
  const submitOrderHandle = async () => {
    const tipAmount = Number(tipPrice) > 0 ? Number(tipPrice) : 0;
    const totalOrder = parseDolla(
      FormatDolla(
        totalPrice +
        feePrice +
        (Number(tipPrice) > 0 ? Number(tipPrice) : 0) +
        (totalPrice * tax) / 100 -
        discount
      )
    );
    const taxPrice = (totalPrice * tax) / 100;
    if (listCartFood.length === 0) {
      setMessageError("May I take your order!");
      setShowToastError(true);
    } else if (!table_id && paymentMethod === 0) {
      setMessageError("Please choose payment method!");
      setShowToastError(true);
    } else if (tipAmount < 0) {
      setMessageError("Tipping must be greater than 0!");
      setShowToastError(true);
    } else {
      // console.log({ totalOrder, taxPrice, staffId, tipAmount, listCartFood, statusOrderGo, table_id, order_id, voucher_id: infoVoucher?.voucher_id ? infoVoucher?.voucher_id : '0', voucher_code: infoVoucher?.voucher_code ? infoVoucher?.voucher_code : '', discount, customer_id: infoVoucher?.customer_id ? infoVoucher?.customer_id : '' });

      // console.log(order);
      if (!isOnline) {
        const food = listCartFood.map((item) => {
          return {
            id: "",
            owner_id: UserIdStore ? UserIdStore.toString() : "",
            custommer_id: "",
            table_id: table_id ? table_id.toString() : "",
            service_item_id: item?.food_id ?? "",
            price: item?.price.toString(),
            quantity: item?.quantity.toString(),
            notefood_id: item?.noteFood_id ?? "",
            side_id: item?.side_id ?? "",
            form_order: item?.statusOrderGo.toString(),
            note: item?.notechef ?? "",
            name: item?.foodName ?? "",
            imageCourse: item?.imageFood ?? "",
            donvitinh: "",
            table_name: NameTable ?? "",
            notefood_name: item?.noteFood_name ?? "",
            side_name: "",
            slcon: "",
            description: "",
            status: "0",
          };
        });
        var id = "OL" + Date.now();
        var order = {
          custommer_id: infoVoucher?.customer_id
            ? infoVoucher?.customer_id
            : "0",
          name: "",
          listFood: food,
          order_id: id,
          totalPrice: totalOrder.toString(),
          staff_name: "",
          tip_amount: tipAmount.toString(),
          tip_staff_id: staffId,
          discount: discount.toString(),
          taxPrice: taxPrice,
          table_id: table_id ? table_id.toString() : "",
          is_end: table_id ? 0 : 1,
          payment: 0,
          voucher_id: infoVoucher?.voucher_id ? infoVoucher?.voucher_id : "0",
          voucher_code: infoVoucher?.voucher_code
            ? infoVoucher?.voucher_code
            : "",
        };
        if (listOrderOffline && listOrderOffline.length > 0) {
          if (
            order_id &&
            listOrderOffline.some((item) => item?.order_id === order_id)
          ) {
            const newOrder = listOrderOffline.map((item) => {
              return item?.order_id === order_id
                ? { ...item, listFood: [...item?.listFood, ...food] }
                : item;
            });
            dispatch(orderSlice.setOrder([...newOrder]));
          } else {
            dispatch(orderSlice.setOrder([...listOrderOffline, order]));
          }
        } else {
          dispatch(orderSlice.setOrder([order]));
        }
        if (table_id) {
          if (paymentMethod === 1) {
            const NewListTable = TableOffline.map((item) => {
              return item.id === table_id
                ? { ...item, is_cash: true, status: "1" }
                : item;
            });
            dispatch(tableSlice.setTable(NewListTable));
          } else {
            const NewListTable = TableOffline.map((item) => {
              return item.id === table_id
                ? { ...item, is_visa: true, status: "1" }
                : item;
            });
            dispatch(tableSlice.setTable(NewListTable));
          }
        }
        setOrderId(id);
        PrintCard(id);
        if (table_id) {
          setShowSeeYourOrder(true);
        } else {
          dismisModalSeeYourOrder();
        }
      } else {
        if (paymentMethod === 2) {
          PaymentIntentClient();
        } else {
          setShowLoading(true);
          let listSideDish = ListFreeSidesActive.map((item) => {
            return {
              side_id: item.side_id,
              quantity: Number(item.quantity),
            };
          });
          const submitOrder = await menuPosApi.submitOrderPos(
            Number(UserIdStore),
            totalOrder,
            taxPrice,
            staffId,
            tipAmount,
            listCartFood,
            statusOrderGo,
            table_id,
            order_id,
            infoVoucher?.voucher_id ? infoVoucher?.voucher_id : "0",
            infoVoucher?.voucher_code ? infoVoucher?.voucher_code : "",
            discount,
            infoVoucher?.customer_id ? infoVoucher?.customer_id : "",
            paymentMethod,
            listSideDish,
            priceSide,
            staffJointId
          );
          if (Number(submitOrder?.status) === 1) {
            // setShowSeeYourOrder(true)
            setOrderId(submitOrder?.order_id);
            PrintCard(submitOrder?.order_id);
            if (table_id) {
              // setShowSeeYourOrder(true);
              socket.emit("PushNotificationPayMent", {
                table_id,
                ownerID: UserIdStore,
                customer_name: "",
                custommerId: 0,
                message: submitOrder?.message,
                order_id: submitOrder?.order_id,
              });
            } else {
              setOrderId("");
              dismisModalSeeYourOrder();
              navigate("/web-owner/tablepos", {
                state: { floorId },
              });
            }
            setShowLoading(false);
            // dismisModalSeeYourOrder()
            if (table_id) {
              if (paymentMethod === 1) {
                const NewListTable = TableOffline.map((item) => {
                  return item.id === table_id
                    ? { ...item, is_cash: true, status: "1" }
                    : item;
                });
                dispatch(tableSlice.setTable(NewListTable));
              } else {
                const NewListTable = TableOffline.map((item) => {
                  return item.id === table_id
                    ? { ...item, is_visa: true, status: "1" }
                    : item;
                });
                dispatch(tableSlice.setTable(NewListTable));
              }
              if (
                listOrderOffline &&
                submitOrder?.order_detail &&
                submitOrder?.order_detail !== undefined
              ) {
                if (
                  listOrderOffline.some(
                    (item) => item?.order_id === submitOrder?.order_id
                  )
                ) {
                  const newOd = listOrderOffline.map((item) => {
                    return item?.order_id === submitOrder?.order_id
                      ? submitOrder.order_detail
                      : item;
                  });
                  if (newOd && newOd !== undefined) {
                    dispatch(orderSlice.setOrder([...newOd]));
                  }
                } else {
                  dispatch(
                    orderSlice.setOrder([
                      ...listOrderOffline,
                      submitOrder?.order_detail,
                    ])
                  );
                }
              } else {
                dispatch(
                  orderSlice.setOrder(
                    submitOrder?.order_detail ? [submitOrder?.order_detail] : []
                  )
                );
              }
              navigate("/web-owner/tablepos", {
                state: { floorId },
              });
            }
          } else {
            setShowLoading(false);
            setMessageError("Order failed!");
            setShowToastError(true);
          }
        }
      }
    }
  };

  const CheckVouchePass = async () => {
    if (UserIdStore) {
      const req = await checkVoucher(voucherCode, UserIdStore);
      if (Number(req?.status) === 1) {
        setinfoVoucher(req?.data ?? {});
        setMessageErrorVoucher("");
      } else {
        setinfoVoucher({});
        setMessageErrorVoucher(req?.message ?? "");
      }
    }
  };

  useEffect(() => {
    if (!table_id) {
      setStatusOrderGo(2);
    } else {
      setStatusOrderGo(1);
    }
  }, [table_id]);
  useEffect(() => {
    var total = 0;
    var totalPriceSide = 0;
    // eslint-disable-next-line array-callback-return
    listCartFood.map((item) => {
      totalPriceSide += Number(item?.priceSide) * Number(item?.quantity);
      total +=
        Number(item?.price) * Number(item?.quantity) +
        Number(item?.priceSide) * Number(item?.quantity);
    });

    setTotalPrice(total);
    setPriceSide(totalPriceSide);
    if (infoVoucher?.id) {
      if (Number(infoVoucher?.type) === 1) {
        if (Number(infoVoucher?.is_gifvoucher) === 1) {
          setDiscount(
            parseDolla(
              FormatDolla(
                ((total +
                  feePrice +
                  (Number(tipPrice) > 0 ? Number(tipPrice) : 0) +
                  (total * tax) / 100) *
                  Number(infoVoucher?.discount_value)) /
                100
              )
            )
          );
        } else {
          setDiscount(
            parseDolla(
              FormatDolla((total * Number(infoVoucher?.discount_value)) / 100)
            )
          );
        }
      } else {
        var amount =
          total +
          feePrice +
          (Number(tipPrice) > 0 ? Number(tipPrice) : 0) +
          (total * tax) / 100;
        if (Number(infoVoucher?.is_gifvoucher) === 1) {
          setDiscount(
            parseDolla(
              FormatDolla(
                Number(infoVoucher?.discount_value) > amount
                  ? amount
                  : Number(infoVoucher?.discount_value)
              )
            )
          );
        } else {
          setDiscount(
            parseDolla(
              FormatDolla(
                Number(infoVoucher?.discount_value) > total
                  ? total
                  : Number(infoVoucher?.discount_value)
              )
            )
          );
        }
      }
    } else {
      setDiscount(0);
    }
  }, [listCartFood, infoVoucher]);
  useEffect(() => {
    if (searchFoodGroup !== "") {
      handleSearch();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [searchFoodGroup]);

  const checkShowVoucher = async (orderId: string) => {
    const req = await checkOrderHaveVoucher(orderId);
    if (Number(req?.status) === 1) {
      setIsShowVoucher(req?.data === 1);
    }
  };
  useEffect(() => {
    // Đăng ký sự kiện kiểm tra trạng thái kết nối mạng
    window.addEventListener("online", handleOnline);
    window.addEventListener("offline", handleOffline);

    // Hủy đăng ký sự kiện khi component bị hủy
    return () => {
      window.removeEventListener("online", handleOnline);
      window.removeEventListener("offline", handleOffline);
    };
  }, []);

  const handleOnline = () => {
    setIsOnline(true);
  };

  const handleOffline = () => {
    setIsOnline(false);
  };
  const PrintCard = async (orderid: string) => {
    try {
      // console.log(listCartFood);

      if (listPrinterName.length > 0) {
        listPrinterName.map(async (item, k) => {
          // if(listCartFood.map((cart, i) => item?.printType?.some(ty=>(ty===4 || ty ===cart.typeFood)))){
          if (
            item?.printType.some(
              (zItem) =>
                listCartFood.some((iItem) => iItem.typeFood === zItem) ||
                (!table_id && item?.printType?.some((ty) => ty === 4))
            )
          ) {
            const rawhtml = `
          <!DOCTYPE html>
          <html lang="en">

          <head>
          <meta charset="UTF-8">
          <meta name="viewport" content="width=device-width, initial-scale=1.0">
          <title>Hóa đơn</title>
          <style type="text/css">
            .name-food {
              font-weight: 600;
            }

            .quanlity-number {
              font-weight: 600;
            }

            p, h6 {
              margin-bottom: 0;
              margin-top: 0;
              line-height: normal;
            }

            .fw-normal{
              font-weight: 400;
            }
          </style>

          </head>

          <body>

          <div id="bill" style="width: 100%; padding-right: 40px; padding-top: 200px;">
            <h3 style="width: 100%; margin:  auto; text-align: center">Bill</h3>
            <div style="width: 100%;">
              <h5 class="" style="font-weight: 400; margin: 0; text-align: start;">${Number(table_id) > 0
                ? `${languageUI.tableName} ${NameTable} -`
                : ""
              } Order#${orderid}</h5>
            </div>

                ${listCartFood
                .map((cart, i) =>
                  item?.printType?.some(
                    (ty) => ty === cart.typeFood || (!table_id && ty === 4)
                  )
                    ? `
          <div style="display: table; width: 100%">
            <div style = "display: table-row; padding-top: 10px; width: 100%" >
              <div style="display: table-cell; width: auto; text-align: left; padding-right: 10px;  padding-top: 10px; ">
              <h6 class="fw-normal" style=" margin: 0; text-align: start;">${i + 1
                    }.${cart?.foodName}</h6>
              </div>
              <div style="display: table-cell; width: auto; text-align: right; padding-right: 10px;  padding-top: 10px; ">
                <h6 class="quanlity-number; margin-top: 10px !important">${NameTable ? "x" : ``
                    }${Number(cart?.quantity)}${NameTable ? "" : `x${cart?.price}$`
                    }</h6>
                <h6 style=" flex-shrink: 0; width: fit-content; white-space: nowrap; font-weight: 600;">${cart?.statusOrderGo !== 1 ? languageUI.takeAWay : ""
                    }</h6>
              </div>
            </div>
             <div style = "display: table-row; padding-top: 10px; width: 100%" >
              <div style="display: table-cell; width: auto; text-align: left; padding-right: 10px;  padding-top: 10px; ">
                ${cart?.noteFood_name
                      ? `<p style="font-size: 24px ; "  >-${cart?.noteFood_name}</p>`
                      : ""
                    }
                 ${cart?.notechef
                      ? `<p style="font-size: 24px ;" >-${cart?.notechef}</p>`
                      : ""
                    }
              </div>
            </div>
          </div>

         `
                    : ``
                )
                .join(" ")}

       ${ListFreeSidesActive.length > 0
                ? `
             <h6 class="fw-normal" style=" margin: 0; text-align: start;">${languageUI.sideDish
                }:</h6>
          ${ListFreeSidesActive.map(
                  (item) =>
                    `
         <p style="font-size: 24px ;" >-${item.name} x${item.quantity}</p>
            `
                ).join(" ")}
    `
                : ""
              }
            ${NameTable
                ? ""
                : `
        <div style="width: 100%; ">
  <h6 style="font-weight: 400; margin: 0; text-align: center;">- - - - - - - - - -</h6>
</div>

<div style="display: table; width: 100%;  padding-right: 40px;">
  <div style="display: table-row; padding-top: 0px; width: 100%">
    <div style="display: table-cell; width: auto; text-align: left; padding-right: 10px;  padding-top: 0px">
      <p style="font-size: 24px ; ">${languageUI.subTotoal}:</p>
    </div>
    <div style="display: table-cell; text-align: right; flex-shrink: 0; width: fit-content;padding-top:0px;">
      <p style="font-size: 24px ; ">$${Number(totalPrice) ? FormatDolla(totalPrice) : 0
                }
      </p>
    </div>
  </div>
</div>
<div style="display: table; width: 100%">

  <div style="display: table-row; padding-top: 0px; width: 100%">
    <div style="display: table-cell; width: auto; text-align: left; padding-right: 10px;  padding-top: 0px">
      <p style="font-size: 24px ; ">${languageUI.tax}:</p>
    </div>
    <div style="display: table-cell; text-align: right; flex-shrink: 0; width: fit-content;padding-top:0px;">
      <p style="font-size: 24px ">$${Number(tax) ? FormatDolla(Number((tax / 100) * totalPrice)) : "0 "
                }</p>
    </div>
  </div>
</div>
<div style="display: table; width: 100%">

  <div style="display: table-row; padding-top: 0px; width: 100%">
    <div style="display: table-cell; width: auto; text-align: left; padding-right: 10px;  padding-top: 0px">
      <p style="font-size: 24px ; ">${languageUI.discount}:</p>
    </div>
    <div style="display: table-cell; text-align: right; flex-shrink: 0; width: fit-content;padding-top:0px;">
      <p style="font-size: 24px ; ">$${FormatDolla(Number(discount))}</p>
    </div>
  </div>
</div>
<div style="display: table; width: 100%">
  <div style="display: table-row; padding-top: 0px; width: 100%">
    <div style="display: table-cell; width: auto; text-align: left; padding-right: 10px;  padding-top: 0px">
      <p style="font-size: 24px ; ">Tip:</p>
    </div>
    <div style="display: table-cell; text-align: right; flex-shrink: 0; width: fit-content;padding-top:0px;">
      <p style="font-size: 24px ; ">$${tipPrice ? FormatDolla(Number(tipPrice)) : "0"
                }</p>
    </div>
  </div>
</div>
<div style="display: table; width: 100%">
  <div style="display: table-row; padding-top: 0px; width: 100%">
    <div style="display: table-cell; width: auto; text-align: left; padding-right: 10px;  padding-top: 0px">
      <p style="font-size: 24px ; ">${languageUI.total}</p>
    </div>
    <div style="display: table-cell; text-align: right; flex-shrink: 0; width: fit-content;padding-top:0px;">
      <p style="font-size: 24px ; ">$${FormatDolla(
                  Number(
                    listCartFood.length > 0
                      ? FormatDolla(
                        totalPrice +
                        (Number(tipPrice) > 0 ? Number(tipPrice) : 0) +
                        (totalPrice * tax) / 100 -
                        discount
                      )
                      : 0
                  )
                )}</p>
    </div>
  </div>
</div>
        `
              }
        ${NameTable
                ? ""
                : `<div style="width: 100%;">
        <div style="width: 100%;">
        <h6 style="font-weight: 400; margin: 0; text-align: center;">- - - - - - - - - -</h6>
    </div>
              <h5 class="" style="font-weight: 400; margin: 0; text-align: center;">${addressStore}</h5>
              <h5 class="" style="font-weight: 400; margin: 0; text-align: center;">${phoneOwnerStore}</h5>
            </div>
            <h3 style="width: 100%; margin: 0 auto; vertical-align: middle; text-align: center;">Thank you!</h3>
          </div>`
              }
            
          </body >

          </html >
  `;
            const req = await PrintOrderPage(
              UserIdStore ?? 0,
              item.printer_name,
              rawhtml.toString()
            );
            if (!req?.status) {
              // setMessageError(req?.message ?? ("Error printing on " + item.printer_name))
              // setShowToastError(true)
              // toast.warning(req?.message ?? ("Error printing on " + item.printer_name))
            }
          }
        });
      } else {
        setMessageError("Please configure the printer in settings!");
        setShowToastError(true);
      }

      // await printJS({

      //   printable: rawhtml,
      //   targetStyles: ["*"],
      //   targetStyle: ["*"],
      //   frameId: "cardprint",
      //   type: "raw-html",
      //   showModal: false,
      //   css: ["./Styles.css"],
      //   style: ".abc {font-size: 30px; color: red;}",
      //   header: rawhtml,
      //   onError: async (error: any) => {
      //     console.error("Error Printing: ", error);
      //   },
      //   // silent: false,3
      //   onPrintDialogClose: async () => {
      //     console.info("Print Window Closed");

      //   }
      // });
      // setTimeout(() => {
      //   const arr = listCartFood ?? [];
      //   printCardInfo(arr, arr.length - 1, arr[arr.length - 1]?.food_id, arr[arr.length - 2]?.food_id ?? '', arr[arr.length - 1], Number(table_id) > 0 ? NameTable : '', orderid ?? '', '')
      // }, 1000);
    } catch (error) {
      console.error("Error:", error);
    }
  };
  const pushReloadHappyHour = useCallback(async () => {
    // await socket.on("pushReloadHappyHour" + UserIdStore, (data: any) => {
    var pathName = window.location.pathname;
    if (pathName.search("/web-owner/posmenu") > -1) {
      gShowHappyHour();
    }
    // });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [socket]);
  useEffect(() => {
    if (!UserIdStore) {
      return;
    }
    const eventName = "pushReloadHappyHour" + UserIdStore;

    socket.on(eventName, pushReloadHappyHour);

    return () => {
      socket.off(eventName, pushReloadHappyHour);
    };
  }, [UserIdStore]);
  useEffect(() => {
    if (UserIdStore) {
      gListPrinter();
      gListCategory(UserIdStore.toString());
      getListMenuFood(Number(UserIdStore));
      gConfig();
      gListStaffTip();
      gShowHappyHour();
      if (order_id) {
        checkShowVoucher(order_id);
      }
      if (!table_id) {
        setPaymentMethod(0);
      } else {
        setPaymentMethod(0);
      }
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (!table_id && feeStripe && paymentMethod === 2) {
      setFeePrice(
        ((totalPrice + (Number(tipPrice) > 0 ? Number(tipPrice) : 0)) * 3) / 100
      );
    } else {
      setFeePrice(0);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [totalPrice, tipPrice, paymentMethod]);
  ////////////////
  //tạo hóa đơn thanh toán
  async function PaymentIntentClient() {
    setShowModalReader(true);
    const totalOrder = parseDolla(
      FormatDolla(
        totalPrice +
        feePrice +
        (Number(tipPrice) > 0 ? Number(tipPrice) : 0) +
        (totalPrice * tax) / 100 -
        discount
      )
    );
    // console.log(Number(FormatDolla(totalOrder)) * 100);

    const req = await CreatePaymentIntent(
      UserIdStore ?? 0,
      totalOrder * 100,
      pos_id
    );
    if (Number(req?.status) === 1) {
      // return req?.secret
      // setShowModalReader(false)
      setpaymentIntentId(req?.paymentIntents);
      PaymentWithReader(req?.paymentIntents ?? "");
    } else {
      setShowModalReader(false);
      setMessageErrPayment(req.message ?? "");
      setShowModalPaymentErr(true);
      return "";
    }
  }

  //tiến hàng chờ wuetj thẻ
  const PaymentWithReader = async (paymentIntents: string) => {
    const tipAmount = Number(tipPrice) > 0 ? Number(tipPrice) : 0;
    const totalOrder = parseDolla(
      FormatDolla(
        totalPrice +
        feePrice +
        (Number(tipPrice) > 0 ? Number(tipPrice) : 0) +
        (totalPrice * tax) / 100 -
        discount
      )
    );
    const taxPrice = (totalPrice * tax) / 100;

    const req = await PaymentIntentStripe(
      UserIdStore ?? 0,
      paymentIntents,
      pos_id
    );
    if (Number(req?.status) === 1) {
      let listSideDish = ListFreeSidesActive.map((item) => {
        return {
          side_id: item.side_id,
          quantity: Number(item.quantity),
        };
      });
      const submitOrder = await menuPosApi.submitOrderPos(
        Number(UserIdStore),
        totalOrder,
        taxPrice,
        staffId,
        tipAmount,
        listCartFood,
        statusOrderGo,
        table_id,
        order_id,
        infoVoucher?.voucher_id ? infoVoucher?.voucher_id : "0",
        infoVoucher?.voucher_code ? infoVoucher?.voucher_code : "",
        discount,
        infoVoucher?.customer_id ? infoVoucher?.customer_id : "",
        paymentMethod,
        listSideDish,
        priceSide,
        staffJointId
      );
      if (Number(submitOrder?.status) === 1) {
        // setShowSeeYourOrder(true)
        setOrderId(submitOrder?.order_id);
        setShowLoading(false);
        dismisModalReader();
        PrintCard(submitOrder?.order_id);
        if (table_id) {
          socket.emit("PushNotificationPayMent", {
            table_id,
            ownerID: UserIdStore,
            customer_name: "",
            custommerId: 0,
            message: submitOrder?.message,
            order_id: order_id,
          });
          // setShowSeeYourOrder(true);
        } else {
          setOrderId("");
          dismisModalSeeYourOrder();
          navigate("/web-owner/tablepos");
        }
        setShowLoading(false);
        // dismisModalSeeYourOrder()
        if (table_id) {
          if (paymentMethod === 1) {
            const NewListTable = TableOffline.map((item) => {
              return item.id === table_id
                ? { ...item, is_cash: true, status: "1" }
                : item;
            });
            dispatch(tableSlice.setTable(NewListTable));
          } else {
            const NewListTable = TableOffline.map((item) => {
              return item.id === table_id
                ? { ...item, is_visa: true, status: "1" }
                : item;
            });
            dispatch(tableSlice.setTable(NewListTable));
          }
          if (
            listOrderOffline &&
            submitOrder?.order_detail &&
            submitOrder?.order_detail !== undefined
          ) {
            if (
              listOrderOffline.some(
                (item) => item?.order_id === submitOrder?.order_id
              )
            ) {
              const newOd = listOrderOffline.map((item) => {
                return item?.order_id === submitOrder?.order_id
                  ? submitOrder.order_detail
                  : item;
              });
              if (newOd && newOd !== undefined) {
                dispatch(orderSlice.setOrder([...newOd]));
              }
            } else {
              dispatch(
                orderSlice.setOrder([
                  ...listOrderOffline,
                  submitOrder?.order_detail,
                ])
              );
            }
          } else {
            dispatch(
              orderSlice.setOrder(
                submitOrder?.order_detail ? [submitOrder?.order_detail] : []
              )
            );
          }
          navigate("/web-owner/tablepos", {
            state: { floorId },
          });
        }
      } else {
        dismisModalReader();
        setShowLoading(false);
        setMessageError("Order failed!");
        setShowToastError(true);
      }
    } else {
      setShowModalReader(false);
      if (!req?.message?.includes("canceled")) {
        setMessageErrPayment(req.message ?? "");
        setShowModalPaymentErr(true);
      }

      return "";
    }
  };

  const CancelPayment = async () => {
    if (paymentIntentId !== "") {
      const req = await CancelPaymentIntent(
        UserIdStore ?? 0,
        paymentIntentId,
        pos_id
      );
      if (Number(req?.status) === 1) {
        setpaymentIntentId("");
        dismisModalReader();
      } else {
        dismisModalReader();
        setMessageErrPayment(req.message ?? "");
        setShowModalPaymentErr(true);
        return "";
      }
    }
  };

  //cuộn lên đầu trang
  const scrollToTop = (id: string) => {
    const element = document.getElementById(id);
    // console.log(element);
    if (element) {
      element.scrollIntoView({ behavior: "smooth" });
    }
  };

  const gDiscountHappyHourByFood = (food_id: string) => {
    return Number(infoHappyhour?.status) === 0
      ? 1
      : listFoodSell.filter((item) => Number(item.id) === Number(food_id))[0]
        ?.discount ?? 0;
  };
  useEffect(() => {
    let total = 0;
    listCartFood.map((item) => (total += Number(item.maxSide) * item.quantity));
    setMaxSideFoodOrder(total);
  }, [listCartFood]);

  return (
    <div>
      <ModalReader
        show={showModalReader}
        handleClose={CancelPayment}
        amount={parseDolla(
          FormatDolla(
            totalPrice +
            feePrice +
            (Number(tipPrice) > 0 ? Number(tipPrice) : 0) +
            (totalPrice * tax) / 100 -
            discount
          )
        )}
      />
      <ModalPaymentError
        show={showModalPaymentErr}
        handleClose={dismisModalPaymentErr}
        content={messageErrPayment}
      />
      <ModalToast
        show={showToastError}
        handleClose={() => setShowToastError(false)}
        content={messageError}
      />
      <div className="h-70px mt-header bg-white px-4 d-flex align-items-center border-bottom container-fluid custom-header-search w-100">
        <div className="d-flex align-items-center gap-3 items-center border-right h-100 pe-2 flex-shink-0">
          <p className="fitlter-pos-menu mb-0 d-flex align-items-center">
            {languageUI.filter}
          </p>
          <Select
            className="react-select react-select-container w-300px"
            classNamePrefix="react-select"
            defaultValue={[""]}
            placeholder={languageUI.chooseFoodGroup}
            isClearable={true}
            options={listCategory.map((item) => {
              return {
                value: item.id,
                label: item.name,
              };
            })}
            onChange={(e: any) => handleOnchageFoodGroup(e)}
          />

          <div className="d-flex gap-2 align-items-center">
            {arrayNote.map((items, i) => (
              <button
                key={i}
                className={`flex-shink-0 rounded-4  h-40px  d-flex justify-content-center align-items-center bg-white text-green px-2 ${statusOrderGo === items.id
                    ? "bg-primary text-white  border-1px-primary"
                    : "border"
                  }`}
                // onClick={() => {
                //   setStatusOrderGo(items.id);
                //   if (statusOrderGo === 2 && !table_id) {
                //     navigate("/web-owner/tablepos");
                //   }
                // }}
                disabled={statusOrderGo === items.id}
              >
                <p
                  className={`m-0 text-choose-status-order ${statusOrderGo === items.id ? "text-white" : ""
                    }`}
                >
                  {items.title}
                </p>
              </button>
            ))}
          </div>
        </div>
        <div className=" w-100 d-flex align-items-center pl-50px">
          <div className="d-flex align-items-center gap-2">
            <img src={iconSearchGreen} alt="" />
            <input
              type="text"
              className="focus-visible-none w-100 input-find-dishes"
              placeholder={languageUI.findDishes}
              value={searchFood}
              onChange={(e) => setSearchFood(e?.target.value)}
              onKeyPress={(event) => {
                if (event.key === "Enter") {
                  handleSearch();
                }
              }}
            />
          </div>

          <div
            className="py-1 px-2 text-choose-status-order radius-8px bg-primary text-white d-flex justify-content-center align-items-center"
            onClick={() => {
              setSearchFood("");
              handleClearMenu();
            }}
          >
            {languageUI.clear}
          </div>
          <button
            className={`ml-5 ${showImgFood ? "btn-show-img" : "btn-hidden-img"
              } `}
            onClick={() => setShowImgFood(!showImgFood)}
          >
            {showImgFood ? "Show Image" : "Hide Image"}
          </button>
        </div>
      </div>
      <div className="w-100 d-flex flex-nowrap justify-content-between page-menu-post-table overflow-hidden">
        <div className="w-100  position-relative d-flex flex-column">
          <div className="w-100 bg-white box-option-choose-menu">
            {ListMenuItems.map((group, i) => {
              if (i > 7) {
                return;
              } else {
                return (
                  <>
                    {i === 0 && (
                      <div
                        className={` w-100 h-40px  border rounded-5 px-3 w-fit d-flex justify-content-center align-items-center bg-primary text-white`}
                        onClick={() => {
                          setSelectOptionMenu("all");
                          scrollToTop(`group${group?.foodgroup_id}`);
                        }}
                      >
                        <p className={`text-title-bill mb-0 text-white`}>All</p>
                      </div>
                    )}
                    <div
                      key={"gr" + i}
                      className={`h-40px  w-100  rounded-5 px-3 w-fit d-flex justify-content-center align-items-center `}
                      style={{ backgroundColor: `${group?.color}` }}
                      onClick={() => {
                        setSelectOptionMenu(group?.foodgroup_id ?? "");
                        scrollToTop(`group${group?.foodgroup_id}`);
                      }}
                    >
                      <p
                        className={`text-title-bill text-white mb-0 text-truncate`}
                      >
                        {group.foodgroup_name}
                      </p>
                    </div>
                  </>
                );
              }
            })}
            {ListMenuItems.length > 7 && (
              <div
                className={` w-100 h-40px bg-white border rounded-5 px-3 w-fit d-flex justify-content-center align-items-center  text-white`}
                onClick={() => setShowModalViewMoreGroup(true)}
              >
                <p className={`text-title-bill mb-0 text-4a4a6a`}>
                  View more +
                </p>
              </div>
            )}
          </div>
          <div className="custom-h-content-food  overflow-y-scroll p-3">
            {showHappyHour && Number(infoHappyhour?.status) === 1 && (
              <div className="w-100 ">
                <p className="text-title-bill mb-0">Happy hour</p>
                <div className="w-100 p-3 d-flex flex-wrap gap-2 ">
                  {listFoodSell &&
                    listFoodSell.map((food, j) => (
                      <FoodItems
                        showImgFood={showImgFood}
                        key={"fooditem" + j}
                        food_id={food?.id}
                        foodName={food?.name}
                        image={`${targetupload}${food.imageCourse}`}
                        price={
                          Number(food.price) * (1 - Number(food.discount) / 100)
                        }
                        side_id={food?.side_id}
                        addToCart={addToCart}
                        priceOld={Number(food.price)}
                        showHappyHour={1}
                      />
                    ))}
                </div>
              </div>
            )}
            {ListMenuItems.map((group, i) => (
              <div
                className="w-100 "
                key={`mn${i}`}
                id={`group${group?.foodgroup_id}`}
              >
                <p className="text-title-bill mb-0">{group.foodgroup_name}</p>
                <div className="w-100 p-3 d-flex flex-wrap gap-2 ">
                  {group?.list_menu &&
                    group?.list_menu.map((food, j) => (
                      <FoodItems
                        showImgFood={showImgFood}
                        tableId={NameTable}
                        key={"fooditem" + j}
                        food_id={food?.menu_id}
                        foodName={food?.menu_name}
                        image={`${targetupload}${food.imageCourse}`}
                        price={
                          showHappyHour
                            ? Number(infoHappyhour?.status) === 0
                              ? Number(food.price) *
                              (1 - Number(infoHappyhour?.discount) / 100)
                              : Number(food.price) *
                              (1 -
                                Number(
                                  gDiscountHappyHourByFood(food.menu_id ?? "")
                                ) /
                                100)
                            : Number(food.price)
                        }
                        priceOld={Number(food.price)}
                        side_id={food?.side_id}
                        addToCart={addToCart}
                        showHappyHour={Number(
                          gDiscountHappyHourByFood(food.menu_id ?? "")
                        )}
                      />
                    ))}
                </div>
              </div>
            ))}
          </div>
        </div>

        <div className="tab-order-food d-flex flex-column justify-content-between overflow-hidden">
          {showDelevery ? (
            <div className="h-100 w-100 d-flex flex-column justify-content-between pb-4">
              <div className="pt-10 px-3">
                <h3 className="text-4a4a6a text-center font-600">
                  {languageUI.delivery}
                </h3>
                <div className="d-flex flex-column gap-2">
                  {listDelevery.map((item, index) => (
                    // if(index > 10)
                    <div
                      className={`d-flex gap-2 align-items-center px-2 ${paymentMethod === item.id ? "border-FF7B2C" : "border"
                        } py-1 rounded-4`}
                      key={`Develivery-${item.id}`}
                      onClick={() => {
                        setPaymentMethod(item.id);
                        setShowDelevery(false);
                      }}
                    >
                      <div className="box-img-develivery">
                        <img src={item.img} alt="" className="w-100" />
                      </div>
                      <h4 className="text-4a4a6a text-center m-0">
                        {item.name}
                      </h4>
                    </div>
                  ))}
                </div>
              </div>
              {/* <div>
                <div className="px-5 w-100 mt-2">
                  <button
                    className="d-flex w-100 py-2 rounded-5 border-none justify-content-center align-items-center gap-2 bg-primary text-white "
                    onClick={() => setShowDelevery(false)}
                  >
                    <p className="m-0 text-16px font-bold">Submit</p>
                  </button>
                </div>
              </div> */}
            </div>
          ) : (
            <div className="h-100 w-100 d-flex flex-column justify-content-between">
              <div className="px-3 pt-3 border-bottom position-relative">
                {/* <div className="w-100 d-flex rounded-12px overflow-hidden h-50px">
              <div className="w-100 h-100 bg-12B064 d-flex align-items-center justify-content-center">
                <p className="text-center name-brand-pos-menu mb-0">{nameStore}</p>
              </div>
            </div> */}
                <div className="d-flex justify-content-between align-items-center mt-2 gap-2">
                  <div>
                    {table_id && (
                      <>
                        <div className="text-center w-80px mb-1 font-bold">
                          {`${languageUI.Guest}: ${guestNumber}`}
                        </div>
                        <div className="bg-FF7B2C w-80px d-flex align-items-center justify-content-center flex-shink-0 h-40px rounded-5 shadow-button-default">
                          <p className="text-center name-table-pos-menu mb-0">
                            {NameTable}
                          </p>
                        </div>
                      </>
                    )}
                  </div>

                  <p className="mb-0 order-number-pos-menu text-truncate">
                    {order_id ? "Order #" + order_id : ""}
                  </p>
                  <div>
                    <div
                      className="d-flex align-items-center flex-shink-0 justify-content-end"
                      onClick={clearCart}
                    >
                      <p className="clear-food-ordeer mb-0 me-1">
                        {languageUI.clearAll}
                      </p>
                      <img src={trashRed} alt="" />
                    </div>
                    <button
                      className="py-1 px-4 rounded-4 bg-primary text-white font-bold border-none mt-1 shadow-button-default"
                      disabled={maxSideFoodOrder < 1}
                      onClick={() => setShowModalAddSideDish(true)}
                    >
                      {languageUI.sideDish}({maxSideFoodOrder})
                    </button>
                  </div>
                </div>
                <div
                  className={`list-food-order mt-2 overflow-y-scroll d-flex flex-column gap-2 pb-3 scroll-hidden position-relative ${showMoreListOrder === false && table_id
                      ? "h-cacl-content-pos-menu-323"
                      : showMoreListOrder === true && table_id
                        ? "calc-100vh-482px"
                        : showMoreListOrder === false && !table_id
                          ? "h-100vh-450px"
                          : "h-cacl-content-pos-menu"
                    }`}
                >
                  {listCartFood.map((cart, i) => (
                    <FoodOrderItems
                      key={"cart" + i}
                      foodName={cart?.foodName}
                      imageFood={cart?.imageFood}
                      noteFood={cart?.noteFood_name}
                      price={cart?.price}
                      quantity={cart?.quantity}
                      food_id={cart?.food_id}
                      noteFood_id={cart?.noteFood_id}
                      index={i}
                      changeQuantity={changeQuantity}
                      noteChef={cart?.notechef}
                    />
                  ))}
                  {ListFreeSidesActive.length > 0 ? (
                    <div>
                      <p className="font-bold text-4a4a6a m-0">Side Dish</p>
                      <div className="d-flex flex-column text-base">
                        {ListFreeSidesActive.map((item) => (
                          <p className="m-0">
                            {item.name} x{item.quantity}
                          </p>
                        ))}
                      </div>
                    </div>
                  ) : (
                    ""
                  )}
                </div>
                <div className="absolute-center">
                  <div
                    className="open-list-food-order"
                    onClick={() => setShowMoreListOrder(!showMoreListOrder)}
                  >
                    <div className="d-flex align-items-center custom-text-view-more">
                      <img
                        src={upScroll}
                        alt=""
                        className={`h-16px w-16px me-1 ${showMoreListOrder === false ? "" : "rorate-180"
                          }`}
                      />
                      {showMoreListOrder === true
                        ? languageUI.viewMore
                        : languageUI.viewLess}
                    </div>
                  </div>
                </div>
              </div>
              <div>
                <div
                  className={`px-3 pt-3 d-flex flex-column gap-1 border-bottom pb-1 ${showMoreListOrder === false ? "d-none" : ""
                    }`}
                >
                  <div className="d-flex justify-content-between mt-2">
                    <p className="mb-0 text-title-bill h-fit">
                      {languageUI.subTotoal}
                    </p>
                    <p className="mb-0 text-price-title-bill">
                      ${FormatDolla(totalPrice)}
                    </p>
                  </div>
                  <div className="d-flex justify-content-between">
                    <p className="mb-0 text-title-bill h-fit">
                      {languageUI.tax}
                    </p>
                    <p className="mb-0 text-price-title-bill">
                      {FormatDolla((tax / 100) * totalPrice)}
                    </p>
                  </div>
                  {!table_id && (
                    <>
                      <p className="mb-0 text-title-bill h-fit">
                        {languageUI.enterTip}
                      </p>
                      <div className="d-flex justify-content-between align-items-center">
                        {/* <div className="tip-employee-pos-menu px-2 w-48" onClick={() => { setShowModalChooseNameTip(true) }}>
                      {staffName != '' ? (

                        <p className="mb-0 name-employee-tip-pos-menu text-truncate text-14px" >{staffName}</p>

                      ) : (
                        <p className="mb-0 text-choose-employee text-truncate" >Employee</p>

                      )}
                      <img src={chevronDown} alt="" />
                    </div> */}
                        <InputGroup className="w-100 h-42px radius-8px overflow-hidden border border-input-bootstrap-none">
                          <InputGroup.Text>$</InputGroup.Text>
                          <Form.Control
                            aria-label="Amount (to the nearest dollar)"
                            type="number"
                            className="text-14px"
                            placeholder={languageUI.enterTip}
                            value={tipPrice}
                            onChange={(e) =>
                              setTipPrice(
                                Number(e?.target?.value) === 0
                                  ? undefined
                                  : Number(e?.target?.value)
                              )
                            }
                          />
                        </InputGroup>
                      </div>
                    </>
                  )}
                  {!table_id && isShowVoucher && (
                    <>
                      <p className="text-table-pay-bill-2 mb-0">
                        {languageUI.discount}
                      </p>
                      <div className="custom-typing-discount px-2">
                        <input
                          type="text"
                          className="input-discount-pos-menu focus-visible-none w-100"
                          placeholder={languageUI.discount}
                          value={voucherCode}
                          onChange={(e) => setVoucherCode(e.target.value)}
                          onKeyPress={(event) => {
                            if (event.key === "Enter") {
                              CheckVouchePass();
                            }
                          }}
                        />
                        {/* <div className={`custom-icon-typing-discount ${Number(infoVoucher?.type) === 2 ? "text-FF7B2C border-FF7B2C" : ""}`}>
                          <AttachMoneySharpIcon />
                        </div>
                        <div className={`custom-icon-typing-discount ${Number(infoVoucher?.type) === 1 ? "text-FF7B2C border-FF7B2C" : ""}`}>
                          <PercentSharpIcon />
                        </div> */}
                        <div
                          className="btn-apply-discount-posmenu flex-shrink-0"
                          onClick={() => CheckVouchePass()}
                        >
                          {languageUI.apply}
                        </div>
                      </div>
                      {messageErrorVoucher && (
                        <div className="text-danger text-italic text-center">
                          {messageErrorVoucher}
                        </div>
                      )}
                    </>
                  )}
                </div>

                {!table_id && statusOrderGo === 2 && (
                  <div className="px-3 border-bottom py-2">
                    <p className="text-table-pay-bill-2 mb-0">
                      {languageUI.payment}
                    </p>

                    <Form>
                      <div className="d-flex align-items-center gap-4 mt-1">
                        {/* <Form.Check
                          inline
                          label={languageUI.cash}
                          name="group1"
                          type={"radio"}
                          id={`paymentcash`}
                          className="d-flex align-items-start p-0"
                          checked={paymentMethod === 1}
                          onClick={() => setPaymentMethod(1)}
                        />
                        <Form.Check
                          inline
                          label={"Visa/Master"}
                          name="group1"
                          type={"radio"}
                          id={`paymentcard`}
                          className="d-flex align-items-start p-0"
                          checked={paymentMethod === 2}
                          onClick={() => setPaymentMethod(2)}
                        ></Form.Check> */}
                        <div
                          className={`${paymentMethod === 1
                              ? "btn-payment-method-menu-2"
                              : "btn-payment-method-menu"
                            } `}
                          onClick={() => setPaymentMethod(1)}
                        >
                          <MonetizationOnRoundedIcon />
                          {languageUI.cash}
                        </div>
                        <div
                          className={`${paymentMethod === 2
                              ? "btn-payment-method-menu-2"
                              : "btn-payment-method-menu"
                            }`}
                          onClick={() => setPaymentMethod(2)}
                        >
                          <AddCardRoundedIcon />
                          {languageUI.card}
                        </div>
                      </div>
                    </Form>
                    {listDelevery.filter((item) => item.id === paymentMethod)
                      .length > 0 ? (
                      listDelevery.map(
                        (item) =>
                          item.id === paymentMethod && (
                            <div
                              className="d-flex gap-2 align-items-center mt-1 "
                              onClick={() => setShowDelevery(true)}
                            >
                              <p className="text-table-pay-bill-2 mb-0 flex-shrink-0">
                                {languageUI.delivery}:
                              </p>
                              <div className="btn-payment-delivery">
                                <div
                                  className="w-40px d-flex align-items-center"
                                  style={{ height: "35px" }}
                                >
                                  <img
                                    style={{ maxHeight: "35px" }}
                                    src={item.img}
                                    alt=""
                                    className="w-100 "
                                  />
                                </div>
                                <p className="text-table-pay-bill-2 mb-0">
                                  {item.name}
                                </p>
                              </div>
                            </div>
                          )
                      )
                    ) : (
                      <div className="px-5 w-100 mt-2">
                        <button
                          className="d-flex w-100 py-1 rounded-5 border-none justify-content-center align-items-center gap-2 bg-primary text-white "
                          onClick={() => setShowDelevery(true)}
                        >
                          <LocalShippingIcon className="text-white" />
                          <p className="m-0 text-16px font-bold">
                            {languageUI.delivery}
                          </p>
                        </button>
                      </div>
                    )}
                  </div>
                )}
                <div className="px-3">
                  <div className="d-flex justify-content-between align-items-end">
                    <p className="mb-0 text-title-bill h-fit">
                      {languageUI.discount}
                    </p>
                    <div className="text-price-title-bill d-flex align-items-end gap-2px">
                      <p className="mb-0 ">{discount}</p>
                      <p className="mb-1">$</p>
                    </div>
                  </div>
                  {/* <div className="d-flex justify-content-between">
                <p className="mb-0 text-title-bill h-fit">{staffName != '' ? ('TIP for ' + staffName) : "Tip"}</p>
                <div className="text-price-title-bill d-flex align-items-center gap-2px">
                  <p className="mb-0 ">{Number(tipPrice) > 0 ? FormatDolla(tipPrice) : 0}</p>
                  <p className="">$</p>
                </div>
              </div> */}
                  {!table_id && feeStripe && paymentMethod === 2 && (
                    <div className="d-flex justify-content-between align-items-end">
                      <ClickAwayListener onClickAway={handleTooltipClose}>
                        <div className="tooltip-payment-web-checkin">
                          <Tooltip
                            className=""
                            PopperProps={{
                              disablePortal: true,
                            }}
                            onClose={handleTooltipClose}
                            open={openToolTip}
                            disableFocusListener
                            disableHoverListener
                            disableTouchListener
                            placement="right"
                            title={languageUI.theConvenienceFee}
                          >
                            <div
                              className="d-flex gap-1 align-items-end w-fit"
                              onClick={() => setOpenToolTip(true)}
                            >
                              <div className="text-title-bill">
                                {languageUI.ConvenienceFee}
                              </div>
                              <HelpOutlineSharpIcon className="" />
                            </div>
                          </Tooltip>
                        </div>
                      </ClickAwayListener>
                      <div className="text-price-title-bill d-flex align-items-end gap-2px">
                        <div className="mb-0">{FormatDolla(feePrice)}</div>
                        <div className="mb-1">$</div>
                      </div>
                    </div>
                  )}
                  <div className="d-flex justify-content-between align-items-end">
                    <p className="mb-0 text-title-bill h-fit ">
                      {languageUI.total}
                    </p>
                    <div className="text-price-title-bill d-flex align-items-end gap-2px text-FF7B2C h-fit">
                      <p className="mb-0 ">
                        {listCartFood.length > 0
                          ? FormatDolla(
                            totalPrice +
                            feePrice +
                            (Number(tipPrice) > 0 ? Number(tipPrice) : 0) +
                            (totalPrice * tax) / 100 -
                            discount
                          )
                          : 0}
                      </p>
                      <p className="mb-1">$</p>
                    </div>
                  </div>
                  <div
                    className="btn-order-menu-pos mb-3 shadow-button-default"
                    onClick={() => {
                      submitOrderHandle();
                    }}
                  >
                    <p className="mb-0">Order</p>
                  </div>
                </div>
              </div>
            </div>
          )}
        </div>
      </div>
      <SeeYourOrder
        handleClose={dismisModalSeeYourOrder}
        show={showSeeYourOrder}
        HandleSeeOrder={HandleSeeOrder}
      />
      <ModalSelectEmployeeTip
        heightModal={600}
        listStaff={listStaff}
        staffId={staffId}
        setStaffId={(id: string) => setStaffId(id)}
        setStaffName={(name: string) => setStaffName(name)}
        handleClose={dismisModalChooseNameTip}
        show={showModalChooseNameTip}
      />
      <div className="d-none">
        <div className="d-flex flex-column w-100 pe-2" id="print-bill-posmenu">
          <div className="border-bottom-black position-relative line-height-normal">
            <div>
              <p className="mb-0 id-order-customer-print text-black line-height-normal">
                {nameStore}
              </p>
              <div className="d-flex gap-1 align-items-end">
                <p className="mb-0 id-order-customer-print text-black line-height-normal">
                  Add:
                </p>
                <p className="mb-0 text-items-order-print text-black">
                  {addressStore}
                </p>
              </div>
              {/* <div className="d-flex gap-1 align-items-end">
                <p className="mb-0 id-order-customer-print  text-black line-height-normal">Phone:</p>
                <p className="mb-0 text-items-order-print text-black">{phoneOwnerStore}</p>
              </div> */}
            </div>
            <div className="text-black">
              {Number(table_id) > 0 && (
                <div className="title-infor-bill-customer-print line-height-normal">
                  {NameTable}
                </div>
              )}
              <div className="d-flex align-items-center ">
                <p className="mb-0 order-customer-print text-black line-height-normal">
                  Order
                </p>
                <p className="mb-0 id-order-customer-print text-black line-height-normal">
                  #{order_id}
                </p>
              </div>
            </div>
            <div className={` border-bottom-black h-1 w-100`}></div>

            <div className={`d-flex flex-column`}>
              {listCartFood.map((cart, i) => (
                <OrderListITemPrintPosMenu
                  key={"cart" + i}
                  note_bill={cart?.notechef}
                  name={cart?.foodName}
                  notefood_name={cart?.noteFood_name}
                  form_order={`${statusOrderGo === 1 ? "1" : "2"}`}
                  price={cart?.price}
                  quantity={cart?.quantity}
                />
              ))}
              {/* <OrderListITemPrint orderInfo={listFood} status={true} /> */}
            </div>
          </div>
          <div className="">
            <div className={``}>
              <div
                className={`d-flex align-items-end justify-content-between `}
              >
                <p className="title-pay-bill-customer font-weight-normal line-height-normal mb-0 text-black ">
                  Sub total
                </p>
                <p className="price-pay-bill-customer  line-height-normal mb-0 text-black  text-right">
                  ${FormatDolla(totalPrice)}
                </p>
              </div>
              <div className="d-flex align-items-end justify-content-between ">
                <p className="title-pay-bill-customer font-weight-normal line-height-normal mb-0 text-black ">
                  Tax
                </p>
                <p className="price-pay-bill-customer  line-height-normal mb-0 text-black  text-right">
                  ${FormatDolla((tax / 100) * totalPrice)}
                </p>
              </div>
            </div>
            <div className="">
              <div className="d-flex align-items-end justify-content-between">
                <p className="title-pay-bill-customer font-weight-normal line-height-normal align-items-end mb-0 text-black ">
                  Discount
                </p>
                <div className="d-flex gap-2px align-items-end">
                  <p className="price-pay-bill-customer d-flex mb-0 h-fit text-black line-height-normal  text-right">
                    ${discount}
                  </p>
                </div>
              </div>

              <div className="d-flex align-items-end justify-content-between m-0">
                <p className="title-pay-bill-customer font-weight-normal line-height-normal mb-0 d-flex align-items-end text-black m-0 ">
                  Total
                </p>
                <div className="d-flex align-items-end m-0">
                  <p className="price-pay-bill-customer-total m-0 h-fit text-black line-height-normal  text-right">
                    $
                    {listCartFood.length > 0
                      ? FormatDolla(
                        totalPrice +
                        feePrice +
                        (Number(tipPrice) > 0 ? Number(tipPrice) : 0) +
                        (totalPrice * tax) / 100 -
                        discount
                      )
                      : 0}
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <ModalLoading show={ShowLoading} onHide={() => setShowLoading(false)} />
      <ModalOrderFunction show={false} />
      <ModalAddSideDish
        ListFreeSidesActive={ListFreeSidesActive}
        setListFreeSidesActive={setListFreeSidesActive}
        maxSide={maxSideFoodOrder}
        handleClose={dismisModalAddSideDish}
        show={showModalAddSideDish}
        ownerID={UserIdStore?.toString() ?? ""}
      />
      <ModalViewMoreGroupFood
        handleClose={dismisModalViewMoreGroup}
        show={showModalViewMoreGroup}
        ListMenuItems={ListMenuItems}
        scrollToTop={scrollToTop}
      />
    </div>
  );
};

export default POSMenu;
