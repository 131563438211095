import * as Request from "../Request";

export interface ChartPayment {
  label: string;
  value: number;
  percent: number;
  color: string;
}

export interface ChartCustomer {
  name: string;
  data: number[];
}

export interface ReportDishis {
  id: string;
  name: string;
  total_order: string;
  price: string;
}
export interface ReportIncomeByMonth {
  monthName: string;
  monthValue: string;
  yearValue: string;
  income: string;
}
export interface ReportIncomeByDay {
  dayName: string;
  dayValue: string;
  income: string;
}
export interface ReportBill {
  id: string;
  owner_id: string;
  custommer_id: string;
  table_id: string;
  table_name: string;
  tip_amount: string;
  tip_staff_id: string;
  staff_name: string;
  price: string;
  timeorder: string;
  total_order: string;
  vat_amount: string;
  dateorder: string;
  custommer_name: string;
}
export interface Result<T> {
  status: string;
  message: string;
  data: T;
  totalIncome?: string;
  totalAllCustomer?: string;
  totalCustomerNew?: string;
  totalCustomer2Time?: string;
  chartPayment?: ChartPayment[];
  chartCustomer?: ChartCustomer[];
  valueMonthName?: string[];
  IncomeValue?: ChartCustomer[];
}

export interface ReportResult {
  totalIncome: number;
  percentIncome: number;
  totalImport: number;
  totalTip: number;
  totalSalary: number;
  totalTax: number;
  totalCoupon: number;
  profit: number;
  totalCash: number;
  totalCard: number;
  cashPercen: number;
  cardPercen: number;
  totalAllCustomer: number;
  totalCustomerNew: number;
  totalCustomer2Time: number;
  totalGood: number;
  totalNuetral: number;
  totalBad: number;
  orderOnline: number;
}
export interface ReportYear {
  year: number;
  value: number;
}
export interface ResultReportYear {
  totalIncome: number;
  percentIncome: number;
  totalImport: number;
  totalTip: number;
  totalSalary: number;
  totalTax: number;
  totalCoupon: number;
  profit: number;
  totalCash: number;
  totalCard: number;
  cashPercen: number;
  cardPercen: number;
  totalAllCustomer: number;
  totalCustomerNew: number;
  totalCustomer2Time: number;
  totalGood: number;
  totalNuetral: number;
  totalBad: number;
}
export const gReportInventory = async (
  owner_id: number,
  date_start: string = "",
  date_end: string = ""
) => {
  return await Request.fetchAPI(`owner/report_Inventory.php`, "POST", {
    owner_id,
    date_start,
    date_end,
  })
    .then((res: Result<[]>) => {
      return res;
    })
    .catch((err: any) => {
      return {
        message: "An error has occurred.Please check your internet connection",
        status: 0,
        data: [],
        totalIncome: "0",
        totalAllCustomer: "0",
        totalCustomerNew: "0",
        totalCustomer2Time: "0",
        chartPayment: [],
        chartCustomer: [],
        valueMonthName: [],
        IncomeValue: [],
      };
    });
};

export const gReportDishis = async (
  owner_id: number,
  date_start: string = "",
  date_end: string = "",
  sortType: number = 0
) => {
  return await Request.fetchAPI(`owner/report_Dishis.php`, "POST", {
    owner_id,
    date_start,
    date_end,
    sortType,
  })
    .then((res: Result<ReportDishis[]>) => {
      return res;
    })
    .catch((err: any) => {
      return {
        message: "An error has occurred.Please check your internet connection",
        status: 0,
        data: [],
      };
    });
};

export const gReportbyYear = async (owner_id: number, yearIncome: number) => {
  return await Request.fetchAPI(`owner/greportIncomeByYear.php`, "POST", {
    owner_id,
    yearIncome,
  })
    .then((res: Result<ReportIncomeByMonth[]>) => {
      return res;
    })
    .catch((err: any) => {
      return {
        message: "An error has occurred.Please check your internet connection",
        status: 0,
        data: [],
      };
    });
};
export const gReportbyMonth = async (
  owner_id: number,
  yearIncome: number,
  monthIncome: number
) => {
  return await Request.fetchAPI(`owner/greportIncomeByMonth.php`, "POST", {
    owner_id,
    yearIncome,
    monthIncome,
  })
    .then((res: Result<ReportIncomeByDay[]>) => {
      return res;
    })
    .catch((err: any) => {
      return {
        message: "An error has occurred.Please check your internet connection",
        status: 0,
        data: [],
      };
    });
};
export const gReportBillbyDay = async (owner_id: number, daySearch: string) => {
  return await Request.fetchAPI(`owner/gAllBillByDate.php`, "POST", {
    owner_id,
    daySearch,
  })
    .then((res: Result<ReportBill[]>) => {
      return res;
    })
    .catch((err: any) => {
      return {
        message: "An error has occurred.Please check your internet connection",
        status: 0,
        data: [],
      };
    });
};
export const gReportDetailbyDay = async (
  owner_id: number,
  daySearch: string
) => {
  return await Request.fetchAPI(`owner/reportByDay.php`, "POST", {
    owner_id,
    daySearch,
  })
    .then((res: Result<ReportResult>) => {
      return res;
    })
    .catch((err: any) => {
      return {
        message: "An error has occurred.Please check your internet connection",
        status: 0,
        data: undefined,
      };
    });
};
export const gReportBillbyWeek = async (
  owner_id: number,
  dayStart: string,
  dayEnd: string
) => {
  return await Request.fetchAPI(`owner/reportByWeek.php`, "POST", {
    owner_id,
    dayStart,
    dayEnd,
  })
    .then((res: Result<ReportResult>) => {
      return res;
    })
    .catch((err: any) => {
      return {
        message: "An error has occurred.Please check your internet connection",
        status: 0,
        data: undefined,
      };
    });
};
export const gReportBillbyMonth = async (
  owner_id: number,
  monthSearch: number
) => {
  return await Request.fetchAPI(`owner/reportByMonth.php`, "POST", {
    owner_id,
    monthSearch,
  })
    .then((res: Result<ReportResult>) => {
      return res;
    })
    .catch((err: any) => {
      return {
        message: "An error has occurred.Please check your internet connection",
        status: 0,
        data: undefined,
      };
    });
};
export const gReportBillbyQuater = async (
  owner_id: number,
  dayStart: string,
  dayEnd: string
) => {
  return await Request.fetchAPI(`owner/reportByQuarter.php`, "POST", {
    owner_id,
    dayStart,
    dayEnd,
  })
    .then((res: Result<ReportResult>) => {
      return res;
    })
    .catch((err: any) => {
      return {
        message: "An error has occurred.Please check your internet connection",
        status: 0,
        data: undefined,
      };
    });
};
export const gReportMoneyTimeChart = async (owner_id: number) => {
  return await Request.fetchAPI(`owner/reportMoneyTime.php`, "POST", {
    owner_id,
  })
    .then((res: Result<ReportResult[]>) => {
      return res;
    })
    .catch((err: any) => {
      return {
        message: "An error has occurred.Please check your internet connection",
        status: 0,
        data: [],
      };
    });
};
export const gComboYearReport = async (owner_id: number) => {
  return await Request.fetchAPI(`owner/gComboYearReport.php`, "POST", {
    owner_id,
  })
    .then((res: Result<ReportYear[]>) => {
      return res;
    })
    .catch((err: any) => {
      return {
        message: "An error has occurred.Please check your internet connection",
        status: 0,
        data: [],
      };
    });
};
export const reportByYear = async (owner_id: number, yearReport: number) => {
  return await Request.fetchAPI(`owner/reportByYear.php`, "POST", {
    owner_id,
    yearReport,
  })
    .then((res: Result<ResultReportYear>) => {
      return res;
    })
    .catch((err: any) => {
      return {
        message: "An error has occurred.Please check your internet connection",
        status: 0,
        data: undefined,
      };
    });
};
export const sendReportToMail = async (owner_id: number, filePath: string) => {
  return await Request.fetchAPI(`owner/sendReportToMail.php`, "POST", {
    owner_id,
    filePath,
  })
    .then((res: Result<string>) => {
      return res;
    })
    .catch((err: any) => {
      return {
        message: "An error has occurred.Please check your internet connection",
        status: 0,
        data: undefined,
      };
    });
};
