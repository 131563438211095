// import axios from "axios";
import axios from "axios";
// const URL = "http://localhost/";
// const URL = "https://api.brijackposadmin.com/";
const URL = "https://api.brijack.bdata.link/";

// const URL = "http://192.168.1.88/";
// const URL_QRCode = "https://brijackpos-restaurant.com/webcheckin/sendmenu";
// const URLWeb = "https://brijackpos-restaurant.com";
const URLWeb = "https://brijack.bdata.link";
const URL_QRCode = "https://brijack.bdata.link/webcheckin/sendmenu";
// const targetFood = "https://api.brijackposadmin.com/owner/image/food/";
const targetFood = "https://api.brijack.bdata.link/owner/image/food/";
// const targetImgage = "https://api.brijackposadmin.com/owner/image/";
const targetImgage = "https://api.brijack.bdata.link/owner/image/";
// const targetImgadmin = "https://api.brijackposadmin.com/admin/image/";
const targetImgadmin = "https://api.brijack.bdata.link/admin/image/";
// const URL_SOCKET = "https://localhost:5002";
// const URL_SOCKET = "https://socket.brijackposadmin.com";
const URL_SOCKET = "https://socket.brijack.bdata.link";
const KeyGoogleMap = "AIzaSyDEg6BghG-ECBQmAxPblLiP4ZUg2evKSew";

//

interface Option {
  router?: string;
}
axios.defaults.headers.get["Accept"] = "application/json";
axios.defaults.headers.post["Accept"] = "application/json";
// export const get = (params: any, option?: Option, authorizationToken?: string) => {
//   return axios({
//     url: URL + (option ? option.router : "get.php"),
//     method: "get",
//     params: params,
//     // headers: { Authorization: `Bearer ${authorizationToken}` },
//   }).then((response) => {
//     // return repsonse.data;
//   });
// };

export const fetchAPI = async (
  urlquest: string,
  method: string,
  params?: any
) => {
  if (method.toUpperCase() === "GET") {
    const response = await fetch(URL + urlquest, {
      method: method,
      // headers: {
      //   Authorization: `Bearer ${'020598'}`
      // },
      // body: params
    });
    return response.json();
  } else if (method.toUpperCase() === "POST") {
    var bodyFormData = new FormData();
    for (let key in params) {
      bodyFormData.append(key, params[key]);
    }

    const response = await fetch(URL + urlquest, {
      method: method,
      body: bodyFormData,
      // headers: {
      //   Authorization: `Bearer ${'020598'}`
      // },
      // body: JSON.stringify(bodyFormData)
    }).then((res) => res.json());
    return response;
    // return response.json();
  }
};
// const post = (params: any, option?: Option, authorizationToken?: string) => {
//   var bodyFormData = new FormData();
//   for (let key in params) {
//     bodyFormData.append(key, params[key]);
//   }
//   return axios({
//     url: URL + (option ? option.router : "post.php"),
//     method: "post",
//     data: bodyFormData,
//     // headers: { Authorization: `Bearer ${authorizationToken}` },
//   }).then((response) => {
//     return response.data;
//   });
// };
const API = (
  urlquest: string,
  method: string,
  params?: any,
  authorizationToken?: any
) => {
  var data: any = [];
  if (method.toUpperCase() === "GET") {
    return axios({
      url: URL + urlquest,
      method: "get",
      params: params,
      // headers: { Authorization: `Bearer ${authorizationToken}` },
    }).then((response: any) => {
      return response.data;
    });
  } else if (method.toUpperCase() === "POST") {
    // var bodyFormData = new FormData();
    // for (let key in params) {
    //   bodyFormData.append(key, params[key]);
    // }
    // return axios({
    //   url: URL + urlquest,
    //   method: "post",
    //   data: bodyFormData,
    //   // headers: { Authorization: `Bearer ${authorizationToken}` },
    // }).then((response: any) => {
    //   console.log("aaaaaaaaaaaaaaaa2222222222222222",response);
    //   return response.data;
    // }).catch((err: any) => {

    //   console.log("bbbbbbbbbbsaiiiiiiiiiiiiiiiiiiiiiiiiiii", err);
    // });
    return axios
      .post(URL + urlquest, { params })
      .then((response: any) => {
        return response;
        // return response.data;
      })
      .catch((err: any) => {});
  } else {
    return data;
  }
};

//chuyển text sang HTML
const ConvertHTML = (item: any) => {
  return {
    __html: item,
  };
};

export interface Response<T> {
  status?: number;
  message?: string;
  data?: T;
}

export {
  // get,
  // post,
  API,
  ConvertHTML,
};

export {
  URL_QRCode,
  targetFood,
  targetImgage,
  URL_SOCKET,
  KeyGoogleMap,
  URLWeb,
  targetImgadmin,
};
