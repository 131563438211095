import { useCallback, useEffect, useState } from "react";
import "./Styles.css";
import TableItems from "./components/TableItem/TableItems";
import * as tableApi from "../../api/apiOwner/tableApi";
import * as userSlice from "../../store/slices/userSlice";
import * as floorSlice from "../../store/slices/floorSlice";
import * as tableSlice from "../../store/slices/tableSlice";
import * as tipSlice from "../../store/slices/tipPercentSlice";
import * as staffTipSlice from "../../store/slices/ListStaffTipSlice";
import * as ListDetailFoodSlice from "../../store/slices/ListDetailFoodSlice";
import { useSelector } from "react-redux";
import * as paymentApi from "../../api/apiCheckin/paymentApi";
import { withSwal } from "react-sweetalert2";
import { useDispatch } from "react-redux";
import { gListInfoFoodOffline } from "../../api/apiCheckin/chooseMenuApi";
import { getLanguageUI } from "../../Services/languageUI";
import socket from "../../utils/socket";

import ModalLoading from "../../components/ModalLoading";
import { useLocation, useNavigate } from "react-router-dom";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import React from "react";
import {
  ListPrinterByCatalog,
  gNamePrinterByCatalog,
} from "../../api/apiOwner/SettingPrinterApi";
import axios from "axios";
import { giftCardSVG } from "../../components/ImgExport";
import ModalBuyGiftCard from "./components/ModalBuyGiftCard/ModalBuyGiftCard";
// import ModalPayBillTablePOS from "./components/ModalPayBillTablePOS/ModalPayBillTablePOS";

const POSTable: React.FC = withSwal((props: any) => {
  const navigate = useNavigate();
  const pos_id = useSelector(userSlice.selectorPosId) ?? 0;
  const LanguageUI = getLanguageUI().posTable;
  const location = useLocation();
  const params: any = location.state;
  const tableShow = params?.tableShow ? params?.tableShow : "";
  const orderShow = params?.orderShow ? params?.orderShow : "";
  const floorIdChoosed = params?.floorId ? params?.floorId : "";
  const { swal } = props;
  const [isOnline, setIsOnline] = useState(navigator.onLine);
  const [tableSelected, setTableSelected] = useState<string>("");
  const UserIdStore = useSelector(userSlice.selectorUserId);
  const FloorOffline = useSelector(floorSlice.selectorFloor);
  const TableOffline = useSelector(tableSlice.selectorTable);
  const InfoFoodOffline = useSelector(ListDetailFoodSlice.selectorListInfoFood);
  const ListTipline = useSelector(tipSlice.selectorListTipPercent);
  const ListStaffTipOffline = useSelector(staffTipSlice.selectorListStaffTip);
  const [listTable, setListTable] = useState<tableApi.TableInfo[]>([]);
  const [listFloor, setListFloor] = useState<tableApi.FloorInfo[]>([]);
  const [indexFloor, setIndexFloor] = useState<number>(0);
  const [ShowLoading, setShowLoading] = useState<boolean>(false);
  const [searchCustommer, setSearchCustommer] = useState<string>("");
  const [tipList, setTipList] = useState<paymentApi.Tip[]>([]);
  const [listStaff, setListStaff] = useState<paymentApi.TipStaff[]>([]);
  const [isCash, setIsCash] = useState<boolean>(false);
  const [isVisa, setIsVisa] = useState<boolean>(false);
  const [idTableStatus, setidTableStatus] = useState<string>();
  const [showModalGiftCard, setShowModalGiftCard] = useState<boolean>(false);
  const [listPrinterName, setListPrinterName] = useState<
    ListPrinterByCatalog[]
  >([]);
  const [floorSelect, setFloorSelect] = useState<string>();
  ////
  const dispatch = useDispatch();
  // const url = "http://localhost:9000/";
  // const handleCheckUrl = async () => {
  //   try {
  //     const response = await axios.get(url);

  //     if (response.status === 200) {
  //       console.log(true);
  //     } else {
  //       console.log(response);
  //     }
  //   } catch (error) {
  //     console.error("Error checking URL:", error);
  //   }
  // };
  // useEffect(() => {
  //   handleCheckUrl();
  // }, []);
  //lấy danh sách tầng

  const dismisModalGiftCard = () => {
    setShowModalGiftCard(false);
  };

  const gListFloorByOwner = async () => {
    if (!isOnline && FloorOffline) {
      setListFloor(FloorOffline ?? []);
      setIndexFloor(0);
      gListTable(FloorOffline[0]?.id);
    } else {
      if (UserIdStore) {
        const glistFloor = await tableApi.gListAllFloor(UserIdStore);
        if (Number(glistFloor.status) === 1) {
          setListFloor(glistFloor.data ?? []);
          dispatch(floorSlice.setFloor(glistFloor.data ?? []));
          if (floorIdChoosed) {
            setIndexFloor(
              glistFloor?.data?.findIndex((it) => it?.id == floorIdChoosed) ?? 0
            );
            gListTable(floorIdChoosed);
          } else {
            if (
              glistFloor?.data &&
              glistFloor?.data?.length > 0 &&
              glistFloor?.data[0]?.id
            ) {
              setIndexFloor(0);
              gListTable(glistFloor?.data[0]?.id);
            }
          }
        }
      }
    }
  };
  //lấy danh sách bàn
  const gListTable = async (floor_id: string) => {
    setFloorSelect(floor_id);
    if (!isOnline && TableOffline) {
      setListTable(
        TableOffline
          ? TableOffline.filter((item) => item?.floor_id === floor_id)
          : []
      );
    } else {
      if (UserIdStore && floor_id !== "") {
        setShowLoading(true);
        const ListTB = await tableApi.gListAllTableByFloor(
          UserIdStore,
          floor_id,
          1
        );
        if (Number(ListTB.status) === 1) {
          setListTable(ListTB.data ?? []);
          setShowLoading(false);
        } else {
          setShowLoading(false);
        }
      }
    }
  };
  //lấy danh sách bàn chỉ với id table
  const gListAllTablenotFloor = async (tablereset: string) => {
    // setShowLoading(true);
    const ListTB = await tableApi.gListAllTablenotFloor(tablereset);
    if (Number(ListTB.status) === 1) {
      setListTable(ListTB.data ?? []);
      setShowLoading(false);
      setidTableStatus("");
    } else {
      setShowLoading(false);
    }
  };
  //lấy danh sách bàn offline
  const gAllListTableOffline = async () => {
    if (!TableOffline || TableOffline.length === 0) {
      if (UserIdStore) {
        const ListTB = await tableApi.gListTableOffline(UserIdStore);
        if (Number(ListTB.status) === 1) {
          dispatch(tableSlice.setTable(ListTB?.data ?? []));
        }
      }
    }
  };
  //lấy danh sách chi tiết món ăn offline
  const gListDetailFood = async () => {
    if (!InfoFoodOffline || InfoFoodOffline.length === 0) {
      if (UserIdStore) {
        const ListTB = await gListInfoFoodOffline(UserIdStore);
        if (Number(ListTB.status) === 1) {
          dispatch(ListDetailFoodSlice.setListInfoFood(ListTB?.data ?? []));
        }
      }
    }
  };
  const searchTableByCustommer = async (tableOL?: tableSlice.Table[]) => {
    if (!isOnline) {
      setListTable(tableOL ?? []);
    } else {
      if (UserIdStore) {
        setShowLoading(true);
        if (searchCustommer !== "") {
          const ListTB = await tableApi.gListTableByCustommer(
            UserIdStore,
            searchCustommer
          );
          if (Number(ListTB.status) === 1) {
            setListTable(ListTB.data ?? []);
            setShowLoading(false);
          } else {
            setShowLoading(false);
          }
        } else {
          if (listFloor.length > 0 && listFloor[indexFloor]) {
            gListTable(listFloor[indexFloor].id ?? "");
          }
        }
      }
    }
  };

  const refeshListTable = async (tableId: number, floor_id: string) => {
    await setFloorSelect(floor_id);
    await gListTable(floor_id);

    const tableElement = document.getElementById(String(tableId));
    if (tableElement) {
      tableElement.click();
    }
  };

  // const handleChaneFloor = (type: number) => {
  //   //type:1 tăng 0 giảm
  //   var index = 0;
  //   if (type === 1) {
  //     index = indexFloor + 1;
  //   } else {
  //     index = indexFloor - 1;
  //   }
  //   setIndexFloor(index);
  //   if (listFloor.length > 0 && listFloor[index]) {
  //     gListTable(listFloor[index].id ?? "");
  //   }
  // };

  // const handleChooseFloor = (id: string) => {
  //   gListTable(id);
  // };

  const gListTip = async () => {
    if (!isOnline && ListTipline) {
      setTipList(ListTipline);
    } else {
      if (UserIdStore) {
        const Tip = await paymentApi.getListTip(UserIdStore);
        if (Number(Tip.status) === 1) {
          setTipList(Tip?.data ?? []);
          dispatch(tipSlice.setListTipPercent(Tip?.data ?? []));
        }
      }
    }
  };

  const gListStaffTip = async () => {
    if (!isOnline && ListStaffTipOffline) {
      setListStaff(ListStaffTipOffline);
    } else {
      if (UserIdStore) {
        const StaffApi = await paymentApi.getListTipStaff(UserIdStore);
        if (Number(StaffApi.status) === 1) {
          setListStaff(StaffApi.data ?? []);
          dispatch(staffTipSlice.setListStaffTip(StaffApi?.data ?? []));
        }
      }
    }
  };
  const ConfilmResetTable = async (table_id: string, table_name: string) => {
    swal
      .fire({
        title:
          "Are you sure you want to return table " +
          table_name +
          " to Available state?",
        text: "",
        icon: "question",
        showCancelButton: true,
        confirmButtonColor: "#28bb4b",
        cancelButtonColor: "#f34e4e",
        confirmButtonText: "Yes",
        cancelButtonText: "No",
      })
      .then(function (result: { value: any; dismiss: any }) {
        if (result.value) {
          ResetTable(table_id);
        }
      });
  };

  const ResetTable = async (table_id: string) => {
    if (!isOnline && TableOffline) {
      const data = listTable?.map((item) => {
        return item?.id === table_id ? { ...item, status: "0" } : item;
      });
      dispatch(tableSlice.setTable(data ?? []));
      setListTable(data ?? []);
    } else {
      const upStatus = await tableApi.CleaveTable(table_id);
      if (upStatus.status === 1) {
        const data = listTable?.map((item) => {
          return item?.id === table_id ? { ...item, status: "0" } : item;
        });
        dispatch(tableSlice.setTable(data ?? []));
        setListTable(data ?? []);
      }
    }
  };

  const CheckPaymentMethodOrder = async (
    owner_id: number,
    table_id: string
  ) => {
    if (!isOnline && TableOffline) {
      const TableInfo = TableOffline.filter((item) => item?.id === table_id);
      if (TableInfo.length > 0) {
        setIsCash(TableInfo[0]?.is_cash ?? false);
        setIsVisa(TableInfo[0]?.is_visa ?? false);
      }
    } else {
      const CheckPay = await tableApi.CheckPaymentTable(owner_id, table_id);
      if (Number(CheckPay.status) === 1) {
        setIsCash(CheckPay?.is_cash ?? false);
        setIsVisa(CheckPay?.is_visa ?? false);
      }
    }
  };
  useEffect(() => {
    gListFloorByOwner();
    gListTip();
    gListStaffTip();
    gAllListTableOffline();
    gListDetailFood();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  useEffect(() => {
    // Đăng ký sự kiện kiểm tra trạng thái kết nối mạng
    window.addEventListener("online", handleOnline);
    window.addEventListener("offline", handleOffline);

    // Hủy đăng ký sự kiện khi component bị hủy
    return () => {
      window.removeEventListener("online", handleOnline);
      window.removeEventListener("offline", handleOffline);
    };
  }, []);

  const handleOnline = () => {
    setIsOnline(true);
  };

  const handleOffline = () => {
    setIsOnline(false);
  };

  // const setStatusTable = (tabid: string) => {
  //   const newList = listTable.map(item => item.id == tabid ? { ...item, status: '1' } : item)
  //   setListTable([...newList])
  // }
  useEffect(() => {
    if (
      idTableStatus &&
      listTable.some((tb) => Number(tb.id) === Number(idTableStatus))
    ) {
      // gListAllTablenotFloor(idTableStatus);
      gListTable(listFloor[indexFloor]?.id);
    }
  }, [idTableStatus]);
  // const ReloadStatusTable = useCallback(async () => {
  //   var pathName = window.location.pathname;
  //   await socket.on("PushShowNotification" + UserIdStore, (data) => {
  //     if (pathName.search("/tablepos") > -1) {
  //       if (data?.table_id) {
  //         setidTableStatus(data?.table_id);
  //       }
  //     }
  //   });
  //   // eslint-disable-next-line react-hooks/exhaustive-deps
  // }, [socket, indexFloor]);
  const ReloadStatusTable = useCallback(
    async (data: any) => {
      var pathName = window.location.pathname;
      if (pathName.search("/tablepos") > -1) {
        if (data?.table_id) {
          setidTableStatus(data?.table_id);
        }
      }
      // eslint-disable-next-line react-hooks/exhaustive-deps
    },
    [socket, indexFloor]
  );
  useEffect(() => {
    if (!UserIdStore) {
      return;
    }
    const eventName = "PushShowNotification" + UserIdStore;

    socket.on(eventName, ReloadStatusTable);

    return () => {
      socket.off(eventName, ReloadStatusTable);
    };
  }, [UserIdStore]);
  ////////////////

  const showToastPrint = (MessWarning: string) => {
    toast.warning(MessWarning);
  };

  const gListPrinter = async () => {
    if (UserIdStore) {
      const req = await gNamePrinterByCatalog(UserIdStore, 1, pos_id);
      if (Number(req?.status) === 1) {
        setListPrinterName(req?.data ?? []);
      }
    }
  };
  useEffect(() => {
    gListPrinter();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <div className="">
      <div className="">
        {/* <Topbar pageName="POS Table" /> */}
        <ModalLoading show={ShowLoading} onHide={() => setShowLoading(false)} />
        <ToastContainer />
      </div>
      <div className="pb-1 mt-header bg-white px-4 border-bottom container-fluid custom-header-search gap-3">
        <div className="d-flex justify-content-between align-items-center pt-1">
          {/* <div className="d-flex align-items-center h-100 gap-2 w-100">
            <img src={searchGreen} alt="" />
            <input
              type="text"
              className="focus-visible-none border-none input-search-table bg-transparent w-100 text-truncate"
              placeholder={LanguageUI.findTableByPhone}
              value={searchCustommer}
              onChange={(e) => setSearchCustommer(e.target.value)}
              onKeyPress={(event) => {
                if (event.key === "Enter") {
                  searchTableByCustommer();
                }
              }}
            />
          </div> */}
          {/* <div className="d-flex align-items-center gap-2 flex-shrink-0">
            <button
              className={`flex-shink-0 rounded-4  h-40px  d-flex justify-content-center align-items-center text-green px-2 bg-f1735d text-white border-0`}
              onClick={() => navigate("/web-owner/posmenu")}
            >
              <p className={`m-0 text-choose-status-order text-white`}>
                {LanguageUI.ordertogo}
              </p>
            </button>
            <button onClick={createReader}>create Reader</button>
            <div className="d-flex align-items-center gap-2">
              <div className="circle-status-table status-table-available"></div>
              <p className="mb-0 name-status-table">{LanguageUI.avaiable}</p>
            </div>
            <div className="d-flex align-items-center gap-2">
              <div className="circle-status-table status-table-peserved"></div>
              <p className="mb-0 name-status-table">{LanguageUI.Reserved}</p>
            </div>
            <div className="d-flex align-items-center gap-2">
              <div className="circle-status-table status-table-paid"></div>
              <p className="mb-0 name-status-table">{LanguageUI.Paid}</p>
            </div>
            <div className="d-flex gap-1 align-items-center">
              <button
                className="btn-choose-floor"
                disabled={indexFloor === 0}
                onClick={() => {
                  handleChaneFloor(0);
                }}
              >
                <img src={arrow} alt="" className="rorate-left" />
              </button>
              <div className="w-70px text-floor text-center">
                {listFloor.length > 0
                  ? LanguageUI.floor + " " + listFloor[indexFloor]?.floor_name
                  : ""}
              </div>
              <button
                className="btn-choose-floor"
                disabled={indexFloor === listFloor.length - 1}
                onClick={() => {
                  handleChaneFloor(1);
                }}
              >
                <img src={arrow} alt="" className="rorate-right" />
              </button>
            </div>
          </div> */}
          <div className="d-flex align-items-center px-2 flex-swap mt-1 gap-1">
            {listFloor.map((item, index) => (
              <div
                key={index}
                className={`px-2 py-2px border text-white font-600 round-8px ${floorSelect === item.id ? "border-default-floor" : ""
                  }`}
                style={{ backgroundColor: `${item.color}` }}
                onClick={() => {
                  gListTable(item.id);
                  setFloorSelect(item.id);
                }}
              >
                {item.floor_name}
              </div>
            ))}

            {/* <div className="px-2 py-2px border text-white font-600 bg-0989FF">
            Dining Room 1
          </div>
          <div className="px-2 py-2px border text-white font-600 bg-E7191F">
            Dining Room 2
          </div>
          <div className="px-2 py-2px border text-white font-600 bg-006D37">
            Bar Outside
          </div>
          <div className="px-2 py-2px border text-white font-600 bg-004AAD">
            Inhouse
          </div>
          <div className="px-2 py-2px border text-white font-600 bg-CB6CE6">
            Upper Room
          </div>
          <div className="px-2 py-2px border text-white font-600 bg-EB04A3">
            Bacany
          </div>
          <div className="px-2 py-2px border text-white font-600 bg-C57E1F">
            SeaFood Room
          </div>
          <div className="px-2 py-2px border text-white font-600 bg-AA8300">
            Indoor Mater
          </div> */}
          </div>
          <div className="d-flex gap-2 align-items-center">
            <button
              className={`flex-shink-0 rounded-4  h-40px  d-flex justify-content-center align-items-center text-green px-2 bg-999999 box-shadow-default border-gray text-white `}
              onClick={() => setShowModalGiftCard(true)}
            >
              <img src={giftCardSVG} alt="" className="w-100 h-100" />
            </button>
            <button
              className={`flex-shink-0 rounded-4  h-40px  d-flex justify-content-center align-items-center text-green px-2 bg-f1735d text-white border-0`}
              onClick={() =>
                navigate("/web-owner/posmenu", {
                  state: { floor_id: floorSelect },
                })
              }
            >
              <p className={`m-0 text-choose-status-order text-white`}>
                {LanguageUI.ordertogo}
              </p>
            </button>
          </div>
        </div>
      </div>

      <div className="d-flex">
        <div className="px-4 w-100  min-height-menu-table scroll-hidden position-relative">
          {listTable.map((items, index) => {
            return (
              <TableItems
                listPrinterName={listPrinterName}
                tx={Number(items.tx)}
                ty={Number(items.ty)}
                id={items?.id}
                NameTable={items?.table_name}
                setTableSelected={setTableSelected}
                CheckPaymentMethodOrder={CheckPaymentMethodOrder}
                StatusTable={Number(items?.status)}
                index={tableSelected === items?.id}
                key={items.id}
                numberChair={Number(items?.size)}
                tipList={tipList}
                listStaff={listStaff}
                ReloadTable={searchTableByCustommer}
                ConfilmResetTable={ConfilmResetTable}
                is_cash={isCash}
                is_visa={isVisa}
                orderShow={orderShow}
                tableShow={tableShow}
                scale={Number(items.scale)}
                shape={items.shape ?? "square"}
                rorate={Number(items.rorate)}
                showToastPrint={showToastPrint}
                listTable={listTable}
                gListFloorByOwner={gListFloorByOwner}
                floor_id={floorSelect}
                refeshListTable={refeshListTable}
              />
            );
          })}
          {/* <div className="w-100 h-90px"></div> */}
        </div>
      </div>
      <ModalBuyGiftCard
        handleClose={dismisModalGiftCard}
        show={showModalGiftCard}
        setShowLoading={setShowLoading}
      />
    </div>
  );
});

export default POSTable;
